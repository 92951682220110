import React from "react";
import {
  Popover,
  PopoverInteractionKind,
  Menu,
  MenuItem,
  Icon,
} from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { AuthRoutes, NonAuthRoutes } from "../../shared/enums";
import { generateUsernameInitial } from "../../utility/utils";
import "./UsernameAvatarComponent.css";
import { logOut } from "../../api/authService";

interface IUsernameAvatarComponent {
  username: string | null;
}

export const UsernameAvatarComponent: React.FC<IUsernameAvatarComponent> = (
  props
) => {
  const { username } = props;
  const history = useHistory();
  const handleLogout = () => {
    history.push(NonAuthRoutes.login);
    logOut();
  };

  const handleChangePassword = () => {
    history.push(AuthRoutes.changePassword);
  };

  const renderDropDownContent = () => {
    return (
      <Menu>
        <MenuItem
          icon="key"
          text="Change Password"
          onClick={handleChangePassword}
        />
        <MenuItem icon="log-out" text="Logout" onClick={handleLogout} />
      </Menu>
    );
  };

  return (
    <>
      <span data-initials={generateUsernameInitial(username)}></span>
      <span>
        Welcome,
        <Popover
          popoverClassName="bp3-popover-content-sizing"
          content={renderDropDownContent()}
          interactionKind={PopoverInteractionKind.CLICK}
          position="bottom"
          target={
            <div className="username">
              {username}
              <Icon icon="caret-down" color="666"></Icon>
            </div>
          }
        ></Popover>
      </span>
    </>
  );
};
