export enum AuthRoutes {
  dashboard = "/dashboard",
  simDetails = "/sim-details/:routeParamIccid",
  changePassword = "/change-password"
}

export enum NonAuthRoutes {
  login = "/",
}

export enum PageTitles {
  PORTAL_NAME = "End User Portal | ",
  LOGIN = `Login`,
  DASHBOARD = `Dashboard`,
  SIM_DETAILS = `Sim Detail`,
  CHANGE_PASSWORD = `Change Password`
}

export enum Locals {
  ACCESS_TOKEN = "end-user-portal-token",
  REFRESH_TOKEN = "end-user-portal-refresh_token",
  USERNAME = "end-user-portal-username",
  USER = "end-user-portal-user"
}

export enum LoginDefaultConfigs {
  ROLE = "selfcare_enduser",
}

export enum AuthErrorMsgs {
  UNAUTHORIZED = "Invalid credentials",
  CONNECTION_ERROR = "Server Error",
}

export enum MenuPositions {
  BOTTOM_END = "bottom-end",
  BOTTOM = "bottom",
  RIGHT_START = "right-start",
  RIGHT_END = "right-end",
}

export enum TableColumns {
  DURATION = "duration",
  DATA_TOTAL = "data_total",
  SERVING_NETWORK = "serving_network",
  COUNTRY = "country",
  B_PARTY_NUMBER = "other_party_number",
  CALL_TYPE = "call_type",
  START_TIME = "start_time",
  END_TIME = "end_time",
}

export enum ChartLabels {
  TOTAL_DATA = "Total Data",
  TOTAL_VOICE = "Total Voice",
  TOTAL_SMS = "Total SMS",
}

export enum ChartColors {
  JAGUAR_BG_COLOR = "rgb(40, 41, 40)",
  JAGUAR_BORDER_COLOR = "rgba(40, 41, 40, 0.5)",
  WATERMELON_BG_COLOR = "rgb(255, 99, 132)",
  WATERMELON_BORDER_COLOR = "rgba(255, 99, 132, 0.5)",
  PICTON_BLUE_BG_COLOR = "rgb(54, 162, 235)",
  PICTON_BLUE_BORDER_COLOR = "rgba(54, 162, 235, 0.5)",
}

export enum CallTypes {
  DATA = "data",
  VOICE = "voice",
  SMS = "sms",
}

export enum Filter {
  DATE_PRESET = "date_preset",
  COUNTRIES = "countries",
  OPERATORS = "operators",
  START_DATE = "start_date",
  END_DATE = "end_date",
}

export enum DefaultApiParams {
  DATE_PRESET = "7days",
  SORT = "start_time",
  ORDER = "desc",
  PAGE = "0",
  PAGE_SIZE = "25",
  CURRENCY = "USD",
  SUMMARIZE = "iccid",
}

export enum PagingTypes {
  PAGE = "page",
  PAGE_SIZE = "page_size",
}

export enum DateFormat {
  DEFAULT_LOCALE = "en-US",
  DEFAULT = "DD MMM YYYY",
  YEAR_MONTH_DAY = "YYYYMMDD",
  DATE_PICKER_VIEW = "YYYY-MM-DD",
  START_END_TIME = "DD MMM YYYY HH:mm:ss (Z)",
}

export enum Messages {
  NO_RESULTS = "No results found for the selected filters.",
  NON_IDEAL_STATE = "This SIM has no usage for the selected filters.",
  SERVICE_STATUS_BLOCK_MSG = "Are you sure you wish to block this SIM? (Service will be disabled immediately).",
  SERVCE_STATUS_UNBLOCK_MSG = "Are you sure you wish to unblock this SIM?",
}
