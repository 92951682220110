import { IStorage } from "../shared/types";

export default abstract class Storage<T extends string> {
  private readonly storage: IStorage;

  public constructor(getStorage = (): IStorage => window.localStorage) {
    this.storage = getStorage();
  }

  protected getItem(key: T): string | null {
    return this.storage.getItem(key);
  }

  protected setItem(key: T, value: string): void {
    this.storage.setItem(key, value);
  }

  protected removeItem(key: T): void {
    this.storage.removeItem(key);
  }

  protected removeItems(keys: T[]): void {
    keys.forEach((key) => this.removeItem(key));
  }
}
