import {
    DateRangeOption,
    PageSizeOption,
  } from "../shared/types";

  export const TOAST_DISPLAY_DEFAULT_TIME = 5000;
  export const TOAST_DISPLAY_LONG_TIME = 15000;
  export const IN_OUT_CALLTYPE_ICONS_SIZE = 10;
  export const DEFAULT_ICON_SIZE = 14;
  // export const ICON_SIZE = 16;
  export const TRUNCATE_STRING_LEN = 30;
  export const COPIED_TXT = "Copied";
  export const SMDP_ADDRESS_TXT = "Copy SMDP Address";
  export const ACTIVATION_CODE_TXT = "Copy Activation Code";
  export const ACTIVATION_URL_TXT = "Copy Activation URL";
  export const CLIPBOARD_RESET_TIME = 3000;

  export const DateRangeOptions: DateRangeOption[] = [
    { name: "Last 24 Hours", value: "24hours" },
    { name: "Last 7 Days", value: "7days" },
    { name: "Month to Date", value: "mtd" },
    { name: "Last 30 Days", value: "30days" },
    { name: "Last 90 Days", value: "90days" },
    { name: "Select Date Range", value: "selectDate" },
  ];

  export const PageSizeOptions: PageSizeOption[] = [
    { name: "5", value: "5" },
    { name: "10", value: "10" },
    { name: "15", value: "15" },
    { name: "25", value: "25" },
    { name: "50", value: "50" },
    { name: "100", value: "100" },
  ];
  
  export const ServiceStatuses = new Map<number, string>([
    [100, "New"], //	Waiting for activation
    [200, "Activated"], //	Active in Network
    [225, "Pending Block"], // // Waiting to be blocked in network
    [235, "Pending Unblock"], // 	Waiting to be unblocked in network
    [240, "Blocked"], // Blocked - Soft, Returns Block for UI purposes...	Waiting to be unblocked in network
    [260, "Blocked - Possible Fraud"], // Blocked in network due to Possible Fraud
    [270, "Blocked - SIM Lost / Stolen"], // Blocked in network due to SIM Lost / Stolen
    [280, "Blocked"], //	Blocked in network - no particular reason
    [400, "Terminated"], //	Terminated in network]
  ]);
  export const SubscriptionStatuses = new Map<number, string>([
    [1, "New"],
    [2, "Activated"],
    [3, "Cancellation Pending"],
    [4, "Terminated"],
  ]);
  export const Operators = new Map<string, string>([
    ["AAAWL", "SeaNet Maritime Communications AB"],
    ["AAM26", "Telecom26 AG"],
    ["AAMOM", "Monaco Telecom S.A.M."],
    ["AAMOW", "Síminn h.f."],
    ["AAMT1", "Tampnet AS"],
    ["AAZ24", "Sierra Wireless Inc."],
    ["AAZ29", "Sierra Wireless Inc."],
    ["AAZAT", "AT&T Comercializacion Movil, S. de R.L. de C.V."],
    ["AAZC4", "Com4 Sweden AB"],
    ["AAZTD", "Telekom Deutschland GmbH"],
    ["AAZTE", "TATA Communications MOVE Nederland B.V."],
    ["AAZTR", "Transatel"],
    ["ABWDC", "New Millennium Telecom Services NV"],
    ["ABWSE", "Servicio di Telecomunicacion di Aruba"],
    ["AFGAR", "MTN Afghanistan"],
    ["AFGAT", "Afghan Telecom"],
    ["AFGAW", "Afghan Wireless Communication Company"],
    ["AFGEA", "Emirates Telecommunications Corporation (ETISALAT)"],
    ["AFGTD", "Telecom Development Company Afghanistan Limited"],
    ["AGOMV", "Movicel - Telecomunicações, S.A."],
    ["AGOUT", "Unitel S.A."],
    ["AIACW", "Cable and Wireless (Anguilla) Ltd"],
    ["AILID", "ACeS International Limited Indonesia (AIL)"],
    ["AILTA", "ACeS Regional Services Co., Ltd"],
    ["ALBAM", "TELEKOM ALBANIA SH.A"],
    ["ALBEM", "ALBtelecom sh.a."],
    ["ALBM4", "Plus Communication sh.a"],
    ["ALBVF", "Vodafone - Albania"],
    ["ANDMA", "Andorra Telecom, S.A.U."],
    ["ANTCT", "Curaçao Telecom N.V."],
    ["ANTTC", "Telcell N.V."],
    ["ANTUT", "UTS N.V."],
    ["AREDU", "Emirates Integrated Telecommunications Company PJSC"],
    ["ARETC", "Emirates Telecom Corp-ETISALAT"],
    ["ARETH", "Thuraya Telecommunications Company"],
    ["ARGCM", "AMX Argentina S.A."],
    ["ARGNC", "Nextel Communications"],
    ["ARGTM", "Telefónica Móviles Argentina S.A."],
    ["ARGTP", "Telecom Argentina S.A."],
    ["ARGTP", "Telecom Personal S.A."],
    ["ARM01", "VEON Armenia CJSC"],
    ["ARM05", "MTS Armenia CJSC"],
    ["ARMKT", "Karabakh"],
    ["ARMOR", "UCOM LLC"],
    ["ASMAS", "AST Telecom, LLC"],
    ["ASMAS", "Blue Sky"],
    ["ATG03", "Antigua Public Utilities Authority (APUA)"],
    ["ATGCW", "Cable & Wireless Antigua & Barbuda Ltd"],
    ["AUS71", "Telstra Corporation Limited"],
    ["AUS72", "Telstra Corporation Limited"],
    ["AUSDL", "Dialogue Communications Ltd"],
    ["AUSOP", "SingTel Optus Pty Limited"],
    ["AUSTA", "Telstra Corporation Limited"],
    ["AUSVF", "Vodafone Hutchison Australia Pty Limited"],
    ["AUTAT", "T-Mobile Austria GmbH"],
    ["AUTCA", "Hutchison Drei Austria GmbH"],
    ["AUTMM", "T-Mobile Austria GmbH"],
    ["AUTMR", "Mass Response Service GmbH"],
    ["AUTMT", "Mtel Austrija Gmbh"],
    ["AUTPT", "A1 Telekom Austria AG"],
    ["AZEAC", "Azercell Telecom LLC"],
    ["AZEAF", "Azerfon LLC"],
    ["AZEBC", "Bakcell Limited Liable Company"],
    ["BDI02", "Africell PLC Company"],
    ["BDIHC", "Onatel"],
    ["BDIL1", "Lacell SU"],
    ["BDITL", "Econetleo"],
    ["BDIVG", "Viettel Burundi S.A."],
    ["BELBX", "BICS"],
    ["BELKO", "Telenet Group BVBA/SPRL"],
    ["BELLM", "Lycamobile Belgium Limited"],
    ["BELML", "VikingCo NV"],
    ["BELMO", "ORANGE Belgium nv/SA"],
    ["BELNE", "Nethys SA"],
    ["BELTB", "Proximus PLC"],
    ["BELTN", "Telenet Group BVBA/SPRL"],
    ["BEN02", "Etisalat Benin SA"],
    ["BENBB", "Bell Benin Communications (BBCOM)"],
    ["BENGM", "Glomobile Benin Limited"],
    ["BENLI", "Libercom"],
    ["BENSP", "Spacetel-Benin"],
    ["BFACT", "Orange Burkina Faso S.A"],
    ["BFAON", "Onatel"],
    ["BFATL", "Telecel Faso S.A."],
    ["BGDAK", "Robi Axiata Limited"],
    ["BGDBL", "Banglalink Digital Communications Ltd."],
    ["BGDGP", "Grameenphone Ltd"],
    ["BGDTT", "Teletalk Bangladesh Limited"],
    ["BGR01", "A1 Bulgaria EAD"],
    ["BGRCM", "Telenor Bulgaria EAD"],
    ["BGRVA", "Bulgarian Telecommunications Company EAD"],
    ["BHRBT", "Batelco (Bahrain Telecommunications Company)"],
    ["BHRBT", "Bahrain Telecommunications Company (B.S.C.)"],
    ["BHRMV", "Zain Bahrain B.S.C"],
    ["BHRST", "Viva Bahrain B.S.C Closed"],
    ["BHSBH", "The Bahamas Telecommunications Company Ltd."],
    ["BHSNC", "Be Aliv Limited"],
    ["BIHER", "Public Enterprise Croatian Telecom JSC"],
    ["BIHMS", "RS Telecommunications  JSC Banja Luka"],
    ["BIHPT", "BH Telecom JSC Sarajevo"],
    ["BLR02", "JLLC Mobile TeleSystems"],
    ["BLRBT", "Belarusian Telecommunications Network CJSC"],
    ["BLRMD", "Unitary enterprise velcom"],
    ["BLZ67", "Belize Telemedia Limited"],
    ["BLZSC", "Speednet Communications Ltd"],
    ["BMU01", "AT&T Mobility"],
    ["BMUBD", "Bermuda Digital Communications Ltd."],
    ["BMUNI", "Bermuda Digital Communications"],
    ["BOLME", "Entel S.A."],
    ["BOLNT", "Nuevatel PCS de Bolivia S.A."],
    ["BOLTE", "Telefonica Celular de Bolivia S.A."],
    ["BRAAC", "Oi M�vel S.A"],
    ["BRAC3", "Algar Telecom S.A."],
    ["BRAC4", "Algar Telecom S.A."],
    ["BRACL", "Claro S.A"],
    ["BRACS", "TIM S.A."],
    ["BRACT", "Algar Telecom S.A."],
    ["BRAN3", "Nextel Telecomunicações Ltda"],
    ["BRANC", "Nextel Telecomunicações Ltda."],
    ["BRAPC", "Porto Seguro Telecomunicações Ltd. (Porto Conecta)"],
    ["BRASC", "Sercomtel"],
    ["BRATC", "Vivo S.A."],
    ["BRATM", "Oi Móvel S.A"],
    ["BRBCW", "Cable & Wireless (Barbados) Ltd"],
    ["BRBDC", "Digicel (Barbados) Limited"],
    ["BRNBR", "Progresif Cellular Sdn Bhd"],
    ["BRNDS", "Datastream Technology Sdn Bhd"],
    ["BRNDS", "DST Communications Sdn Bhd"],
    ["BTNBM", "Bhutan Telecom Ltd."],
    ["BTNTC", "Tashi InfoComm Ltd, Thimphu"],
    ["BWABC", "Botswana Telecommunications Corporation"],
    ["BWAGA", "Mascom Wireless (Pty) Limited"],
    ["BWAVC", "Orange (Botswana) Pty Ltd."],
    ["CAF02", "Telecel Centrafrique SA"],
    ["CAF03", "Orange Centrafric"],
    ["CAFAT", "Atlantique Telecom Centrafrique SA"],
    ["CAFNL", "Nationlink Telecom"],
    ["CAN02", "Xplornet Communications Inc."],
    ["CANBM", "Bell Mobility Inc."],
    ["CANEL", "Bragg Communications Inc."],
    ["CANET", "Execulink Telecom Inc"],
    ["CANGW", "Freedom Mobile Inc"],
    ["CANIW", "Ice Wireless Inc."],
    ["CANKO", "Keewaytinook Okimakanak"],
    ["CANLM", "Lynx Mobility Inc."],
    ["CANMM", "Bell Mobility Inc."],
    ["CANRW", "Rogers Communications Canada Inc."],
    ["CANRW", "Rogers Communications Partnership"],
    ["CANST", "SaskTel"],
    ["CANTE", "Telus"],
    ["CANTS", "TELUS Communications Inc."],
    ["CANVT", "Videotron Ltd"],
    ["CANWT", "Wightman Telecom Ltd"],
    ["CHE01", "SMSRelay AG"],
    ["CHEC1", "Swisscom (Switzerland) Ltd"],
    ["CHEDX", "Sunrise Communications AG"],
    ["CHEGN", "Bebbicell"],
    ["CHEHO", "Horisen AG"],
    ["CHEIP", "in&phone (inactive)"],
    ["CHEMT", "Mitto AG"],
    ["CHEOA", "SITA OnAir Switzerland Sarl"],
    ["CHEOR", "Salt Mobile SA"],
    ["CHESB", "SBB Switzerland"],
    ["CHEUC", "upc cablecom GmbH"],
    ["CHLMV", "Entel PCS Telecomunicaciones S.A."],
    ["CHLN3", "WOM S.A."],
    ["CHLNC", "Centennial Cayman Corp Chile S.A.(Nextel)"],
    ["CHLPC", "Entel"],
    ["CHLSM", "Claro Chile SA"],
    ["CHLTM", "Telefónica Móvil de Chile S.A."],
    ["CHLVT", "VTR Móvil S.A."],
    ["CHNCC", "China Unicom"],
    ["CHNCN", "China Unicom"],
    ["CHNCT", "CITIC Telecom 1616 (SMS Hub Termination Only)"],
    ["CHNCT", "China Mobile"],
    ["CHNCT", "China Mobile Limited"],
    ["CHNCU", "China Unicom"],
    ["CHNDX", "China Telecommunications Corporation"],
    ["CIV02", "Atlantique Telecom Côte d'Ivoire"],
    ["CIV03", "Orange Côte d'Ivoire S.A"],
    ["CIVCM", "Comium Côte d'Ivoire SA (CCI SA)"],
    ["CIVOR", "Oricel"],
    ["CIVTL", "MTN Cote d'Ivoire S.A"],
    ["CIVWT", "Warid Cote d'Ivoire"],
    ["CMR02", "Orange Cameroun S.A."],
    ["CMRMT", "VIETTEL CAMEROUN S.A.R.L"],
    ["CMRMT", "MTN Cameroon Ltd"],
    ["CODAC", "Africell RDC sprl"],
    ["CODCT", "AIRTEL CONGO RDC SA"],
    ["CODOR", "Orange RDC Sarl"],
    ["CODSA", "Orange RDC Sarl"],
    ["CODVC", "Vodacom Congo (RDC) S.A."],
    ["COGCT", "Warid Congo JSC"],
    ["COGCT", "Airtel Congo SA"],
    ["COGEQ", "Equateur Telecom Congo S.A. (E.T.C.)"],
    ["COGLB", "MTN Congo S.A"],
    ["COKTC", "Telecom Cook Islands Limited"],
    ["COLAV", "Avantel S.A.S"],
    ["COLCM", "Comunicacion Celular S.A (Comcel S.A)"],
    ["COLCO", "Colombia Movil S.A."],
    ["COLTM", "Colombia Telecomunicaciones S.A. ESP"],
    ["COMHR", "Societe Nationale des Telecommunications (Comores Telecom)"],
    ["COMTM", "TELCO S.A."],
    ["CPVCV", "CVMóvel, S.A."],
    ["CPVTM", "UNITEL T+ TELECOMUNICACÕES, Sociedade Unipessoal S.A."],
    ["CRICL", "Claro CR Telecomunicaciones, S.A."],
    ["CRICR", "Instituto Costarricense de Electricidad - ICE"],
    ["CRICR", "I.C.E. (Instituto Costarricense de Electricidad)"],
    ["CRITC", "Telefónica de Costa Rica TC, S.A."],
    ["CUB01", "Empresa de Telecomunicaciones de Cuba, SA (ETECSA)"],
    ["CYMCW", "Cable and Wireless (Cayman Islands) Ltd"],
    ["CYPCT", "Cyprus Telecommunications Authority"],
    ["CYPPT", "PrimeTel PLC"],
    ["CYPSC", "MTN Cyprus Limited"],
    ["CZECM", "Vodafone Czech Republic a.s."],
    ["CZEET", "O2 Czech Republic a.s."],
    ["CZERM", "T-Mobile Czech Republic a.s."],
    ["CZETT", "Travel Telekommunikation s.r.o."],
    ["DEU13", "MobilCom Multimedia GmbH"],
    ["DEUD1", "Telekom Deutschland GmbH"],
    ["DEUD2", "Vodafone GmbH"],
    ["DEUE1", "Telefónica Germany GmbH & Co. OHG"],
    ["DEUHN", "HNS GmbH"],
    ["DEUTV", "TelcoVillage Roaming Services GmbH"],
    ["DJIDJ", "Djibouti Telecom S.A."],
    ["DMACW", "Cable & Wireless Dominica Limited"],
    ["DNK25", "ViaHub Corp. Denmark"],
    ["DNKDM", "Telenor A/S"],
    ["DNKHU", "HI3G Denmark ApS"],
    ["DNKIA", "Telia Nattjanster Norden AB"],
    ["DNKTD", "TDC A/S"],
    ["DOM01", "Altice Dominicana, S.A."],
    ["DOMAC", "Trilogy Dominicana S.A."],
    ["DOMCL", "Compañia Dominicana de Teléfonos, S.A."],
    ["DZAA1", "ATM Mobilis"],
    ["DZAOT", "Optimum Telecom Algeria Spa"],
    ["DZAWT", "Wataniya Télécom Algérie"],
    ["ECUAL", "Corporación Nacional de Telecomunicaciones CNT E.P."],
    ["ECUOT", "Otecel Sociedad Anónima"],
    ["ECUPG", "Conecel S.A. (Consorcio Ecuatoriano de Telecomunicaciones S.A.)"],
    ["EGYAR", "Orange Egypt for Telecommunications"],
    ["EGYEM", "Etisalat - Misr"],
    ["EGYMS", "Vodafone Egypt Telecommunications S.A.E"],
    ["EGYTE", "Telecom Egypt"],
    ["EMNDE", "EMnify GmbH"],
    ["ESPAT", "Vodafone España, S.A.U."],
    ["ESPKP", "E-Plus Moviles Virutales Espana (iBasis/KPN)"],
    ["ESPON", "Vodafone España, S.A.U. (inactive)"],
    ["ESPRT", "Orange Espagne, S.A., sociedad unipersonal"],
    ["ESPTE", "Telefónica Móviles España S.A."],
    ["ESPXF", "Xfera Móviles, S.A."],
    ["ESPXF", "Xfera Moviles S.A. (Yoigo)"],
    ["EST4G", "Progroup Holding OU"],
    ["ESTEM", "Telia Eesti AS"],
    ["ESTRB", "Tele2 Eesti Aktsiaselts"],
    ["ESTRE", "Elisa Eesti AS"],
    ["ESTVX", "VIVEX OU"],
    ["ETH01", "Ethio Telecom"],
    ["FIN2G", "DNA Ltd."],
    ["FIN2G", "DNA Plc"],
    ["FINAM", "Ålands Telekommunikation Ab"],
    ["FINRL", "Elisa Corporation"],
    ["FINTF", "TeliaSonera Finland Oyj"],
    ["FJIDP", "Digicel (Fiji) Ltd"],
    ["FJIVF", "Vodafone Fiji Pte Limited"],
    ["FLKCW", "Sure South Atlantic Limited"],
    ["FRA09", "Société Française du Radiotéléphone S.A."],
    ["FRA0F", "Halys SAS"],
    ["FRAF1", "Orange"],
    ["FRAF2", "Société Française du Radiotéléphone S.A."],
    ["FRAF3", "Bouygues Télécom"],
    ["FRAF4", "Digicel Antilles Française Guyane"],
    ["FRAFM", "Free Mobile"],
    ["FRAGS", "Globalstar"],
    ["FRALS", "Legos"],
    ["FRANJ", "NRJ Mobile"],
    ["FRAOT", "OMEA TELECOM"],
    ["FRARE", "Société Réunionnaise du Radiotéléphone"],
    ["FRASA", "SAP Digital Interconnect"],
    ["FRATK", "Onati S.A.S."],
    ["FRAUT", "United Telecommunications Services Caraibe"],
    ["FROFT", "Føroya Tele (Faroese Telecom)"],
    ["FROKA", "P/F Kall"],
    ["FSMFM", "Federated States of Micronesia Telecommunications Corporation"],
    ["GAB01", "Libertis S.A."],
    ["GABAZ", "USAN-Gabon"],
    ["GABCT", "Celtel Gabon S.A."],
    ["GABTL", "Libertis S.A."],
    ["GBR24", "Stour Marine Limited"],
    ["GBR3O", "Mundio Mobile"],
    ["GBRAJ", "Jersey Airtel Limited"],
    ["GBRC9", "Cloud 9 Mobile Communications PLC"],
    ["GBRCA", "Cable & Wireless Jersey (inactive)"],
    ["GBRCN", "Telefónica UK Limited"],
    ["GBRGA", "Gamma Telecom Limited"],
    ["GBRGT", "Sure (Guernsey) Limited"],
    ["GBRHH", "HANHAA Ltd"],
    ["GBRHU", "Hutchison 3G UK Limited"],
    ["GBRHW", "SMShighway"],
    ["GBRJT", "JT (Jersey) Limited"],
    ["GBRKY", "Sky UK Limited"],
    ["GBRLI", "Limitless Mobile"],
    ["GBRLV", "Virgin Mobile Telecoms Ltd"],
    ["GBRME", "EE Limited"],
    ["GBRME", "Everything Everywhere (T-Mobile) Limited"],
    ["GBRMJ", "Marathon Telecom Limited"],
    ["GBRMT", "Manx Telecom"],
    ["GBROR", "EE Limited"],
    ["GBRTR", "Truphone Limited"],
    ["GBRUM", "TISMI"],
    ["GBRVF", "Vodafone Ltd"],
    ["GEOGC", "Silknet JSC"],
    ["GEOGM", "GMobile LLC"],
    ["GEOMA", "Magticom Ltd"],
    ["GEOMT", "Veon Georgia LLC"],
    ["GHAGM", "Glo Mobile Ghana Limited"],
    ["GHAGT", "Vodafone Ghana"],
    ["GHAMT", "Airtel Ghana Limited"],
    ["GHASC", "Scancom PLC"],
    ["GIBGT", "Gibtelecom Limited"],
    ["GIN02", "Sotelgui sa"],
    ["GIN03", "INTERCEL Guinée"],
    ["GIN07", "Cellcom Guinea"],
    ["GINAG", "Areeba Guinée S.A."],
    ["GINGS", "Orange Guinée S.A."],
    ["GLP01", "Orange Caraibe"],
    ["GLPDT", "Dauphin Télécom"],
    ["GMB01", "Gambia Telecommunications Cellular Company Ltd (Gamcell)"],
    ["GMBAC", "Africell (Gambia) Limited"],
    ["GMBCM", "Comium Gambia Ltd."],
    ["GMBQC", "QCell"],
    ["GNB03", "Orange Bissau"],
    ["GNBSB", "Spacetel Guinée-Bissau SA"],
    ["GNQ01", "Guinea Ecuatorial de Telecomunicaciones Sociedad Anonima"],
    ["GNQHT", "Muni S.A."],
    ["GRC07", "AMD Telecom S.A."],
    ["GRCCO", "Cosmote Mobile Telecommunications S.A."],
    ["GRCPF", "Vodafone-Panafon Hellenic Telecommunications Company S.A."],
    ["GRCSH", "Wind Hellas Telecommunications S.A."],
    ["GRDCW", "Cable & Wireless Grenada Limited"],
    ["GRLTG", "Tele Greenland  A/S"],
    ["GTMCM", "Comunicaciones Celulares S.A."],
    ["GTMSC", "Telecomunicaciones de Guatemala, S.A. (TELGUA)"],
    ["GTMTG", "Telecomunicaciones de Guatemala, S.A. (TELGUA)"],
    ["GUF01", "Outremer Télécom"],
    ["GUMDP", "DOCOMO PACIFIC, INC. (Guam)"],
    ["GUMHT", "DOCOMO PACIFIC, INC. (Guam)"],
    ["GUMIW", "IT&E Overseas| Inc."],
    ["GUMWR", "Wave Runner, LLC"],
    ["GUYGT", "Guyana Telephone and Telegraph Company"],
    ["GUYUM", "U-Mobile (Cellular) Inc"],
    ["HKG35", "Webbing Hong Kong Limited"],
    ["HKGCT", "CITIC Telecom International Ltd."],
    ["HKGCU", "CHINA UNICOM (HONG KONG) OPERATIONS LIMITED"],
    ["HKGH3", "Hutchison Telecom (HK) Ltd."],
    ["HKGH3", "Hutchison Telecommunications Hong Kong Holdings Limited"],
    ["HKGPP", "China Mobile Hong Kong Peoples"],
    ["HKGPP", "China Mobile Hong Kong Company Limited"],
    ["HKGSM", "SmarTone Mobile Communications Limited"],
    ["HKGTC", "Hong Kong Telecommunications (HKT) Limited"],
    ["HND02", "Telefónica Celular, S.A. (CELTEL)"],
    ["HNDHM", "Empresa Hondure�a de Telecomunicaciones (HONDUTEL)"],
    ["HNDME", "Servicios de Comunicaciones de Honduras S.A. de C.V."],
    ["HRVCN", "Hrvatski Telekom d.d."],
    ["HRVT2", "Tele2 d.o.o za telekomunikacijske usluge"],
    ["HRVVI", "A1 Hrvatska d.o.o"],
    ["HTICL", "Communication Cellulaire d'Haiti SA"],
    ["HTIVT", "National Telecom S.A"],
    ["HUND1", "DIGI Telecommunication and Services Ltd."],
    ["HUNH1", "Telenor Magyarország Zártkörűen Működő Részvénytársaság"],
    ["HUNH2", "Magyar Telekom Nyrt."],
    ["HUNUM", "LGI Mobile (UPC Magyarország Kft.)"],
    ["HUNVR", "Vodafone Hungary Mobile Telecommunications Company Limited"],
    ["IDN89", "PT. Hutchison 3 Indonesia"],
    ["IDNEX", "PT. XL Axiata Tbk"],
    ["IDNM8", "PT. Smartfren Telecom Tbk."],
    ["IDNSL", "PT Indosat Tbk"],
    ["IDNST", "PT. Smartfren Telecom Tbk."],
    ["IDNTS", "PT Telekomunikasi Selular"],
    ["IND01", "Aircel Limited"],
    ["IND02", "Aircel Limited"],
    ["IND03", "Aircel Limited"],
    ["IND04", "Aircel Limited"],
    ["IND05", "Aircel Limited"],
    ["IND06", "Aircel Limited"],
    ["IND07", "Vodafone Idea Limited"],
    ["IND08", "Aircel Limited"],
    ["IND09", "Aircel Limited"],
    ["IND17", "Aircel Limited"],
    ["IND18", "Aircel Limited"],
    ["IND19", "Aircel Limited"],
    ["IND20", "Aircel Limited"],
    ["IND21", "Aircel Limited"],
    ["IND22", "Aircel Limited"],
    ["IND23", "Aircel Limited"],
    ["IND24", "Aircel Limited"],
    ["IND25", "Aircel Limited"],
    ["IND26", "Aircel Limited"],
    ["IND27", "Aircel Limited"],
    ["IND28", "Aircel Limited"],
    ["IND29", "Aircel Limited"],
    ["INDA1", "Bharti Airtel Limited"],
    ["INDA2", "Bharti Airtel Limited"],
    ["INDA3", "Bharti Airtel Limited"],
    ["INDA4", "Bharti Airtel Limited"],
    ["INDA5", "Bharti Airtel Limited"],
    ["INDA6", "Bharti Airtel Limited"],
    ["INDA7", "Bharti Airtel Limited"],
    ["INDA8", "Bharti Airtel Limited"],
    ["INDA9", "Bharti Airtel Limited"],
    ["INDAC", "Aircel Limited"],
    ["INDAM", "Vodafone Idea Limited"],
    ["INDAP", "Bharat Sanchar Nigam Limited"],
    ["INDAS", "Bharat Sanchar Nigam Limited"],
    ["INDAT", "Bharti Airtel Limited"],
    ["INDB1", "Loop Mobile (India) Limited"],
    ["INDBH", "Bharat Sanchar Nigam Limited"],
    ["INDBI", "Vodafone Idea Limited"],
    ["INDBK", "Vodafone Idea Limited"],
    ["INDBL", "Bharti Airtel Limited"],
    ["INDBM", "Vodafone Idea Limited"],
    ["INDBO", "Vodafone Idea Limited"],
    ["INDBR", "Bihar"],
    ["INDBT", "Vodafone Idea Limited"],
    ["INDCA", "Bharat Sanchar Nigam Limited"],
    ["INDCC", "Vodafone Idea Limited"],
    ["INDCH", "Bharat Sanchar Nigam Limited"],
    ["INDDA", "Videocon Telecommunications Limited"],
    ["INDDB", "Videocon Telecommunications Limited"],
    ["INDDC", "Videocon Telecommunications Limited"],
    ["INDDD", "Videocon Telecommunications Limited"],
    ["INDDF", "Videocon Telecommunications Limited"],
    ["INDDG", "Videocon Telecommunications Limited"],
    ["INDDH", "Videocon Telecommunications Limited"],
    ["INDDI", "Videocon Telecommunications Limited"],
    ["INDDJ", "Videocon Telecommunications Limited"],
    ["INDDK", "Videocon Telecommunications Limited"],
    ["INDDL", "Mahanagar Telephone Nigam Limited"],
    ["INDDM", "Videocon Telecommunications Limited"],
    ["INDDN", "Videocon Telecommunications Limited"],
    ["INDDO", "Videocon Telecommunications Limited"],
    ["INDDP", "Videocon Telecommunications Limited"],
    ["INDDQ", "Videocon Telecommunications Limited"],
    ["INDDR", "Videocon Telecommunications Limited"],
    ["INDDS", "Videocon Telecommunications Limited"],
    ["INDDT", "Videocon Telecommunications Limited"],
    ["INDDU", "Videocon Telecommunications Limited"],
    ["INDE1", "Vodafone Idea Limited"],
    ["INDEB", "Telenor (India) Communications Private Limited"],
    ["INDEE", "Telenor (India) Communications Private Limited"],
    ["INDEH", "Vodafone Idea Limited"],
    ["INDEK", "Vodafone Idea Limited"],
    ["INDEU", "Vodafone Idea Limited"],
    ["INDF1", "Vodafone Idea Limited"],
    ["INDGJ", "Bharat Sanchar Nigam Limited"],
    ["INDGU", "Gujarat"],
    ["INDH1", "Bharti Airtel Limited"],
    ["INDHM", "Vodafone Idea Limited"],
    ["INDHP", "Bharat Sanchar Nigam Limited"],
    ["INDHR", "Bharat Sanchar Nigam Limited"],
    ["INDIB", "Vodafone Idea Limited"],
    ["INDID", "Vodafone Idea Limited"],
    ["INDIH", "Vodafone Idea Limited"],
    ["INDIJ", "Vodafone Idea Limited"],
    ["INDIK", "Vodafone Idea Limited"],
    ["INDIM", "Vodafone Idea Limited"],
    ["INDIO", "Vodafone Idea Limited"],
    ["INDIR", "Vodafone Idea Limited"],
    ["INDIT", "Vodafone Idea Limited"],
    ["INDIU", "Vodafone Idea Limited"],
    ["INDIW", "Vodafone Idea Limited"],
    ["INDJ1", "Reliance Jio Infocomm Limited"],
    ["INDJ2", "Reliance Jio Infocomm Limited"],
    ["INDJ3", "Reliance Jio Infocomm Limited"],
    ["INDJ4", "Reliance Jio Infocomm Limited"],
    ["INDJ5", "Reliance Jio Infocomm Limited"],
    ["INDJ6", "Reliance Jio Infocomm Limited"],
    ["INDJ7", "Reliance Jio Infocomm Limited"],
    ["INDJ8", "Reliance Jio Infocomm Limited"],
    ["INDJ9", "Reliance Jio Infocomm Limited"],
    ["INDJB", "Bharti Airtel Limited"],
    ["INDJH", "Bharti Airtel Limited"],
    ["INDJK", "Bharat Sanchar Nigam Limited"],
    ["INDJL", "Reliance Jio Infocomm Limited"],
    ["INDJM", "Reliance Jio Infocomm Limited"],
    ["INDJN", "Reliance Jio Infocomm Limited"],
    ["INDJO", "Reliance Jio Infocomm Limited"],
    ["INDJP", "Reliance Jio Infocomm Limited"],
    ["INDJQ", "Reliance Jio Infocomm Limited"],
    ["INDJR", "Reliance Jio Infocomm Limited"],
    ["INDJS", "Reliance Jio Infocomm Limited"],
    ["INDJT", "Reliance Jio Infocomm Limited"],
    ["INDJU", "Reliance Jio Infocomm Limited"],
    ["INDJV", "Reliance Jio Infocomm Limited"],
    ["INDJW", "Reliance Jio Infocomm Limited"],
    ["INDJX", "Reliance Jio Infocomm Limited"],
    ["INDKR", "Bharat Sanchar Nigam Limited"],
    ["INDKT", "Bharat Sanchar Nigam Limited"],
    ["INDMB", "Mahanagar Telephone Nigam Limited"],
    ["INDMH", "Bharat Sanchar Nigam Limited"],
    ["INDMP", "Vodafone Idea Limited"],
    ["INDMT", "Bharti Airtel Limited"],
    ["INDMW", "Telenor (India) Communications Private Limited"],
    ["INDMY", "Bharat Sanchar Nigam Limited"],
    ["INDNE", "Bharat Sanchar Nigam Limited"],
    ["INDNW", "Telenor (India) Communications Private Limited"],
    ["INDOR", "Bharat Sanchar Nigam Limited"],
    ["INDPB", "Bharat Sanchar Nigam Limited"],
    ["INDRC", "Aircel Limited"],
    ["INDRJ", "Bharat Sanchar Nigam Limited"],
    ["INDRM", "Reliance Communications Ltd."],
    ["INDSA", "Telenor (India) Communications Private Limited"],
    ["INDSC", "Bharti Airtel Limited"],
    ["INDSK", "Vodafone Idea Limited"],
    ["INDSP", "Vodafone Idea Limited"],
    ["INDT0", "Tata Teleservices Limited"],
    ["INDT1", "Tata Teleservices Limited"],
    ["INDT2", "Tata Teleservices Limited"],
    ["INDT3", "Tata Teleservices Limited"],
    ["INDT4", "Tata Teleservices Limited"],
    ["INDT5", "Tata Teleservices Limited"],
    ["INDT6", "Tata Teleservices Limited"],
    ["INDT7", "Tata Teleservices Limited"],
    ["INDT8", "Tata Teleservices Limited"],
    ["INDT9", "Tata Teleservices Limited"],
    ["INDTA", "Telenor (India) Communications Private Limited"],
    ["INDTB", "Tata Teleservices Limited"],
    ["INDTD", "Tata Teleservices Limited"],
    ["INDTG", "Tata Teleservices Limited"],
    ["INDTH", "Tata Teleservices Limited"],
    ["INDTK", "Tata Teleservices Limited"],
    ["INDTM", "Tata Teleservices Limited"],
    ["INDTN", "Bharat Sanchar Nigam Limited"],
    ["INDTO", "Tata Teleservices Limited"],
    ["INDTP", "Tata Teleservices Limited"],
    ["INDTR", "Tata Teleservices Limited"],
    ["INDUE", "Bharat Sanchar Nigam Limited"],
    ["INDUW", "Bharat Sanchar Nigam Limited"],
    ["INDWB", "Bharat Sanchar Nigam Limited"],
    ["INDWG", "Telenor (India) Communications Private Limited"],
    ["INTJS", "Jasper Technologies, inc"],
    ["INXIX", "Inmarsat Plc"],
    ["IRLEC", "Vodafone Ireland Limited"],
    ["IRLH3", "Three Ireland (Hutchison) Limited"],
    ["IRLLT", "Three Ireland (Hutchison) Limited"],
    ["IRLME", "Meteor Mobile Communications Limited"],
    ["IRLUP", "LGI Mobile (UPC IE)"],
    ["IRN11", "Mobile Telecommunication Company of Iran"],
    ["IRNAP", "MTN-Irancell"],
    ["IRNKI", "Telecommunication Kish Company (TKC)"],
    ["IRNMI", "Irancell Telecommunications Services Company"],
    ["IRNRI", "Gostaresh Ertebatat Taliya PJS"],
    ["IRNST", "Rightel Communication Services Company PJS"],
    ["IRNTT", "Rightel Communication Services Company PJS"],
    ["IRQAC", "Asiacell Communications PJSC"],
    ["IRQAT", "Atheer Telecommunications Iraq Limited"],
    ["IRQFL", "Regional Telecom Company"],
    ["IRQKK", "Korek Telecom Company"],
    ["ISLNO", "Nova ehf."],
    ["ISLPS", "Síminn h.f."],
    ["ISLTL", "Sýn hf."],
    ["ISLVW", "IMC Ísland ehf."],
    ["ISR01", "Partner Communications Company Ltd."],
    ["ISR23", "Beezz Communication Solutions L.T.D"],
    ["ISRCL", "Cellcom Israel Ltd."],
    ["ISRHC", "Proximus PLC"],
    ["ISRMC", "HOT mobile Ltd. (MiRS)"],
    ["ISRMS", "Hot Mobile Ltd."],
    ["ISRPL", "Pelephone Communication Ltd."],
    ["ITAAT", "Agile Telecom"],
    ["ITAFM", "ILIAD Italia S.p.A."],
    ["ITAGS", "Elsacom S.p.A."],
    ["ITAGT", "Telecom Italia SpA"],
    ["ITAIR", "Fastweb S.p.A. (Swisscom Affiliate)"],
    ["ITAOM", "Vodafone Italia S.p.A"],
    ["ITASI", "Telecom Italia SpA"],
    ["ITAWI", "Wind Tre S.p.A."],
    ["ITAWI", "Wind Tre S.p.A. (ITAWI)"],
    ["JAMCW", "Cable & Wireless Jamaica Limited"],
    ["JAMDC", "Digicel (Jamaica) Limited"],
    ["JORFL", "Jordan Mobile Telephone Services Company, Ltd."],
    [
      "JORMC",
      "Petra Jordanian Mobile Telecommunications Company (Orange Jordan)",
    ],
    ["JORUM", "Umniah Mobile Company"],
    ["JPNDO", "NTT DOCOMO, Inc."],
    ["JPNEM", "SoftBank Corp. (inactive)"],
    ["JPNJP", "SoftBank Corp."],
    ["JPNKD", "KDDI Corporation"],
    ["K0001", "IPKO Telecommunications LLC"],
    ["K00TK", "Kosovo Telecom JSC"],
    ["KAZ77", "Mobile Telecom-Service LLP"],
    ["KAZKT", "KaR-Tel LLP"],
    ["KAZKZ", "Kcell JSC"],
    ["KENEC", "Airtel Networks Kenya Limited"],
    ["KENKC", "Airtel Networks Kenya Limited"],
    ["KENSA", "Safaricom PLC"],
    ["KENTK", "Telkom Kenya Limited"],
    ["KGZ01", "Sky Mobile LLC"],
    ["KGZMC", "JSC Alfa Telecom"],
    ["KGZNT", "Nur Telecom LLC"],
    ["KHM3A", "South East Asia Telecom (Cambodia) Co., Ltd"],
    ["KHMCC", "Cambodia Advance Communications Co., Ltd (CADCOMMS)"],
    ["KHMGM", "CamGSM Co.Ltd."],
    ["KHMSH", "Mfone Co., Ltd"],
    ["KHMSM", "Smart Axiata Co., Ltd."],
    ["KHMVC", "Beeline"],
    ["KHMVC", "Viettel (Cambodia) Pte., Ltd."],
    ["KIRKL", "Amalgamated Telecom Holdings Kiribati Limited (ATHKL)"],
    ["KNACW", "Cable & Wireless St Kitts & Nevis Limited"],
    ["KORKF", "KT Corporation"],
    ["KORKT", "KT Freetel"],
    ["KORLU", "LG Uplus, Corp."],
    ["KORLU", "LG Uplus| Corp."],
    ["KORSK", "SK Telecom Co., Ltd."],
    ["KWTKT", "Kuwait Telecom Company (K.S.C.)"],
    ["KWTMT", "Mobile Telecommunications Company"],
    ["KWTNM", "National Mobile Telecommunications Company (K.S.C)"],
    ["LAOAS", "Star Telecom Company Limited (STL)"],
    ["LAOET", "ETL Company Limited"],
    ["LAOTC", "Lao Telecommunications Co., Ltd"],
    ["LAOTL", "VimpelCom Lao Company Ltd."],
    ["LBNFL", "Mobile Interim Company 1 (MIC1)"],
    ["LBNLC", "Mobile Interim Company 2 (MIC2)"],
    ["LBR07", "Orange Liberia"],
    ["LBRLC", "Novafone Inc."],
    ["LBRLC", "Lonestar Communications Corporation"],
    ["LBY01", "Al Madar Al Jadeed"],
    ["LBYLM", "Libyana Mobile Phone"],
    ["LCACW", "Cable & Wireless (St. Lucia) Limited"],
    ["LIECT", "Cubic Telecom Limited LIECHTENSTEIN"],
    ["LIEEM", "EMnify GmbH"],
    ["LIEMK", "Telecom Liechtenstein AG"],
    ["LIESO", "Telecom Liechtenstein AG"],
    ["LIETG", "TELE 2 AKTIENGE"],
    ["LIEVE", "Salt (Liechtenstein) AG"],
    ["LKA71", "Mobitel (Pvt) Limited"],
    ["LKAAT", "Bharti Airtel Lanka (Private) Limited"],
    ["LKACT", "ETISALAT Lanka (Private) Limited"],
    ["LKADG", "Dialog Axiata Plc"],
    ["LKAHT", "Hutchison Telecom Sri Lanka"],
    ["LSOET", "Econet Telecom Lesotho (Pty) Ltd (ETL)"],
    ["LSOVL", "Vodacom Lesotho (Pty) Ltd"],
    ["LTU03", "UAB Tele2"],
    ["LTUMT", "UAB Bitė Lietuva"],
    ["LTUOM", "Telia Lietuva AB"],
    ["LUXEL", "e-LUX Mobile"],
    ["LUXPT", "POST Luxembourg"],
    ["LUXTG", "Proximus Luxembourg S.A."],
    ["LUXVM", "Orange Communications Luxembourg S.A."],
    ["LVABC", "Tele2 SIA"],
    ["LVABT", "SIA Bite Mobile"],
    ["LVALM", "Latvijas Mobilais Telefons"],
    ["LVAVM", "Ventamobile"],
    ["MACCT", "Companhia de Telecomunicações de Macau, S.A.R.L."],
    ["MACHT", "Hutchison Telephone (Macau) Company Limited"],
    ["MACHT", "Hutchison Telephone (Macau) Company Ltd."],
    ["MACSM", "SmarTone Mobile Communications (Macau) Limited"],
    ["MARM1", "Itissalat Al-Maghrib S.A."],
    ["MARM3", "Wana Corporate S.A."],
    ["MARMT", "Orange Maroc"],
    ["MCOM1", "Monaco Telecom S.A.M."],
    ["MCOM2", "Monaco Telecom S.A.M."],
    ["MDAMC", "Moldcell S.A"],
    ["MDAUN", "Moldtelecom S.A."],
    ["MDAUN", "Moldtelecom"],
    ["MDAVX", "Orange Moldova S.A."],
    ["MDGAN", "Orange Madagascar S.A."],
    ["MDGCO", "Airtel Madagascar SA"],
    ["MDGTM", "TELECOM MALAGASY (TELMA) S.A."],
    ["MDV01", "Dhivehi Raajjeyge Gulhun Public Limited Company"],
    ["MDVWM", "Ooredoo Maldives Public Limited Company"],
    ["MEXAT", "AT&T Comercializacion Movil, S. de R.L. de C.V."],
    ["MEXIU", "AT&T Comercializacion Movil, S. de R.L. de C.V."],
    ["MEXMS", "Pegaso PCS, S.A. de C.V."],
    ["MEXMS", "Pegaso PCS| S.A. de C.V."],
    ["MEXN3", "AT&T Comercializacion Movil, S. de R.L. de C.V."],
    ["MEXN3", "AT&T Comunicaciones Digitales, S.de R.L. de C.V."],
    ["MEXN3", "AT&T Comunicaciones Digitales| S. de R.L. de C.V."],
    ["MEXTL", "Radiomóvil Dipsa, S.A. de C.V."],
    ["MHLMI", "Marshall Islands National Telecommunications Authority (NTA)"],
    ["MKDCC", "one.Vip DOOEL Skopje"],
    ["MKDMM", "Makedonski Telekom AD - Skopje"],
    ["MKDNO", "one.Vip DOOEL Skopje"],
    ["MLI01", "Malitel - SA"],
    ["MLI02", "Orange"],
    ["MLI03", "Alpha Telecommunication Mali S.A."],
    ["MLTGO", "GO p.l.c."],
    ["MLTMM", "Melita Ltd."],
    ["MLTTL", "VODAFONE MALTA LTD - MARITIME ROAMING"],
    ["MLTTL", "Vodafone Malta Ltd."],
    ["MLTTL", "Vodafone Malta Limited"],
    ["MMROM", "Ooredoo Myanmar Limited"],
    ["MMRPT", "Myanma Posts and Telecommunications"],
    ["MMRTN", "Telenor Myanmar Limited"],
    ["MMRVG", "Telecom International Myanmar Co., Ltd"],
    ["MNEMT", "MTEL d.o.o. Podgorica"],
    ["MNEPM", "Telenor d.o.o. Podgorica"],
    ["MNETM", "Crnogorski Telekom a.d. Podgorica"],
    ["MNGGM", "G-Mobile Corporation"],
    ["MNGMC", "MobiCom Corporation"],
    ["MNGMN", "Unitel LLC"],
    ["MNGSK", "Skytel LLC"],
    ["MOZ01", "Moçambique Celular S.A.R.L."],
    ["MOZVC", "VM, S.A."],
    ["MOZVG", "Movitel S.A."],
    ["MRTCH", "Chinguitel S.A."],
    ["MRTMM", "Mauritanienne des Télécommunications S.A (Mauritel)"],
    ["MRTMT", "Tunisian Mauritanian Company of Telecommunications (MATTEL)"],
    ["MSRCW", "Cable and Wireless (West Indies) Ltd."],
    ["MTX01", "MTX Connect S.à.r.l."],
    ["MUSCP", "Cellplus Mobile Communications Ltd."],
    ["MUSEM", "Emtel Ltd"],
    ["MUSMT", "Mahanagar Telephone (Mauritius) Ltd."],
    ["MWICP", "Telekom Networks Malawi Limited"],
    ["MWICT", "Airtel Malawi Limited"],
    ["MYSAL", "ALTEL Communications Sdn Bhd"],
    ["MYSBC", "Maxis Broadband Sdn. Bhd."],
    ["MYSCC", "Celcom Axiata Berhad"],
    ["MYSMI", "U Mobile Sdn. Bhd."],
    ["MYSMT", "Digi Telecommunications Sdn Bhd"],
    ["MYSP1", "webe digital sdn bhd"],
    ["MYSYC", "YTL Communications Sdn. Bhd."],
    ["NAM01", "Mobile Telecommunications Limited"],
    ["NAM03", "Telecom Namibia Limited"],
    ["NCLPT", "Office des Postes et Telecommunications"],
    ["NERCT", "Celtel Niger S.A."],
    ["NEROR", "Orange Niger S.A."],
    ["NERTL", "Telecel Niger SA"],
    ["NFKNT", "Norfolk Telecom"],
    ["NGAEM", "Emerging Markets Telecommunication Services Ltd"],
    ["NGAET", "Airtel Networks Limited"],
    ["NGAGM", "Globacom Limited"],
    ["NGAMN", "MTN Nigeria Communications Limited"],
    ["NGANT", "NATCOM Development and Investment Ltd."],
    ["NICEN", "Empresa Nicaraguense de Telecomunicaciones S.A. - ENITEL"],
    ["NICMS", "Telefónia Celular de Nicaragua S.A."],
    ["NICSC", "Empresa Nicaraguense de Telecomunicaciones S.A. - ENITEL"],
    ["NLDET", "Pareteum"],
    ["NLDIC", "Intercity Zakelijk"],
    ["NLDLT", "Vodafone Libertel B.V."],
    ["NLDPN", "T-Mobile Netherlands B.V."],
    ["NLDPT", "KPN B.V."],
    ["NLDT1", "T-Mobile Netherlands B.V."],
    ["NLDTE", "TATA Communications MOVE Nederland B.V."],
    ["NLDTM", "KPN B.V."],
    ["NLDVW", "Voiceworks (MVNO)"],
    ["NLTSM", "Tismi Mobile"],
    ["NORAM", "AeroMobile AS"],
    ["NORC4", "Com4 AS"],
    ["NORIC", "ICE Communication Norge AS"],
    ["NORMC", "Telenor Maritime AS"],
    ["NORNC", "Telia Norge AS"],
    ["NORTD", "TDC A/S"],
    ["NORTM", "Telenor Norge AS"],
    ["NPLM2", "Ncell Pvt. Ltd"],
    ["NPLNM", "Nepal Doorsanchar Company Ltd"],
    ["NPLST", "Smart Telecom Pvt. Ltd."],
    ["NRUDP", "Digicel (Nauru) Corporation"],
    ["NZLBS", "Vodafone New Zealand Ltd"],
    ["NZLNH", "Two Degrees Networks Limited"],
    ["NZLTM", "Spark New Zealand"],
    ["OMNGT", "Oman Telecommunications Company S.A.O.G."],
    ["OMNNT", "Omani Qatari Telecommunication Company SAOG"],
    ["PAKMK", "Pakistan Mobile Communications Limited (PMCL)"],
    ["PAKPL", "CMPak Limited"],
    ["PAKTP", "Telenor Pakistan (Private) Limited"],
    ["PAKUF", "Pakistan Telecommunication Mobile Limited"],
    ["PAKWA", "Pakistan Mobile Communications Limited (PMCL)"],
    ["PANCL", "Claro Panamá, S.A."],
    ["PANCW", "Cable & Wireless Panamá, S.A."],
    ["PANDC", "Digicel (Panama) S.A."],
    ["PANMS", "Telefónica Móviles Panamá, S.A."],
    ["PERGS", "Globalstar Ltd Parnership (TESAM) Peru"],
    ["PERMO", "Telefónica del Perú S.A.A."],
    ["PERN3", "Entel Perú S.A."],
    ["PERNC", "Nextel Del Peru"],
    ["PERTM", "América Móvil Perú, S.A.C."],
    ["PERVG", "Viettel Peru S.A.C."],
    ["PHLDG", "Smart Communications, Inc."],
    ["PHLGT", "Globe Telecom, Inc."],
    ["PHLNC", "Next Mobile Inc."],
    ["PHLSR", "Smart Communications, Inc."],
    ["PLW3G", "Palau Mobile Corporation (3G)"],
    ["PLWPC", "Palau National Communications Corporation (PNCC)"],
    ["PLWPM", "Palau Mobile Corporation"],
    ["PNGDP", "Digicel (PNG) Limited"],
    ["PNGPM", "Bemobile Limited"],
    ["PNGTM", "Telikom PNG Limited"],
    ["POL02", "T-Mobile Polska S.A."],
    ["POL03", "Orange Polska S.A."],
    ["POLCP", "Polkomtel Sp. z o.o"],
    ["POLEZ", "EZ phone mobile Sp. z o.o."],
    ["POLKM", "Polkomtel Sp. z o.o."],
    ["POLP4", "P4 Sp. z o.o."],
    ["PRICL", "Puerto Rico Telephone Company, Inc."],
    ["PRIOM", "PR Wireless, Inc."],
    ["PRTEP", "MEO - Serviços de Comunicações e Multimédia SA"],
    ["PRTOP", "NOS – Comunicações, S.A."],
    ["PRTTL", "Vodafone Portugal - Comunicações Pessoais, S.A."],
    ["PRTTL", "Vodafone Telecel"],
    ["PRTTM", "MEO - Serviços de Comunicações e Multimédia SA"],
    ["PRYHT", "AMX Paraguay S.A."],
    ["PRYNP", "Núcleo S.A."],
    ["PRYTC", "Telefonica Celular Del Paraguay S.A. (Telecel S.A.)"],
    ["PRYTC", "Telefonica Celular del Paraguay S.A. (Telecel)"],
    ["PRYVX", "Hola Paraguay S.A."],
    ["PSEJE", "Palestine Telecommunications Company"],
    ["PSEWM", "Wataniya Palestine Mobile Telecommunications Company"],
    ["PYFVF", "Pacific Mobile Telecom"],
    ["QATB1", "Vodafone Qatar Q.S.C."],
    ["QATQT", "Ooredoo QSC"],
    ["REU02", "Orange Reunion"],
    ["REUFM", "TELCO OI"],
    ["REUMZ", "ZEOP Mobile"],
    ["REUOT", "TELCO OI"],
    ["ROM05", "S.C. RCS & RDS S.A."],
    ["ROMCS", "Telekom Romania Mobile Communications S.A."],
    ["ROMMF", "S.C. Vodafone Romania S.A."],
    ["ROMMR", "S.C. Orange România S.A."],
    ["RUS01", "PJSC Mobile TeleSystems (MTS)"],
    ["RUS07", "JSC Mobicom Volga"],
    ["RUS14", "Teleset Ltd."],
    ["RUS16", "NTC - RUSSIA"],
    ["RUS27", "TMT LLC"],
    ["RUS33", "GUP S «SEVTELECOM»"],
    ["RUS37", "Kodotel"],
    ["RUS4R", "Sprint LTD"],
    ["RUS50", "Sberbank Telecom"],
    ["RUS82", "K-Telekom"],
    ["RUS84", "Global Telecom LLC (V-Tell)"],
    ["RUSBD", "VimpelCom PJSC"],
    ["RUSEC", "LLC Ekaterinburg-2000"],
    ["RUSGS", "GlobalTel"],
    ["RUSKT", "SUE of RC Krymtelekom"],
    ["RUSNW", "MegaFon PJSC"],
    ["RUST2", "T2 Mobile LLC"],
    ["RUST2", "LLC T2 Mobile"],
    ["RUSXX", "LLC KTK TELECOM"],
    ["RWAAR", "Bharti Airtel Rwanda Holdings B.V."],
    ["RWAMN", "MTN RwandaCell S.A.R.L."],
    ["RWART", "Rwandatel SA"],
    ["RWATG", "Bharti Airtel Rwanda Holdings B.V."],
    ["SAUAJ", "Saudi Telecom Company (STC)"],
    ["SAUET", "Mobily (Etihad Etisalat Company)"],
    ["SAUET", "Etihad Etisalat Company"],
    ["SAUZN", "MTC Saudi Arabia (Zain)"],
    ["SDNBT", "MTN Sudan"],
    ["SDNTL", "Sudan Telecommunications Company"],
    ["SENAZ", "Société Nationale des Télécommunications du Sénégal (SONATEL)"],
    ["SENEX", "Sudan Telecom Company Ltd"],
    ["SENSG", "Sentel GSM S.A."],
    ["SGPDC", "DNA Communications Pte Ltd."],
    ["SGPM1", "M1 Limited"],
    ["SGPML", "SingTel Mobile Singapore Pte. Ltd."],
    ["SGPSH", "StarHub Mobile Pte Ltd"],
    ["SGPST", "SingTel Mobile Singapore Pte. Ltd."],
    ["SGPT1", "TPG Telecom Pte. Ltd."],
    ["SHIPS", "Manx North American & Maritime Extension Service"],
    ["SHNCW", "Sure South Atlantic (St Helena & Ascension Islands) Ltd"],
    ["SLBBM", "Bemobile (Solomon Islands) Limited"],
    ["SLBSI", "Solomon Telekom Company  Limited"],
    ["SLEAC", "Africell Sierra Leone Limited"],
    ["SLECT", "Orange (SL) Limited"],
    ["SLEQC", "QCell (SL) Ltd."],
    ["SLVDC", "Digicel, S.A. de C.V."],
    ["SLVTM", "Telemovil El Salvador, S.A."],
    ["SLVTP", "CTE Telecom Personal, S.A. de C.V."],
    ["SLVTS", "Telefónica Móviles El Salvador, S.A de C.V."],
    ["SMOSM", "San Marino Telecom S.p.A."],
    ["SOM01", "Telesom Company"],
    ["SOMGT", "Golis Telecommunications Company Ltd"],
    ["SOMHI", "Hormuud Telecommunication Incorporation"],
    ["SOMHI", "Hormuud Telecom Inc."],
    ["SOMNL", "Nationlink"],
    ["SOMSF", "Somafone"],
    ["SOMST", "Somtel International Ltd"],
    ["SOMST", "SOMTEL"],
    ["SPM01", "SPM Telecom"],
    ["SRBNO", "Vip mobile d.o.o."],
    ["SSDGM", "Gemtel Ltd"],
    ["SSDMN", "MTN South Sudan"],
    ["SSDVC", "Network of the World Ltd"],
    ["SSDZS", "Sudanese Mobile Telephone (Zain South Sudan) Co.Ltd"],
    ["STPST", "Companhia Santomense de Telecomunicações, SARL"],
    ["STPUT", "Unitel STP S.a.r.l."],
    ["SUDMO", "Sudanese Mobile Telephone (Zain) Co. Ltd"],
    ["SURDC", "Digicel Suriname N.V."],
    ["SURTG", "Telecommunications Company Suriname"],
    ["SVKET", "Slovak Telekom, a.s."],
    ["SVKGT", "Orange Slovensko, a.s."],
    ["SVKO2", "O2 Slovakia, s.r.o."],
    ["SVKSW", "SWAN Mobile, a.s."],
    ["SVNMT", "Telekom Slovenije d.d."],
    ["SVNSM", "A1 Slovenija, d.d."],
    ["SVNT2", "T-2 d.o.o."],
    ["SVNVG", "Telemach d.o.o."],
    ["SWE46", "ViaHub Corp. Sweden"],
    ["SWEBL", "Borderlight Mobile Networks AB"],
    ["SWEBP", "Twilio Sweden"],
    ["SWEC4", "Com4 Sweden AB"],
    ["SWEEP", "Telenor Sverige AB"],
    ["SWEHU", "Hi3G Access AB"],
    ["SWEIB", "Infobip / Compatel"],
    ["SWEIQ", "Tele2 AB"],
    ["SWEIT", "42 Telecom Limited"],
    ["SWEMI", "Globetouch Inc"],
    ["SWEMI", "Globetouch, Inc."],
    ["SWEMW", "Mobiweb Telecom Sweden"],
    ["SWENN", "ReWiCom Scandinavia AB"],
    ["SWESM", "Tele2 AB"],
    ["SWETD", "TDC A/S"],
    ["SWETG", "Telenor Sverige AB"],
    ["SWETR", "Telia Company AB"],
    ["SWZ02", "Swazi Mobile Limited"],
    ["SWZMN", "Swazi MTN Limited"],
    ["SYCAT", "Airtel (Seychelles) Limited"],
    ["SYCCW", "Cable and Wireless (Seychelles) Ltd"],
    ["SYR01", "Syriatel Mobile Telecom SA"],
    ["SYRSP", "MTN Syria (JSC)"],
    ["TCACW", "Cable and Wireless (TCI) Ltd"],
    ["TCAIC", "Islandcom Telecommunications"],
    ["TCDCT", "Airtel Tchad"],
    ["TCDML", "Millicom Tchad SA"],
    ["TELA1", "Telecom26 AG"],
    ["TGOTC", "Togo Telecom"],
    ["TGOTL", "Telecel Togo"],
    ["THAAS", "Advanced Info Service PLC"],
    ["THACA", "CAT Telecom Public Company"],
    ["THACA", "True Move H Universal Communication Co., Ltd."],
    ["THACO", "True Move H Universal Communication Co.| Ltd."],
    ["THADT", "dtac TriNet Co., Ltd."],
    ["THATC", "TOT PLC"],
    ["THAWN", "Advanced Wireless Network Company Limited"],
    ["THAWP", "Total Access Communications PLC."],
    ["TJK91", "Tacom LLC"],
    ["TJKBM", "CJSC Babilon-Mobile"],
    ["TJKIT", "Indigo Tajikistan CJSC"],
    ["TJKTT", "TT Mobile"],
    ["TKM02", "Altyn Asyr  (TM CELL)"],
    ["TKMBC", "Economy Society MTS-Turkmenistan"],
    ["TLSTC", "PT Telekomunikasi Indonesia International"],
    ["TLSTL", "Timor Telecom"],
    ["TLSVG", "Viettel Timor Leste Unipessoal Lda"],
    ["TON01", "Tonga Communications Corporation"],
    ["TONDP", "Digicel (Tonga) Limited"],
    ["TTO12", "Telecommunications Services of Trinidad and Tobago Ltd"],
    ["TTODL", "Digicel Trinidad and Tobago Ltd"],
    ["TUNOR", "Orange Tunisie, SA"],
    ["TUNTA", "Ooredoo Tunisie SA"],
    ["TUNTT", "Tunisie Telecom"],
    ["TURGS", "Globalstar Avrasya (1) & (2)"],
    ["TURIS", "Avea Iletisim Hizmetleri A.S."],
    ["TURTC", "Turkcell Iletisim Hizmetleri A.S."],
    ["TURTS", "Vodafone Telekomunikasyon A.S"],
    ["TWNFE", "Far EasTone Telecommunications Co Ltd"],
    ["TWNGT", "Asia Pacific Telecom Co.| Ltd. (APT)"],
    ["TWNGT", "Asia Pacific Telecom Co., Ltd. (APT)"],
    ["TWNKG", "Far EasTone Telecommunications Co Ltd"],
    ["TWNLD", "Chunghwa Telecom"],
    ["TWNPC", "Taiwan Mobile Co. Ltd."],
    ["TWNPC", "Taiwan Mobile Co.Ltd"],
    ["TWNTG", "Taiwan Star Telecom Corporation Limited"],
    ["TYNTT", "TynTec"],
    ["TZACT", "Airtel Tanzania Limited"],
    ["TZAMB", "MIC Tanzania Limited"],
    ["TZAVC", "Vodacom Tanzania Limited"],
    ["TZAVG", "Viettel Tanzania Limited"],
    ["TZAYA", "Benson Informatics Limited"],
    ["TZAYA", "Smart"],
    ["TZAZN", "Zanzibar Telecom Public Limited Company"],
    ["UGACE", "Airtel Uganda Ltd."],
    ["UGACE", "Airtel Uganda Limited"],
    ["UGAMN", "MTN Uganda Ltd"],
    ["UGAOR", "Africell Uganda Limited"],
    ["UGASU", "Suretelcom Uganda Limited"],
    ["UGATL", "Uganda Telecom Ltd"],
    ["UKRAS", "lifecell LLC"],
    ["UKRGT", "Golden Telecom"],
    ["UKRKS", "Kyivstar, Private Joint Stock Company"],
    ["UKRUM", "PrJSC VF Ukraine"],
    ["UKRUT", "TriMob LLC"],
    ["URYAM", "AM Wireless Uruguay S.A."],
    ["URYAN", "Antel"],
    ["URYTM", "Telefónica Móviles Del Uruguay S.A."],
    ["USA01", "Pine Belt Cellular, Inc., DBA Pine Belt Wireless"],
    ["USA1E", "Carolina West Wireless, Inc"],
    ["USA27", "T-Mobile USA, Inc"],
    ["USA34", "Illinois Valley Cellular"],
    ["USA55", "NetGenuity, Inc"],
    ["USA6G", "Nex-Tech Wireless LLC"],
    ["USAAC", "Advantage Cellular Systems Inc"],
    ["USAAE", "Airadigm Communications"],
    ["USAAP", "Aspenta, LLC"],
    ["USAAS", "Arctic Slope Telephone Association Cooperative"],
    ["USAAW", "The Alaska Wireless Network| LLC"],
    ["USABG", "Bluegrass Wireless LLC"],
    ["USABI", "TNS Inc. (former VeriSign)"],
    ["USABT", "Michigan Wireless, LLC"],
    ["USAC2", "Nsight Spectrum, LLC"],
    ["USACA", "Cordova Wireless Communications LLC"],
    ["USACB", "Cincinnati Bell Wireless"],
    ["USACC", "AT&T Mobility"],
    ["USACG", "AT&T Mobility"],
    ["USACH", "MTPCS, LLC"],
    ["USACI", "NE Colorado Cellular, Inc"],
    ["USACL", "SafeSIM Test Network"],
    ["USACM", "C Spire"],
    ["USACO", "Commnet Wireless, LLC"],
    ["USACP", "AT&T Mobility"],
    ["USACS", "The Alaska Wireless Network| LLC"],
    ["USACV", "Missouri RSA No 5 Partnership"],
    ["USACW", "Limitless Mobile, LLC"],
    ["USAET", "Epic Touch Co"],
    ["USAEZ", "Evolve Cellular Inc."],
    ["USAFN", "AT&T Mobility"],
    ["USAGC", "The Alaska Wireless Network, LLC"],
    ["USAGF", "New Dimension Wireless (GreenFly)"],
    ["USAGL", "Globecomm Network Services Corp."],
    ["USAGM", "Globalstar"],
    ["USAGU", "PTI Pacifica or IT&E"],
    ["USAGV", "Commnet Wireless, LLC"],
    ["USAGW", "Great North woods Wireless"],
    ["USAIN", "Indigo Wireless, Inc"],
    ["USAIW", "Iowa Wireless Services, LLC"],
    ["USAJS", "Jasper Technologies, inc"],
    ["USAJV", "James Valley Wireless, LLC"],
    ["USAKD", "Choice Wireless LC"],
    ["USAKW", "Ribbon Communications"],
    ["USAKY", "Appalachian Wireless"],
    ["USALS", "Limitless Mobile, LLC"],
    ["USALT", "Limitless Mobile, LLC"],
    ["USALW", "Limitless Mobile, LLC"],
    ["USAM5", "Missouri RSA No 5 Partnership"],
    ["USAMF", "AT&T Mobility"],
    ["USAMH", "Midwest Network Solutions Hub, LLC."],
    ["USAMI", "iSmart Mobile, LLC"],
    ["USANE", "Convey Communications"],
    ["USANF", "Northeast Wireless Networks, LLC"],
    ["USANT", "Sagebrush Cellular, Inc."],
    ["USANW", "NewCore Wireless, LLC"],
    ["USAOP", "OptimERA Inc."],
    ["USAOT", "OTZ Telecommunications Inc."],
    ["USAOW", "Oklahoma Western Telephone Company"],
    ["USAPC", "Pine Telephone Company"],
    ["USAPE", "Tampnet AS"],
    ["USAPI", "Cellular Network Partnership, An Oklahoma Limited Partnership"],
    ["USAPJ", "Proximiti Mobility, Inc"],
    ["USAPR", "Proximiti Mobility, Inc"],
    ["USAPU", "Teleguam Holdings, LLC"],
    ["USARB", "Syniverse Technologies, Inc"],
    ["USARB", "Manx North American & Maritime Extension Service"],
    ["USASB", "Smith Bagley, Inc."],
    ["USASC", "T-Mobile USA, Inc"],
    ["USASK", "Cross Wireless"],
    [
      "USASN",
      "Uintah Basin Electronic Telecommunications, L.L.C. (Strata Networks)",
    ],
    ["USASP", "Sprint Corporation"],
    [
      "USASS",
      "Uintah Basin Electronic Telecommunications, L.L.C. (Strata Networks)",
    ],
    ["USAST", "T-Mobile USA| Inc"],
    ["USASV", "Sprint Corporation"],
    ["USAT1", "TX-11 Acquisition LLC"],
    ["USATA", "TelAlaska Cellular, Inc."],
    ["USATG", "TGS, LLC"],
    ["USATL", "Telecom North America Mobile Inc."],
    ["USATN", "DISH Network Corporation"],
    ["USAU1", "Corr Wireless Communications"],
    ["USAUC", "United States Cellular Corporation"],
    ["USAUD", "United States Cellular Corporation"],
    ["USAUE", "United States Cellular Corporation"],
    ["USAUL", "Missouri RSA No 5 Partnership"],
    ["USAUN", "Union Telephone Company"],
    ["USAUW", "United Wireless Communications, Inc."],
    ["USAVC", "Vanu Coverage Co"],
    ["USAVI", "Vitelcom Cellular, Inc."],
    ["USAVM", "Verizon Wireless"],
    ["USAVT", "Vermont Telephone Company, Inc"],
    ["USAVZ", "Verizon Wireless"],
    ["USAW0", "T-Mobile USA, Inc"],
    ["USAW4", "T-Mobile USA, Inc"],
    ["USAW5", "T-Mobile USA, Inc"],
    ["USAW6", "T-Mobile USA, Inc"],
    ["USAW6", "T-Mobile"],
    ["USAWC", "C T Cube, LP"],
    ["USAWW", "Verizon Wireless 2G (Alltel)"],
    ["USAXC", "Inland Cellular, LLC"],
    ["USAXC", "Inland Cellular| LLC"],
    ["UZB00", "Uzbektelecom Joint-Stock Company"],
    ["UZB05", "FE Coscom LLC"],
    ["UZB07", "Universal Mobile Systems, LLC"],
    ["UZBDU", "Unitel LLC"],
    ["VCTCW", "Cable & Wireless (St. Vincent & the Grenadines) Ltd"],
    ["VEND2", "Corporacion Digitel C.A."],
    ["VENGS", "TE.SA.M de Venezuela (Globalstar)"],
    ["VENMS", "Telefónica Venezolana, C.A."],
    ["VENMV", "Telecomunicaciones Movilnet C.A."],
    ["VGBCC", "Caribbean Cellular Telephone"],
    ["VGBCW", "Cable and Wireless (BVI) Ltd"],
    ["VGBDC", "Digicel (BVI) Limited"],
    ["VIRGS", "GygSky Mobile"],
    ["VNMBL", "GTEL Mobile Joint Stock Company (GTEL Mobile)"],
    ["VNMMO", "MOBIFONE CORPORATION"],
    ["VNMVI", "Vietnam Posts and Telecommunications Group (VNPT)"],
    ["VNMVM", "Vietnamobile Telecommunications Joint Stock Company"],
    ["VNMVT", "Viettel Group"],
    ["VUT01", "Telecom Vanuatu Limited"],
    ["VUTDP", "Digicel (Vanuatu) Ltd"],
    ["WLF02", "SPT"],
    ["WSMDP", "Digicel (Samoa) Limited"],
    ["WSMGS", "Bluesky Samoa Limited"],
    ["XATT2", "Tele2 Telecommunications GmbH (Tele2)"],
    ["XGBMC", "Mars Communications Limited"],
    ["XPMDT", "Dauphin Télécom"],
    ["YEMSA", "Yemen Mobile Phone Company - Sabafon"],
    ["YEMSP", "MTN Yemen"],
    ["YEMYY", "Y-Telecom"],
    ["YUGMT", "Telenor d.o.o."],
    ["YUGPM", "TELENOR GSM"],
    ["YUGTS", "Telekom Srbija a.d."],
    ["ZAF38", "Rain Networks PTY Ltd."],
    ["ZAFCC", "Cell C (Pty) Ltd"],
    ["ZAFMN", "Mobile Telephone Networks (Pty) Ltd."],
    ["ZAFTM", "Telkom SA Ltd"],
    ["ZAFVC", "Vodacom (Pty) Ltd."],
    ["ZMB02", "MTN (Zambia) Ltd"],
    ["ZMBCE", "Airtel Networks Zambia Plc."],
    ["ZMBCZ", "Zambia Telecommunications Company Ltd (ZAMTEL)"],
    ["ZWEET", "Econet Wireless (Private) Limited"],
    ["ZWEN1", "Net*One Cellular (Pvt) Ltd"],
    ["ZWEN3", "Telecel Zimbabwe (PVT) Ltd"],
  ]);
  export const CountryCodes = new Map<string, string>([
    ["AFG", "Afghanistan"],
    ["ALB", "Albania"],
    ["DZA", "Algeria"],
    ["ASM", "American Samoa"],
    ["AND", "Andorra"],
    ["AGO", "Angola"],
    ["AIA", "Anguilla"],
    ["ATA", "Antarctica"],
    ["ATG", "Antigua & Barbuda"],
    ["ARG", "Argentina"],
    ["ARM", "Armenia"],
    ["ABW", "Aruba"],
    ["ASC", "Ascension Island"],
    ["AUS", "Australia"],
    ["AUT", "Austria"],
    ["AZE", "Azerbaijan"],
    ["BHS", "Bahamas"],
    ["BHR", "Bahrain"],
    ["BGD", "Bangladesh"],
    ["BRB", "Barbados"],
    ["BLR", "Belarus"],
    ["BEL", "Belgium"],
    ["BLZ", "Belize"],
    ["BEN", "Benin"],
    ["BMU", "Bermuda"],
    ["BTN", "Bhutan"],
    ["BOL", "Bolivia"],
    ["BIH", "Bosnia"],
    ["BWA", "Botswana"],
    ["BVT", "Bouvet Island"],
    ["BRA", "Brazil"],
    ["IOT", "British Indian Ocean Territory"],
    ["VGB", "British Virgin Islands"],
    ["BRN", "Brunei"],
    ["BGR", "Bulgaria"],
    ["BFA", "Burkina Faso"],
    ["BDI", "Burundi"],
    ["KHM", "Cambodia"],
    ["CMR", "Cameroon"],
    ["CAN", "Canada"],
    ["CPV", "Cape Verde"],
    ["BES", "Caribbean Netherlands"],
    ["CYM", "Cayman Islands"],
    ["CAF", "Central African Republic"],
    ["TCD", "Chad"],
    ["CHL", "Chile"],
    ["CHN", "China"],
    ["CXR", "Christmas Island"],
    ["CCK", "Cocos (Keeling) Islands"],
    ["COL", "Colombia"],
    ["COM", "Comoros"],
    ["COG", "Congo - Brazzaville"],
    ["COD", "Congo - Kinshasa"],
    ["COK", "Cook Islands"],
    ["CRI", "Costa Rica"],
    ["HRV", "Croatia"],
    ["CUB", "Cuba"],
    ["CUW", "Cura\u00e7ao"],
    ["CYP", "Cyprus"],
    ["CZE", "Czechia"],
    ["CIV", "C\u00f4te d\u2019Ivoire"],
    ["DNK", "Denmark"],
    ["DGA", "Diego-Garcia"],
    ["DJI", "Djibouti"],
    ["DMA", "Dominica"],
    ["DOM", "Dominican Republic"],
    ["ECU", "Ecuador"],
    ["EGY", "Egypt"],
    ["SLV", "El Salvador"],
    ["GNQ", "Equatorial Guinea"],
    ["ERI", "Eritrea"],
    ["EST", "Estonia"],
    ["ETH", "Ethiopia"],
    ["FLK", "Falkland Islands"],
    ["FRO", "Faroe Islands"],
    ["FJI", "Fiji"],
    ["FIN", "Finland"],
    ["YUG", "Former Yogoslavia"],
    ["FRA", "France"],
    ["GUF", "French Guiana"],
    ["PYF", "French Polynesia"],
    ["ATF", "French Southern Territories"],
    ["GAB", "Gabon"],
    ["GMB", "Gambia"],
    ["GEO", "Georgia"],
    ["DEU", "Germany"],
    ["GHA", "Ghana"],
    ["GIB", "Gibraltar"],
    ["GRC", "Greece"],
    ["GRL", "Greenland"],
    ["GRD", "Grenada"],
    ["GLP", "Guadeloupe"],
    ["GUM", "Guam"],
    ["GTM", "Guatemala"],
    ["GGY", "Guernsey"],
    ["GIN", "Guinea"],
    ["GNB", "Guinea-Bissau"],
    ["GUY", "Guyana"],
    ["HTI", "Haiti"],
    ["HMD", "Heard & McDonald Islands"],
    ["HND", "Honduras"],
    ["HKG", "Hong Kong"],
    ["HUN", "Hungary"],
    ["ISL", "Iceland"],
    ["IND", "India"],
    ["IDN", "Indonesia"],
    ["IRN", "Iran"],
    ["IRQ", "Iraq"],
    ["IRL", "Ireland"],
    ["IMN", "Isle of Man"],
    ["ISR", "Israel"],
    ["ITA", "Italy"],
    ["JAM", "Jamaica"],
    ["JPN", "Japan"],
    ["JEY", "Jersey"],
    ["JOR", "Jordan"],
    ["KAZ", "Kazakhstan"],
    ["KEN", "Kenya"],
    ["KIR", "Kiribati"],
    ["XKX", "Kosovo"],
    ["KWT", "Kuwait"],
    ["KGZ", "Kyrgyzstan"],
    ["LAO", "Laos"],
    ["LVA", "Latvia"],
    ["LBN", "Lebanon"],
    ["LSO", "Lesotho"],
    ["LBR", "Liberia"],
    ["LBY", "Libya"],
    ["LIE", "Liechtenstein"],
    ["LTU", "Lithuania"],
    ["LUX", "Luxembourg"],
    ["MAC", "Macau"],
    ["MKD", "Macedonia"],
    ["MDG", "Madagascar"],
    ["MWI", "Malawi"],
    ["MYS", "Malaysia"],
    ["MDV", "Maldives"],
    ["MLI", "Mali"],
    ["MLT", "Malta"],
    ["MHL", "Marshall Islands"],
    ["MTQ", "Martinique"],
    ["MRT", "Mauritania"],
    ["MUS", "Mauritius"],
    ["MYT", "Mayotte"],
    ["MEX", "Mexico"],
    ["FSM", "Micronesia"],
    ["MDA", "Moldova"],
    ["MCO", "Monaco"],
    ["MNG", "Mongolia"],
    ["MNE", "Montenegro"],
    ["MSR", "Montserrat"],
    ["MAR", "Morocco"],
    ["MOZ", "Mozambique"],
    ["MMR", "Myanmar"],
    ["NAM", "Namibia"],
    ["NRU", "Nauru"],
    ["NPL", "Nepal"],
    ["NLD", "Netherlands"],
    ["ANT", "Netherlands Antilles"],
    ["NCL", "New Caledonia"],
    ["NZL", "New Zealand"],
    ["NIC", "Nicaragua"],
    ["NER", "Niger"],
    ["NGA", "Nigeria"],
    ["NIU", "Niue"],
    ["NFK", "Norfolk Island"],
    ["PRK", "North Korea"],
    ["MNP", "Northern Mariana Islands"],
    ["NOR", "Norway"],
    ["OMN", "Oman"],
    ["PAK", "Pakistan"],
    ["PLW", "Palau"],
    ["PSE", "Palestine"],
    ["PAN", "Panama"],
    ["PNG", "Papua New Guinea"],
    ["PRY", "Paraguay"],
    ["PER", "Peru"],
    ["PHL", "Philippines"],
    ["PCN", "Pitcairn Islands"],
    ["POL", "Poland"],
    ["PRT", "Portugal"],
    ["PRI", "Puerto Rico"],
    ["QAT", "Qatar"],
    ["ROU", "Romania"],
    ["RUS", "Russia"],
    ["RWA", "Rwanda"],
    ["REU", "R\u00e9union"],
    ["WSM", "Samoa"],
    ["SMR", "San Marino"],
    ["SAU", "Saudi Arabia"],
    ["SEN", "Senegal"],
    ["SRB", "Serbia"],
    ["SYC", "Seychelles"],
    ["SLE", "Sierra Leone"],
    ["SGP", "Singapore"],
    ["SXM", "Sint Maarten"],
    ["SVK", "Slovakia"],
    ["SVN", "Slovenia"],
    ["SLB", "Solomon Islands"],
    ["SOM", "Somalia"],
    ["ZAF", "South Africa"],
    ["SGS", "South Georgia & South Sandwich Islands"],
    ["KOR", "South Korea"],
    ["SSD", "South Sudan"],
    ["ESP", "Spain"],
    ["LKA", "Sri Lanka"],
    ["BLM", "St. Barth\u00e9lemy"],
    ["SHN", "St. Helena"],
    ["KNA", "St. Kitts & Nevis"],
    ["LCA", "St. Lucia"],
    ["MAF", "St. Martin"],
    ["SPM", "St. Pierre & Miquelon"],
    ["VCT", "St. Vincent & Grenadines"],
    ["SDN", "Sudan"],
    ["SUR", "Suriname"],
    ["SJM", "Svalbard & Jan Mayen"],
    ["SWZ", "Swaziland"],
    ["SWE", "Sweden"],
    ["CHE", "Switzerland"],
    ["SYR", "Syria"],
    ["STP", "S\u00e3o Tom\u00e9 & Pr\u00edncipe"],
    ["TWN", "Taiwan"],
    ["TJK", "Tajikistan"],
    ["TZA", "Tanzania"],
    ["THA", "Thailand"],
    ["TLS", "Timor-Leste"],
    ["TGO", "Togo"],
    ["TKL", "Tokelau"],
    ["TON", "Tonga"],
    ["TTO", "Trinidad & Tobago"],
    ["TUN", "Tunisia"],
    ["TUR", "Turkey"],
    ["TKM", "Turkmenistan"],
    ["TCA", "Turks & Caicos Islands"],
    ["TUV", "Tuvalu"],
    ["UMI", "U.S. Outlying Islands"],
    ["VIR", "U.S. Virgin Islands"],
    ["GBR", "UK"],
    ["USA", "US"],
    ["UGA", "Uganda"],
    ["UKR", "Ukraine"],
    ["ARE", "United Arab Emirates"],
    ["URY", "Uruguay"],
    ["UZB", "Uzbekistan"],
    ["VUT", "Vanuatu"],
    ["VAT", "Vatican City"],
    ["VEN", "Venezuela"],
    ["VNM", "Vietnam"],
    ["WLF", "Wallis & Futuna"],
    ["ESH", "Western Sahara"],
    ["YEM", "Yemen"],
    ["ZMB", "Zambia"],
    ["ZWE", "Zimbabwe"],
    ["ALA", "\u00c5land Islands"],
    ["AAQ", "Satellite"],
    ["AAA", "Aeronautical"],
    ["AAM", "Maritime"],
    ["AAZ", "Non-geographical M2M"],
  ]);
  export const isoAlpha3toAlpha2 = new Map<string, string>([
    ["AND", "AD"],
    ["ARE", "AE"],
    ["AFG", "AF"],
    ["ATG", "AG"],
    ["AIA", "AI"],
    ["ALB", "AL"],
    ["ARM", "AM"],
    ["AGO", "AO"],
    ["ATA", "AQ"],
    ["ARG", "AR"],
    ["ASM", "AS"],
    ["AUT", "AT"],
    ["AUS", "AU"],
    ["ABW", "AW"],
    ["ALA", "AX"],
    ["AZE", "AZ"],
    ["BIH", "BA"],
    ["BRB", "BB"],
    ["BGD", "BD"],
    ["BEL", "BE"],
    ["BFA", "BF"],
    ["BGR", "BG"],
    ["BHR", "BH"],
    ["BDI", "BI"],
    ["BEN", "BJ"],
    ["BLM", "BL"],
    ["BMU", "BM"],
    ["BRN", "BN"],
    ["BOL", "BO"],
    ["BES", "BQ"],
    ["BRA", "BR"],
    ["BHS", "BS"],
    ["BTN", "BT"],
    ["BVT", "BV"],
    ["BWA", "BW"],
    ["BLR", "BY"],
    ["BLZ", "BZ"],
    ["CAN", "CA"],
    ["CCK", "CC"],
    ["COD", "CD"],
    ["CAF", "CF"],
    ["COG", "CG"],
    ["CHE", "CH"],
    ["CIV", "CI"],
    ["COK", "CK"],
    ["CHL", "CL"],
    ["CMR", "CM"],
    ["CHN", "CN"],
    ["COL", "CO"],
    ["CRI", "CR"],
    ["CUB", "CU"],
    ["CPV", "CV"],
    ["CUW", "CW"],
    ["CXR", "CX"],
    ["CYP", "CY"],
    ["CZE", "CZ"],
    ["DEU", "DE"],
    ["DJI", "DJ"],
    ["DNK", "DK"],
    ["DMA", "DM"],
    ["DOM", "DO"],
    ["DZA", "DZ"],
    ["ECU", "EC"],
    ["EST", "EE"],
    ["EGY", "EG"],
    ["ESH", "EH"],
    ["ERI", "ER"],
    ["ESP", "ES"],
    ["ETH", "ET"],
    ["FIN", "FI"],
    ["FJI", "FJ"],
    ["FLK", "FK"],
    ["FSM", "FM"],
    ["FRO", "FO"],
    ["FRA", "FR"],
    ["GAB", "GA"],
    ["GBR", "GB"],
    ["GRD", "GD"],
    ["GEO", "GE"],
    ["GUF", "GF"],
    ["GGY", "GG"],
    ["GHA", "GH"],
    ["GIB", "GI"],
    ["GRL", "GL"],
    ["GMB", "GM"],
    ["GIN", "GN"],
    ["GLP", "GP"],
    ["GNQ", "GQ"],
    ["GRC", "GR"],
    ["SGS", "GS"],
    ["GTM", "GT"],
    ["GUM", "GU"],
    ["GNB", "GW"],
    ["GUY", "GY"],
    ["HKG", "HK"],
    ["HMD", "HM"],
    ["HND", "HN"],
    ["HRV", "HR"],
    ["HTI", "HT"],
    ["HUN", "HU"],
    ["IDN", "ID"],
    ["IRL", "IE"],
    ["ISR", "IL"],
    ["IMN", "IM"],
    ["IND", "IN"],
    ["IOT", "IO"],
    ["IRQ", "IQ"],
    ["IRN", "IR"],
    ["ISL", "IS"],
    ["ITA", "IT"],
    ["JEY", "JE"],
    ["JAM", "JM"],
    ["JOR", "JO"],
    ["JPN", "JP"],
    ["KEN", "KE"],
    ["KGZ", "KG"],
    ["KHM", "KH"],
    ["KIR", "KI"],
    ["COM", "KM"],
    ["KNA", "KN"],
    ["PRK", "KP"],
    ["KOR", "KR"],
    ["KWT", "KW"],
    ["CYM", "KY"],
    ["KAZ", "KZ"],
    ["LAO", "LA"],
    ["LBN", "LB"],
    ["LCA", "LC"],
    ["LIE", "LI"],
    ["LKA", "LK"],
    ["LBR", "LR"],
    ["LSO", "LS"],
    ["LTU", "LT"],
    ["LUX", "LU"],
    ["LVA", "LV"],
    ["LBY", "LY"],
    ["MAR", "MA"],
    ["MCO", "MC"],
    ["MDA", "MD"],
    ["MNE", "ME"],
    ["MAF", "MF"],
    ["MDG", "MG"],
    ["MHL", "MH"],
    ["MKD", "MK"],
    ["MLI", "ML"],
    ["MMR", "MM"],
    ["MNG", "MN"],
    ["MAC", "MO"],
    ["MNP", "MP"],
    ["MTQ", "MQ"],
    ["MRT", "MR"],
    ["MSR", "MS"],
    ["MLT", "MT"],
    ["MUS", "MU"],
    ["MDV", "MV"],
    ["MWI", "MW"],
    ["MEX", "MX"],
    ["MYS", "MY"],
    ["MOZ", "MZ"],
    ["NAM", "NA"],
    ["NCL", "NC"],
    ["NER", "NE"],
    ["NFK", "NF"],
    ["NGA", "NG"],
    ["NIC", "NI"],
    ["NLD", "NL"],
    ["NOR", "NO"],
    ["NPL", "NP"],
    ["NRU", "NR"],
    ["NIU", "NU"],
    ["NZL", "NZ"],
    ["OMN", "OM"],
    ["PAN", "PA"],
    ["PER", "PE"],
    ["PYF", "PF"],
    ["PNG", "PG"],
    ["PHL", "PH"],
    ["PAK", "PK"],
    ["POL", "PL"],
    ["SPM", "PM"],
    ["PCN", "PN"],
    ["PRI", "PR"],
    ["PSE", "PS"],
    ["PRT", "PT"],
    ["PLW", "PW"],
    ["PRY", "PY"],
    ["QAT", "QA"],
    ["REU", "RE"],
    ["ROU", "RO"],
    ["SRB", "RS"],
    ["RUS", "RU"],
    ["RWA", "RW"],
    ["SAU", "SA"],
    ["SLB", "SB"],
    ["SYC", "SC"],
    ["SDN", "SD"],
    ["SWE", "SE"],
    ["SGP", "SG"],
    ["SHN", "SH"],
    ["SVN", "SI"],
    ["SJM", "SJ"],
    ["SVK", "SK"],
    ["SLE", "SL"],
    ["SMR", "SM"],
    ["SEN", "SN"],
    ["SOM", "SO"],
    ["SUR", "SR"],
    ["SSD", "SS"],
    ["STP", "ST"],
    ["SLV", "SV"],
    ["SXM", "SX"],
    ["SYR", "SY"],
    ["SWZ", "SZ"],
    ["TCA", "TC"],
    ["TCD", "TD"],
    ["ATF", "TF"],
    ["TGO", "TG"],
    ["THA", "TH"],
    ["TJK", "TJ"],
    ["TKL", "TK"],
    ["TLS", "TL"],
    ["TKM", "TM"],
    ["TUN", "TN"],
    ["TON", "TO"],
    ["TUR", "TR"],
    ["TTO", "TT"],
    ["TUV", "TV"],
    ["TWN", "TW"],
    ["TZA", "TZ"],
    ["UKR", "UA"],
    ["UGA", "UG"],
    ["UMI", "UM"],
    ["USA", "US"],
    ["URY", "UY"],
    ["UZB", "UZ"],
    ["VAT", "VA"],
    ["VCT", "VC"],
    ["VEN", "VE"],
    ["VGB", "VG"],
    ["VIR", "VI"],
    ["VNM", "VN"],
    ["VUT", "VU"],
    ["WLF", "WF"],
    ["WSM", "WS"],
    ["YEM", "YE"],
    ["MYT", "YT"],
    ["XKX", "XK"],
    ["ZAF", "ZA"],
    ["ZMB", "ZM"],
    ["ZWE", "ZW"],
  ]);