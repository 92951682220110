import React from "react";
import { Line } from "react-chartjs-2";
import { LineChartProps } from "../../shared/types";

export const LineChartComponent: React.FC<LineChartProps> = (props) => {
  const { label, labels, backgroundColor, borderColor, data, options } = props;

  // set the options default here
  options.plugins.legend.display = false;
  options.borderWidth = 2;
  options.animation = false;

  const generateChartData = () => {
    return {
      labels: labels,
      datasets: [
        {
          label: label,
          data: data,
          fill: false,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
        },
      ],
    };
  };
  return (
    <>
      <Line data={generateChartData} options={options} />
    </>
  );
};
