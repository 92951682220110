import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AuthRoutes, DateFormat } from "../../shared/enums";
import { Column, Table2, Cell, SelectionModes } from "@blueprintjs/table";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { ServiceStatusrMenu, QRCodeComponent } from "../../components/index";
import { MenuPositions } from "../../shared/enums";
import User from "../../utility/User";
import { initRefresh } from "../../api/authService";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./SimDetailsContainer.css";
import {
  UsernameAvatarComponent,
  LoadingIndicatorComponent,
  LineChartComponent,
  FilterContainer,
  FooterComponent,
  PaginationComponent,
  BrandingComponent,
} from "../../components";
import {
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  TabId,
  Icon,
  FormGroup,
  InputGroup,
  Dialog,
  NonIdealState,
  Toaster,
  Popover,
  Position,
  Tooltip,
  Intent,
} from "@blueprintjs/core";
import {
  getSubscriptionDetails,
  updateServiceStatus,
  getUsage,
  updateAlias,
  getQRCode,
} from "../../api/usageService";
import { getChartData } from "../../api/chartService";
import {
  SubsDetails,
  LineChartProps,
  Loader,
  Filters,
  AliasFormInput,
  RouteParams,
  ServiceStatusDialog,
  PaginationProps,
  BrandingProps,
  QRCodeProps,
} from "../../shared/types";
import {
  ServiceStatuses,
  CountryCodes,
  isoAlpha3toAlpha2,
  DEFAULT_ICON_SIZE,
  IN_OUT_CALLTYPE_ICONS_SIZE,
} from "../../shared/constants";
import {
  generateChartData,
  generateDataUsageFormat,
  generateTooltipLabel,
  generateDurationFormat,
  generatePrimaryMsisdn,
  generateCapitalizeString,
  generateServiceStatusIntent,
  generateServiceStatusIcon,
  generatePhoneNumberFormat,
  generatePageTitle,
} from "../../utility/utils";
import {
  ChartLabels,
  ChartColors,
  TableColumns,
  CallTypes,
  Messages,
  PageTitles,
} from "../../shared/enums";
import moment from "moment";
import { TableConfigs } from "./SIMDetailsTableConfigs";

const toast = Toaster.create({
  className: "top-toaster",
  position: Position.TOP,
});

interface Props {}

export const SimDetailsContainer: React.FC<Props> = () => {
  const { routeParamIccid } = useParams<RouteParams>();
  const user = User.getInstance();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUsageData, setLoadingUsageData] = useState(true);
  const [username, setUsername] = useState<string | null>(null);
  const [subsDetails, setSubsDetails] = useState<SubsDetails | any>({});
  const [usageDetailsData, setUsageDetailsData] = useState<any>({});
  const [alias, setAlias] = useState<string>("");
  const [savingAlias, setSavingAlias] = useState(false);
  const [chartData, setChartData] = useState<number[]>([]);
  const [chartDateRange, setChartDateRange] = useState<string[]>([]);
  const [editAliasMode, setEditAlisMode] = useState(true);
  const [selectedTab, setSelectedTab] = useState("data");
  const [isOpen, setIsOpen] = useState(false);
  const [hasEsim, setHasEsim] = useState<boolean>(false);
  const [QRCode, setQRCode] = useState<QRCodeProps>({
    activation_code: "",
    activation_url: "",
    confirmation_code: "",
    downloaded_at: "",
    last_notification_point_id: "",
    last_notification_result_data: "",
    last_notification_status: "",
    qr_code: "",
    smdp_address: "",
    iccid: ""
  });

  const LoadingProps: Loader = {
    text: "Loading SIMs Details...",
    className: "",
  };

  let accountId = useRef<string>("");
  let serviceStatus = useRef<number>();
  let serviceStatusTimerId: ReturnType<typeof setInterval>;

  const state: ServiceStatusDialog = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: false,
    enforceFocus: true,
    isOpen: isOpen,
    usePortal: true,
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<AliasFormInput>({
    mode: "onChange",
  });

  useEffect(() => {
    generatePageTitle(PageTitles.SIM_DETAILS);
    setLoading(true);
    setUsername(user.getUsername());
    getSubscriptionDetails(routeParamIccid)
      .then((response) => {
        if (response.data) {
          let [sub] = response.data.results;
          setSubsDetails(sub);
          setAlias(sub.alias);
          accountId.current = sub.account.id;
          let serviceStatus = sub.service_status;
          initiServiceStatusRequest(serviceStatus);
          fetchUsage();
          fetchQRCode();
        }
      })
      .catch((error) => {
        toast.show({
          intent: Intent.DANGER,
          message: `${error.description}`,
        });
        setLoading(false);
      });
    return function cleanup() {
      clearInterval(serviceStatusTimerId);
    };
  }, [routeParamIccid, user]);

  const fetchUsage = (callType?: string, page?: string, page_size?: string) => {
    setLoadingUsageData(true);
    let tabDataParseKey = "";

    if (callType === undefined) callType = selectedTab;

    getUsage({
      accountId: accountId.current,
      iccid: routeParamIccid,
      call_type: callType,
      page: page,
      page_size: page_size,
    })
      .then((response) => {
        setUsageDetailsData(response.data);

        if (callType === CallTypes.VOICE) {
          tabDataParseKey = "duration";
        }
        if (callType === CallTypes.SMS) {
          tabDataParseKey = "sms_events";
        }
        if (callType === CallTypes.DATA) {
          tabDataParseKey = "data_total";
        }

        fetchChartData(callType, tabDataParseKey);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchChartData = (
    call_type: string | undefined,
    tabDataParseKey: string
  ) => {
    getChartData({
      iccid: routeParamIccid,
      call_type: call_type,
    })
      .then((response) => {
        let chartData = generateChartData(response.data, tabDataParseKey);
        let chartDateRange = generateChartData(response.data, "date");

        setChartData(chartData);
        setChartDateRange(chartDateRange);
        initRefresh();
        setLoadingUsageData(false);
      })
      .catch((error) => {
        console.log(error, "Error..");
      });
  };

  const fetchServiceStatus = () => {
    if (serviceStatusTimerId) clearInterval(serviceStatusTimerId);
    getSubscriptionDetails(routeParamIccid)
      .then((response) => {
        if (response.data) {
          let [sub] = response.data.results;
          setSubsDetails(sub);
          let newStatus = sub.service_status;
          initiServiceStatusRequest(newStatus);
        }
      })
      .catch((error) => {
        toast.show({
          intent: Intent.DANGER,
          message: `${error.description}`,
        });
      });
  };

  const initiServiceStatusRequest = (updatedStatus: number) => {
    const pendingStatuses: number[] = [225, 235];
    if (!pendingStatuses.includes(updatedStatus)) {
      clearInterval(serviceStatusTimerId);
    } else {
      serviceStatusTimerId = setInterval(fetchServiceStatus, 15000);
    }
  };

  const handleRenderDialog = (status: number) => {
    serviceStatus.current = status;
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const handleUpdateServiceStatus = () => {
    const operation = serviceStatus.current === 200 ? "block" : "unblock";
    updateServiceStatus(accountId.current, subsDetails.internal_id, operation)
      .then((response) => {
        if (response.status === 200) {
          fetchServiceStatus();
          toast.show({
            intent: Intent.SUCCESS,
            message: "Service Status Updating...",
          });
          setIsOpen(false);
        }
      })
      .catch((response) => {
        toast.show({
          intent: Intent.DANGER,
          message: `${response}`,
        });
        setIsOpen(false);
      });
  };

  const StatusProps = {
    service_status: subsDetails.service_status,
    handleRenderDialog: handleRenderDialog,
  };

  const renderServiceStatusDialog = () => {
    const headerTxt =
      serviceStatus.current === 200 ? "Block SIM" : "Unblock SIM";
    const msg =
      serviceStatus.current === 200
        ? Messages.SERVICE_STATUS_BLOCK_MSG
        : Messages.SERVCE_STATUS_UNBLOCK_MSG;
    return (
      <Dialog
        icon="info-sign"
        onClose={handleClose}
        title={headerTxt}
        {...state}
      >
        <div className="bp3-dialog-body">
          <div className="dialog-msg">{msg}</div>
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button onClick={handleClose}>Dismiss</Button>
            <Button intent={Intent.SUCCESS} onClick={handleUpdateServiceStatus}>
              Yes, Procced
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderServiceStatusBtn = (): JSX.Element => {
    const pendingStatuses: number[] = [225, 235];
    return (
      <Button
        className={
          pendingStatuses.includes(subsDetails.service_status)
            ? "pulse pending-status"
            : ""
        }
        intent={generateServiceStatusIntent(subsDetails.service_status)}
        outlined={true}
        minimal={true}
        icon={generateServiceStatusIcon(subsDetails.service_status)}
        text={ServiceStatuses.get(subsDetails.service_status)}
      />
    );
  };

  const renderServiceStatus = (): JSX.Element => {
    const noOptionsStatuses: number[] = [100, 225, 235, 400];
    return noOptionsStatuses.includes(subsDetails.service_status) ? (
      <>{renderServiceStatusBtn()}</>
    ) : (
      <Popover2
        content={<ServiceStatusrMenu {...StatusProps} />}
        placement={MenuPositions.BOTTOM}
      >
        {renderServiceStatusBtn()}
      </Popover2>
    );
  };

  const handleBack = (): void => history.push(AuthRoutes.dashboard);

  const updateFilters = (filterData: Filters): void => {
    setTimeout(() => {
      fetchUsage(selectedTab);
    }, 100);
  };

  const handlePageChange = (page: any, page_size: any) => {
    fetchUsage(selectedTab, page, page_size);
  };

  const cellRendererMsisdn =
    (key: string) =>
    (rowIndex: number): JSX.Element =>
      (
        <Cell>
          {generatePhoneNumberFormat(usageDetailsData.results[rowIndex][key])}
        </Cell>
      );

  const cellRendererCountry =
    (key: string) =>
    (rowIndex: number): JSX.Element =>
      (
        <Cell>
          {renderCountryFlag(
            isoAlpha3toAlpha2.get(usageDetailsData.results[rowIndex][key])
          )}{" "}
          {usageDetailsData.results[rowIndex][key]}
        </Cell>
      );

  const cellRendererCallType =
    (key: string) =>
    (rowIndex: number): JSX.Element =>
      (
        <Cell>
          {renderCallTypeIcon(usageDetailsData.results[rowIndex][key])}
        </Cell>
      );

  const cellRendererDuration =
    (key: string) =>
    (rowIndex: number): JSX.Element =>
      (
        <Cell>
          {generateDurationFormat(usageDetailsData.results[rowIndex][key])}
        </Cell>
      );

  const cellRendererStartEndTime =
    (key: string) =>
    (rowIndex: number): JSX.Element =>
      (
        <Cell>
          {moment
            .parseZone(usageDetailsData.results[rowIndex][key])
            .format(DateFormat.START_END_TIME)}
        </Cell>
      );

  const cellRendererUsageTotal =
    (key: string) =>
    (rowIndex: number): JSX.Element =>
      (
        <Cell>
          {generateDataUsageFormat(usageDetailsData.results[rowIndex][key])}
        </Cell>
      );

  const cellRendererServingNetwork =
    (key: string, subKey: string) =>
    (rowIndex: number): JSX.Element =>
      usageDetailsData.results[rowIndex]["unreconciled"] === true ? (
        <Cell>
          <i>{usageDetailsData.results[rowIndex][key][subKey]}</i>
          <Popover position={Position.BOTTOM}>
            <Tooltip content="Volumes can change." position={Position.TOP}>
              <Icon
                className="info-icon"
                intent={Intent.NONE}
                size={DEFAULT_ICON_SIZE}
                icon="clean"
              ></Icon>
            </Tooltip>
          </Popover>
        </Cell>
      ) : (
        <Cell>{usageDetailsData.results[rowIndex][key][subKey]}</Cell>
      );

  const DataUsageTab = (): JSX.Element => {
    const options = {
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        tooltip: {
          callbacks: {
            label: generateTooltipLabel,
          },
        },
      },
      elements: {
        line: {
          tension: 0.31, // disables bezier curves
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            callback: function (value: number): number | string {
              return generateDataUsageFormat(value);
            },
          },
        },
      },
    };

    const UsageData: LineChartProps = {
      label: ChartLabels.TOTAL_DATA,
      backgroundColor: ChartColors.WATERMELON_BORDER_COLOR,
      borderColor: ChartColors.WATERMELON_BG_COLOR,
      labels: chartDateRange,
      data: chartData,
      options: options,
    };

    const LoadingProps: Loader = {
      text: "Loading Data Usage...",
      className: "",
    };

    const PaginationTotalUsageProps: PaginationProps = {
      page: usageDetailsData.page,
      page_size: usageDetailsData.page_size,
      total_pages: usageDetailsData.total_pages,
      total_results: usageDetailsData.total_results,
      handlePageChange: handlePageChange,
    };

    const TotalUsageTableProps = {
      selectionModes: SelectionModes.ALL,
      enableColumnResizing: TableConfigs.defaultSetting.enableColumnResizing,
      defaultRowHeight: TableConfigs.defaultSetting.defaultRowHeight,
      defaultColumnWidth: TableConfigs.defaultSetting.defaultColumnWidth,
      enableRowHeader: TableConfigs.defaultSetting.enableRowHeader,
      enableGhostCells: TableConfigs.defaultSetting.enableGhostCells,
      columnWidths: TableConfigs.columnWidth.data,
    };

    return loadingUsageData ? (
      <LoadingIndicatorComponent {...LoadingProps} />
    ) : usageDetailsData && usageDetailsData.total_data > 0 ? (
      <>
        <div className="total-usage-summary-header">
          <div className="usage-data-item-header">
            <span className="usage-total-header">
              {generateDataUsageFormat(
                usageDetailsData && usageDetailsData.total_data
              )}
            </span>
            <span className="usage-label-header">Total Data Usage</span>
          </div>
        </div>
        <LineChartComponent {...UsageData} />
        <div className="sim-usage-data-table-container">
          <Table2
            numRows={usageDetailsData.results.length}
            {...TotalUsageTableProps}
          >
            <Column
              name="Start Time"
              cellRenderer={cellRendererStartEndTime(TableColumns.START_TIME)}
            />
            <Column
              name="Duration"
              cellRenderer={cellRendererDuration(TableColumns.DURATION)}
            />
            <Column
              name="Number of Bytes"
              cellRenderer={cellRendererUsageTotal(TableColumns.DATA_TOTAL)}
            />
            <Column
              name="Serving Network"
              cellRenderer={cellRendererServingNetwork(
                "serving_network",
                "name"
              )}
            />
          </Table2>
          {<PaginationComponent {...PaginationTotalUsageProps} />}
        </div>
      </>
    ) : (
      <div className="no-data-state">
        <NonIdealState
          title="No Usage Data"
          description={Messages.NON_IDEAL_STATE}
        />
      </div>
    );
  };

  // place into own file
  const SMSUsageTab = (): JSX.Element => {
    const options = {
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        tooltip: {
          callbacks: {
            label: generateTooltipLabel,
          },
        },
      },
      elements: {
        line: {
          tension: 0.31, // disables bezier curves
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            // callback: function (value: number): number | string {
            //   return formatDuration(value);
            // },
          },
        },
      },
    };

    const SmsData: LineChartProps = {
      label: ChartLabels.TOTAL_SMS,
      backgroundColor: ChartColors.PICTON_BLUE_BG_COLOR,
      borderColor: ChartColors.PICTON_BLUE_BORDER_COLOR,
      labels: chartDateRange,
      data: chartData,
      options: options,
    };

    const LoadingProps: Loader = {
      text: "Loading SMS Data...",
      className: "",
    };

    const PaginationSMSProps: PaginationProps = {
      page: usageDetailsData.page,
      page_size: usageDetailsData.page_size,
      total_pages: usageDetailsData.total_pages,
      total_results: usageDetailsData.total_results,
      handlePageChange: handlePageChange,
    };

    const SMSTotalTableProps = {
      enableColumnResizing: TableConfigs.defaultSetting.enableColumnResizing,
      defaultRowHeight: TableConfigs.defaultSetting.defaultRowHeight,
      defaultColumnWidth: TableConfigs.defaultSetting.defaultColumnWidth,
      enableRowHeader: TableConfigs.defaultSetting.enableRowHeader,
      enableGhostCells: TableConfigs.defaultSetting.enableGhostCells,
      columnWidths: TableConfigs.columnWidth.sms,
    };

    return loadingUsageData ? (
      <LoadingIndicatorComponent {...LoadingProps} />
    ) : usageDetailsData && usageDetailsData.total_sms > 0 ? (
      <>
        <div className="total-usage-summary-header">
          <div className="usage-data-item-header">
            <span className="usage-total-header">
              {" "}
              {usageDetailsData && usageDetailsData.total_sms}
            </span>
            <span className="usage-label-header"> Total Messages</span>
          </div>
        </div>
        <LineChartComponent {...SmsData} />
        <div className="sim-usage-data-table-container">
          <Table2
            {...SMSTotalTableProps}
            numRows={usageDetailsData.results.length}
          >
            <Column
              name=""
              cellRenderer={cellRendererCallType(TableColumns.CALL_TYPE)}
            />
            <Column
              name="Start Time"
              cellRenderer={cellRendererStartEndTime(TableColumns.START_TIME)}
            />
            <Column
              name="B-Party Number"
              cellRenderer={cellRendererMsisdn(TableColumns.B_PARTY_NUMBER)}
            />
            <Column
              name="Country"
              cellRenderer={cellRendererCountry(TableColumns.COUNTRY)}
            />
          </Table2>
          {<PaginationComponent {...PaginationSMSProps} />}
        </div>
      </>
    ) : (
      <div className="no-data-state">
        <NonIdealState
          title="No Usage Data"
          description={Messages.NON_IDEAL_STATE}
        />
      </div>
    );
  };

  // place into own file
  const VoiceUsageTab = (): JSX.Element => {
    const options = {
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        tooltip: {
          callbacks: {
            label: generateTooltipLabel,
          },
        },
      },
      elements: {
        line: {
          tension: 0.31, // disables bezier curves
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            callback: function (value: number): number | string {
              if (ChartLabels.TOTAL_DATA) {
              }
              return generateDurationFormat(value);
            },
          },
        },
      },
    };

    const VoiceData: LineChartProps = {
      label: ChartLabels.TOTAL_VOICE,
      backgroundColor: ChartColors.JAGUAR_BG_COLOR,
      borderColor: ChartColors.JAGUAR_BORDER_COLOR,
      labels: chartDateRange,
      data: chartData,
      options: options,
    };

    const LoadingProps: Loader = {
      text: "Loading Voice Data...",
      className: "",
    };

    const PaginationVoiceProps: PaginationProps = {
      page: usageDetailsData.page,
      page_size: usageDetailsData.page_size,
      total_pages: usageDetailsData.total_pages,
      total_results: usageDetailsData.total_results,
      handlePageChange: handlePageChange,
    };

    const DurationTotalTableProps = {
      enableColumnResizing: TableConfigs.defaultSetting.enableColumnResizing,
      defaultRowHeight: TableConfigs.defaultSetting.defaultRowHeight,
      defaultColumnWidth: TableConfigs.defaultSetting.defaultColumnWidth,
      enableRowHeader: TableConfigs.defaultSetting.enableRowHeader,
      enableGhostCells: TableConfigs.defaultSetting.enableGhostCells,
      columnWidths: TableConfigs.columnWidth.voice,
    };

    return loadingUsageData ? (
      <LoadingIndicatorComponent {...LoadingProps} />
    ) : usageDetailsData && usageDetailsData.total_duration > 0 ? (
      <>
        <div className="total-usage-summary-header">
          <div className="usage-data-item-header">
            <span className="usage-total-header">
              {generateDurationFormat(
                usageDetailsData && usageDetailsData.total_duration
              )}
            </span>
            <span className="usage-label-header">Total Call Time</span>
          </div>
        </div>
        <LineChartComponent {...VoiceData} />
        <div className="sim-usage-data-table-container">
          <Table2
            {...DurationTotalTableProps}
            numRows={usageDetailsData.results.length}
          >
            <Column
              name=""
              cellRenderer={cellRendererCallType(TableColumns.CALL_TYPE)}
            />
            <Column
              name="Start Time"
              cellRenderer={cellRendererStartEndTime(TableColumns.START_TIME)}
            />
            <Column
              name="Duration"
              cellRenderer={cellRendererDuration(TableColumns.DURATION)}
            />
            <Column
              name="B-Party Number"
              cellRenderer={cellRendererMsisdn(TableColumns.B_PARTY_NUMBER)}
            />
            <Column
              name="Country"
              cellRenderer={cellRendererCountry(TableColumns.COUNTRY)}
            />
          </Table2>
          {<PaginationComponent {...PaginationVoiceProps} />}
        </div>
      </>
    ) : (
      <div className="no-data-state">
        <NonIdealState
          title="No Usage Data"
          description={Messages.NON_IDEAL_STATE}
        />
      </div>
    );
  };

  const handleTabChange = (navbarTabId: TabId): void => {
    if (selectedTab === navbarTabId) return;

    if (navbarTabId === CallTypes.VOICE) {
      fetchUsage(CallTypes.VOICE);
      setSelectedTab(navbarTabId);
    }
    if (navbarTabId === CallTypes.SMS) {
      fetchUsage(CallTypes.SMS);
      setSelectedTab(navbarTabId);
    }
    if (navbarTabId === CallTypes.DATA) {
      fetchUsage(CallTypes.DATA);
      setSelectedTab(navbarTabId);
    }
  };

  const handleAliasUpdate = (data: AliasFormInput) => {
    const { alias } = data;
    setSavingAlias(true);
    updateAlias(routeParamIccid, subsDetails.account.id, alias)
      .then((response) => {
        toast.show({
          intent: Intent.SUCCESS,
          message: `Alias Successfully Updated!`,
        });
        setAlias(alias);
        setSavingAlias(false);
        handleAliasMode();
      })
      .catch((error) => {
        setSavingAlias(false);
        toast.show({
          intent: Intent.DANGER,
          message: error.response.data.description,
        });
      });
  };

  const fetchQRCode = () => {
    getQRCode(routeParamIccid, accountId.current)
      .then((response) => {
        
        if(response.data !== ""){
          setHasEsim(true);
          const {
            activation_code,
            activation_url,
            confirmation_code,
            downloaded_at,
            last_notification_point_id,
            last_notification_result_data,
            last_notification_status,
            qr_code,
            smdp_address,
          } = response.data;
  
          setQRCode({
            activation_code: activation_code,
            activation_url: activation_url,
            confirmation_code: confirmation_code,
            downloaded_at: downloaded_at,
            last_notification_point_id: last_notification_point_id,
            last_notification_result_data: last_notification_result_data,
            last_notification_status: last_notification_status,
            qr_code: qr_code,
            smdp_address:smdp_address,
            iccid: routeParamIccid,
          });
        
        }
        setLoading(false);

      })
      .catch((error) => {
        // add error handling
        setLoading(false);
        console.log(error);
      });
  };

  const handleAliasMode = () => setEditAlisMode(!editAliasMode);
  const handleCancel = () => handleAliasMode();
  const handleIntent = (inputRef: string) => {
    const { alias } = getValues();
    if (inputRef === "alias") {
      if (alias !== "" || !errors.alias) return "primary";
      if (errors.alias) return "danger";
    }
  };

  const renderAliasEditInput = () => {
    return (
      <div className="fade-in">
        <>
          <form onSubmit={handleSubmit(handleAliasUpdate)}>
            <FormGroup>
              <InputGroup
                autoFocus
                className="alias-edit-input"
                type="text"
                name="alias"
                placeholder="Enter Alias"
                defaultValue={alias}
                intent={handleIntent("alias")}
                inputRef={register({
                  required: true,
                })}
                large={true}
                disabled={savingAlias}
              />
              <Button
                className="alias-edit-save-btn"
                type="submit"
                intent={Intent.SUCCESS}
                icon="tick"
                loading={savingAlias}
              />
              <Button
                className="alias-edit-cancel-btn"
                type="button"
                onClick={handleCancel}
                intent={Intent.NONE}
                icon="cross"
                disabled={savingAlias}
              />
              <div className="errorMsg">
                {errors.alias && <p>Alias cannot be blank!</p>}
              </div>
            </FormGroup>
          </form>
        </>
      </div>
    );
  };

  const renderCountryFlag = (isoAlpha3toAlpha2: string | undefined) => {
    if (isoAlpha3toAlpha2) {
      return (
        <>
          <span
            className={`flag-icon flag-icon-${isoAlpha3toAlpha2.toLowerCase()}`}
          ></span>
        </>
      );
    }
  };

  const renderCallTypeIcon = (callType: string) => {
    switch (callType) {
      case "VOICE-MO":
        return (
          <Popover2 position={Position.BOTTOM}>
            <Tooltip2 content="Outgoing Call" position={Position.BOTTOM}>
              <span className="call-type-icon">
                <Icon
                  intent={Intent.NONE}
                  icon="phone"
                  color="#666"
                  size={DEFAULT_ICON_SIZE}
                />
                <Icon
                  intent={Intent.SUCCESS}
                  className="mo-mt-icon"
                  icon="arrow-top-right"
                  color="#666"
                  size={IN_OUT_CALLTYPE_ICONS_SIZE}
                />{" "}
              </span>
            </Tooltip2>
          </Popover2>
        );
      case "VOICE-MT":
        return (
          <Popover2 position={Position.BOTTOM}>
            <Tooltip2 content="Incoming Call" position={Position.BOTTOM}>
              <span className="call-type-icon">
                <Icon
                  intent={Intent.NONE}
                  icon="phone"
                  color="#666"
                  size={DEFAULT_ICON_SIZE}
                />
                <Icon
                  intent={Intent.DANGER}
                  className="mo-mt-icon"
                  icon="arrow-bottom-left"
                  color="#666"
                  size={IN_OUT_CALLTYPE_ICONS_SIZE}
                />{" "}
              </span>
            </Tooltip2>
          </Popover2>
        );
      case "SMS-MO":
        return (
          <Popover2 position={Position.BOTTOM}>
            <Tooltip2 content="Outgoing Message" position={Position.BOTTOM}>
              <span className="call-type-icon">
                <Icon
                  intent={Intent.NONE}
                  icon="chat"
                  color="#666"
                  size={DEFAULT_ICON_SIZE}
                />
                <Icon
                  intent={Intent.SUCCESS}
                  color="#666"
                  className="mo-mt-icon"
                  icon="arrow-top-right"
                  size={IN_OUT_CALLTYPE_ICONS_SIZE}
                />{" "}
              </span>
            </Tooltip2>
          </Popover2>
        );
      case "SMS-MT":
        return (
          <Popover2 position={Position.BOTTOM}>
            <Tooltip2 content="Incoming Message" position={Position.BOTTOM}>
              <span className="call-type-icon">
                <Icon
                  intent={Intent.NONE}
                  icon="chat"
                  color="#666"
                  size={DEFAULT_ICON_SIZE}
                />
                <Icon
                  intent={Intent.SUCCESS}
                  color="#666"
                  className="mo-mt-icon"
                  icon="arrow-bottom-left"
                  size={IN_OUT_CALLTYPE_ICONS_SIZE}
                />{" "}
              </span>
            </Tooltip2>
          </Popover2>
        );
      default:
        return callType;
    }
  };

  const BrandingProps: BrandingProps = {
    width: "150",
    className: "header-brand-logo",
    display:false,
    displayText: ""
  };

  return loading ? (
    <LoadingIndicatorComponent {...LoadingProps} />
  ) : (
    <>
      <div className="wrapper">
        <div>
          <div className="header">
            <BrandingComponent {...BrandingProps} />
            <div className="back-btn">
              <ButtonGroup minimal={true} vertical={false}>
                <Button icon="chevron-left" text="Back" onClick={handleBack} />
              </ButtonGroup>
            </div>
            <div className="username-avatar">
              <UsernameAvatarComponent username={username} />
            </div>
          </div>
          <div>
            <div className="sub-header">
              <FilterContainer updateFilters={updateFilters} />
            </div>
          </div>
          <div className="content fade-in">
            <div className="sim-tab-container">
              {subsDetails ? (
                <Tabs
                  animate={true}
                  id="usageTabs"
                  key="horizontal"
                  vertical={false}
                  large={true}
                  onChange={handleTabChange}
                  selectedTabId={selectedTab}
                >
                  <Tab
                    id={CallTypes.DATA}
                    title={generateCapitalizeString(CallTypes.DATA)}
                    panel={<DataUsageTab />}
                  />

                  {subsDetails && subsDetails.voice && (
                    <Tab
                      id={CallTypes.VOICE}
                      title={generateCapitalizeString(CallTypes.VOICE)}
                      panel={<VoiceUsageTab />}
                    />
                  )}
                  {subsDetails && subsDetails.mo_sms && (
                    <Tab
                      id={CallTypes.SMS}
                      title={CallTypes.SMS.toUpperCase()}
                      panel={<SMSUsageTab />}
                    />
                  )}
                </Tabs>
              ) : (
                <div className="no-data-state">
                  {" "}
                  <NonIdealState
                    title="No Usage Data"
                    description={Messages.NON_IDEAL_STATE}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="rightcolumn fade-in">
            {hasEsim ? <QRCodeComponent {...QRCode}/> : null}
            <h3>
              GENERAL INFORMATION
              <div className="health-indicator-action">
                {" "}
                {renderServiceStatus()}
              </div>
            </h3>
            {isOpen && <div>{renderServiceStatusDialog()}</div>}
            <div className="general-sim-info-summary">
              {subsDetails.msisdns.length > 0 ? (
                <div className="sim-data-item">
                  <span className="sim-summary-label">
                    {generatePrimaryMsisdn(subsDetails.msisdns)}
                  </span>
                  <span className="sim-summary-info-data">PRIMARY NUMBER</span>
                </div>
              ) : null}
              <div className="sim-data-item">
                {editAliasMode ? (
                  <span className="alias">
                    <Button
                      onClick={handleAliasMode}
                      minimal={true}
                      rightIcon="edit"
                      text={alias}
                    ></Button>
                  </span>
                ) : (
                  <div>{renderAliasEditInput()}</div>
                )}
                {editAliasMode && (
                  <span className="sim-summary-info-data">ALIAS</span>
                )}
              </div>
              {subsDetails.iccid ? (
                <div className="sim-data-item">
                  <span className="sim-summary-label">{subsDetails.iccid}</span>
                  <span className="sim-summary-info-data">ICCID</span>
                </div>
              ) : null}
              {subsDetails.last_usage_network_name ? (
                <div className="sim-data-item">
                  <span className="sim-summary-label">
                    {subsDetails.last_usage_network_name}
                  </span>
                  <span className="sim-summary-info-data">NETWORK</span>
                </div>
              ) : null}
              {subsDetails.last_event_network_country ? (
                <div className="sim-data-item">
                  <span className="sim-summary-label helper-display-b">
                    {renderCountryFlag(
                      isoAlpha3toAlpha2.get(
                        subsDetails.last_event_network_country
                      )
                    )}
                    <span className="country-name">
                      {CountryCodes.get(subsDetails.last_event_network_country)}
                    </span>
                  </span>
                  <span className="sim-summary-info-data helper-display-ib">
                    COUNTRY
                  </span>
                  <Popover position={Position.BOTTOM}>
                    <Tooltip
                      content="Last Usage Location"
                      position={Position.BOTTOM}
                    >
                      <Icon
                        className="info-icon"
                        intent={Intent.PRIMARY}
                        size={DEFAULT_ICON_SIZE}
                        icon="info-sign"
                      ></Icon>
                    </Tooltip>
                  </Popover>
                </div>
              ) : null}
            </div>
          </div>
          <div className="footer">
            <FooterComponent />
          </div>
        </div>
      </div>
    </>
  );
};
