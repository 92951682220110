import React, { useState, useEffect } from "react";
import {
  UsernameAvatarComponent,
  SimOverviewCardComponent,
  LoadingIndicatorComponent,
  FilterContainer,
  FooterComponent,
  BrandingComponent,
  PaginationComponent
} from "../../components";
import "./DashboardContainer.css";
import { Line } from "react-chartjs-2";
import User from "../../utility/User";
import Paging from "../../utility/Paging";
import { initRefresh } from "../../api/authService";
import { getSubscriptions, getUsageTotals } from "../../api/usageService";
import { getChartData } from "../../api/chartService";
import {
  generateChartData,
  generateDataUsageFormat,
  generateTooltipLabel,
  generateDurationFormat,
  generatePageTitle,
} from "../../utility/utils";
import { Toaster, Position, Tab, Tabs, TabId, NonIdealState } from "@blueprintjs/core";
import {
  ChartLabels,
  ChartColors,
  CallTypes,
  PageTitles,
  Messages
} from "../../shared/enums";
import { Filters, SimsCardUsage, BrandingProps, PaginationProps } from "../../shared/types";

interface Props {}

const toast = Toaster.create({
  className: "top-toaster",
  position: Position.TOP,
});

let subsResponseData: [{}];
let usageSummaryResonseData: [{}];


export const DashboardContainer: React.FC<Props> = () => {
  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: generateTooltipLabel,
        },
      },
    },
    borderWidth: 3.5,
    elements: {
      line: {
        tension: 0.31,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const user = User.getInstance();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [username, setUsername] = useState<string | null>("");

  // all of the data
  const [totalUsage, setTotalUsage] = useState(0);
  const [totalSMS, setTotalSMS] = useState(0);
  const [totalVoice, setTotalVoice] = useState(0);

  // refactor better names and typing
  const [subsData, setSubsData] = useState([]);
  const [responseData, setResponseData] = useState<any>({});
  const [dataUsage, setDataUsage] = useState<number[]>([]);
  const [dataVoice, setDataVoice] = useState<number[]>([]);
  const [dataSMS, setDataSMS] = useState<number[]>([]);
  const [dates, setDates] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState("data");
  const paging = Paging.getInstance();
  const LoadingProps = {
    text: "Loading...",
    className: "",
  };

  const LoadingDataProps = {
    text: "Loading Dashboard...",
    className: "",
  };

  // let dashboardRefreshTimerId: ReturnType<typeof setInterval>;

  useEffect(() => {
    setUsername(user.getUsername());
    generatePageTitle(PageTitles.DASHBOARD);
    getSubscriptions()
      .then((response) => {
        if (response.data) {
          subsResponseData = response.data.results;
          setResponseData(response.data);
          fetchUsageTotals();
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.show({
          intent: "danger",
          message: `${error} getSubs`,
        });
      });
    // return function cleanup() {
    //   clearInterval(dashboardRefreshTimerId);
    // };
  }, [user]);

  const handlePageChange = (page: any, page_size: any) => {
    paging.setCurrentPage(page);
    paging.setCurrentPageSize(page_size);
    fetchSubs(page, page_size);
  }

  const fetchSubs = (page?: any, page_size?: any) => {
    setLoadingData(true);
      getSubscriptions({
        page: page,
        page_size: page_size
      }).then((response) => {
        if (response.data) {
          subsResponseData = response.data.results;
          setResponseData(response.data);
          fetchUsageTotals();
          
        }
      })
      .catch((error) => {
        toast.show({
          intent: "danger",
          message: `${error} getSubs`,
        });
      });
  }

  const PaginationTotalUsageProps: PaginationProps = {
    page: responseData.page,
    page_size: responseData.page_size,
    total_pages: responseData.total_pages,
    total_results: responseData.total_results,
    handlePageChange: handlePageChange,
  };


  const fetchUsageTotals = () => {
    setLoadingData(true);

    let totalData: number;
    let totalSMSData: number;
    let durationData: number;

    getUsageTotals()
      .then((response: any) => {
        let mergedData: any;
        usageSummaryResonseData = response.data.results;

        initRefresh();
        //if (dashboardRefreshTimerId) clearInterval(dashboardRefreshTimerId);
        mergedData = mergeArrayObjects(
          subsResponseData,
          usageSummaryResonseData
        );
        setSubsData(mergedData);

        totalData = response.data?.total_data || 0;
        setTotalUsage(totalData);
    
        totalSMSData = response.data?.total_sms || 0;
        setTotalSMS(totalSMSData);
    
        durationData = response.data?.total_duration || 0;
        setTotalVoice(durationData);

        fetchChartData();
      })
      .catch((error) => {
        toast.show({
          intent: "danger",
          message: `${error}`,
          timeout: 5000,
        });
      });
  };

  const fetchChartData = () => {
    getChartData()
      .then((response) => {
        let dataUsage = generateChartData(response.data, "data_total");
        let dataVoice = generateChartData(response.data, "duration");
        let dataSMS = generateChartData(response.data, "sms_events");
        let dates = generateChartData(response.data, "date");
        setDataUsage(dataUsage);
        setDates(dates);
        setDataVoice(dataVoice);
        setDataSMS(dataSMS);
        setLoadingData(false);
        //dashboardRefreshTimerId = setInterval(fetchUsageTotals, 120000);
      })
      .catch((error) => {
        console.log(error, "Error..");
      });
  };

  const mergeArrayObjects = (subscribersData: any, usageData: any) => {
    let merged = [];

    for (let index = 0; index < subscribersData.length; index++) {
      merged.push({
        ...subscribersData[index],
        ...usageData.find(
          (sub: any) => sub.iccid === subscribersData[index].iccid
        ),
      });
    }
    return merged;
  };

  const updateFilters = (filterData: Filters) => {
    setTimeout(() => {
      //fetchUsageTotals();
      fetchSubs(0);
    }, 100);
  };

  const generateTotalUsageChartData = () => {
    return {
      labels: dates,
      datasets: [
        {
          label: ChartLabels.TOTAL_DATA,
          data: dataUsage,
          fill: false,
          backgroundColor: ChartColors.WATERMELON_BG_COLOR,
          borderColor: ChartColors.WATERMELON_BORDER_COLOR,
        },
      ],
    };
  };

  const generateTotalSMSUsageChartData = () => {
    return {
      labels: dates,
      datasets: [
        {
          label: ChartLabels.TOTAL_SMS,
          data: dataSMS,
          fill: false,
          backgroundColor: ChartColors.PICTON_BLUE_BG_COLOR,
          borderColor: ChartColors.PICTON_BLUE_BORDER_COLOR,
        },
      ],
    };
  };

  const generateTotalVoiceChartData = () => {
    return {
      labels: dates,
      datasets: [
        {
          label: ChartLabels.TOTAL_VOICE,
          data: dataVoice,
          fill: false,
          backgroundColor: ChartColors.JAGUAR_BG_COLOR,
          borderColor: ChartColors.JAGUAR_BORDER_COLOR,
        },
      ],
    };
  };

  const renderSimCardComponent = () => {
    const LoadingProps = {
      text: "Loading Cards...",
      className: "",
    };
    if (loadingData) {
      return (<LoadingIndicatorComponent {...LoadingProps} />)
    }
    else if (subsData && subsData.length > 0) {
      return (<>
        {subsData.map((item: SimsCardUsage, index: number) => {
          return <SimOverviewCardComponent key={index} {...item} />;
        })}
        <PaginationComponent {...PaginationTotalUsageProps} />
      </>)
    } else {
      return (<div className="empty-data-set-dashboard">
        {''}
        <NonIdealState
          title="No Results"
          description={Messages.NO_RESULTS}
        /></div>
      )
    }
  };

  const TotalDataUsageTab = () => {
    return (
      <div className="fade-in">
        <div className="dashboard-total-usage-summary">
          <div className="dashboardusage-data-item">
            <span className="dashboard-usage-total">
              {generateDataUsageFormat(totalUsage)}
            </span>
            <span className="dashboard-usage-label">Total Data Usage</span>
          </div>
        </div>
        <Line data={generateTotalUsageChartData} options={options} />
      </div>
    );
  };

  const TotalVoiceUsageTab = () => {
    return (
      <div className="fade-in">
        <div className="dashboard-total-usage-summary">
          <div className="dashboard-usage-data-item">
            <span className="dashboard-usage-total">
              {generateDurationFormat(totalVoice)}
            </span>
            <span className="dashboard-usage-label">Total Voice Usage</span>
          </div>
        </div>
        <Line data={generateTotalVoiceChartData} options={options} />
      </div>
    );
  };

  const TotalSMSUsageTab = () => {
    return (
      <div className="fade-in">
        <div className="dashboard-total-usage-summary">
          <div className="dashboard-usage-data-item">
            <span className="dashboard-usage-total">{totalSMS}</span>
            <span className="dashboard-usage-label">Total SMS Usage</span>
          </div>
        </div>
        <Line data={generateTotalSMSUsageChartData} options={options} />
      </div>
    );
  };

  const handleTabChange = (navbarTabId: TabId): void => {
    if (selectedTab === navbarTabId) return;

    if (navbarTabId === CallTypes.VOICE) {
      setSelectedTab(navbarTabId);
    }
    if (navbarTabId === CallTypes.SMS) {
      setSelectedTab(navbarTabId);
    }
    if (navbarTabId === CallTypes.DATA) {
      setSelectedTab(navbarTabId);
    }
  };

  const renderTabs = () => {
    return (
      <>
        {totalUsage && totalUsage > 0 ? (
          <div className="dashboard-tabs-container">
            <Tabs
              animate={false}
              id="totalUsageTabs"
              key="horizontal"
              vertical={false}
              large={true}
              onChange={handleTabChange}
              selectedTabId={selectedTab}
            >
              <Tab
                id="data"
                title="Total Data Usage"
                panel={<TotalDataUsageTab />}
              />
              {totalSMS && totalSMS > 0 ? (
                <Tab
                  id="sms"
                  title="Total SMS Usage"
                  panel={<TotalSMSUsageTab />}
                />
              ) : null}
              {totalVoice && totalVoice > 0 ? (
                <Tab
                  id="voice"
                  title="Total Voice Usage"
                  panel={<TotalVoiceUsageTab />}
                />
              ) : null}
            </Tabs>
          </div>
        ) : null}
      </>
    );
  };

  const BrandingProps: BrandingProps = {
    width: "150",
    className: "header-brand-logo",
    display:false,
    displayText: ""
  }

  return loading ? (
    <LoadingIndicatorComponent {...LoadingProps} />
  ) : (
    <>
      <div className="dashboard-wrapper">
        <div className="dashboard-header">
          <BrandingComponent {...BrandingProps}/>
          <div className="dashboard-username-avatar">
            <UsernameAvatarComponent username={username} />
          </div>
        </div>
        <div className="dashboard-sub-header">
          <FilterContainer updateFilters={updateFilters} />
        </div>
        {loadingData ? (
          <LoadingIndicatorComponent {...LoadingDataProps} />
        ) : (
          <div className="dashboard-content fade-in">
            {renderSimCardComponent()}
            {renderTabs()}
          </div>
        )}
        {!loadingData && (
          <div className="dashboard-footer">
            <FooterComponent />
          </div>
        )}
      </div>
    </>
  );
};
