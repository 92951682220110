export const TableConfigs = {
  defaultSetting: {
    enableColumnResizing: true,
    defaultRowHeight: 25,
    defaultColumnWidth: 170,
    enableRowHeader: true,
    enableGhostCells: false,
  },
  columnWidth: {
    sms: [75, 250, 170, 75],
    data: [200, 100, 150, 350],
    voice: [70, 200, 90, 170, 75],
  },
};
