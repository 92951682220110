import './LoadingIndicatorComponent.css';

interface ILoadingIndicatorProps {
  text: string;
  className: string;
}

export const LoadingIndicatorComponent: React.FC<ILoadingIndicatorProps> = (props) => {
  const { text, className } = props;
  return (
    <>
      <div className={`loading-indicator-container ${className}`}>
        <div className="loading-indicator">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <div className="bounce4"></div>
        </div>
        <div className="loading-indicator-text"> {text}</div>
      </div>
    </>
  );
};
