import { useState, useEffect } from "react";
import { Intent, Button, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import {
  DateRange,
  DateRangeInput,
  DateFormatProps,
} from "@blueprintjs/datetime";
import "./FilterComponent.css";
import {
  CountryFilter,
  OperatorsFilter,
  DateRangeOption,
} from "../../shared/types";
import Filters from "../../utility/Filters";
import {
  generateCountryCodeMultiSelectOptions,
  generateOperatorsMultiSelectOptions, generateCapitalizeString} from "../../utility/utils";
import {  DateRangeOptions } from "../../shared/constants";
import { DateFormat } from "../../shared/enums";
import moment from "moment";
import { SearchComponent } from "../Search/SearchComponent";

const CountriesMultiSelect = MultiSelect.ofType<CountryFilter>();
const OperatorsMultiSelect = MultiSelect.ofType<OperatorsFilter>();

interface FilterProps {
  updateFilters: (filterData: any) => any; // figure out the type {datePreset: e.target.value} CountryFilter[{}]
}

export const FilterContainer: React.FC<FilterProps> = (props) => {
  const { updateFilters } = props;
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [dateRange, setDateRangeFilter] = useState<string | undefined>("");
  const [startDate, setStartDateFilter] = useState<any>("");
  const [endDate, setEndStartDateFilter] = useState<any>();
  const [CountryData, setCountryData] = useState<any>([]); // create type
  const [OperatorsData, setOperatorsData] = useState<any>([]);
  const filters = Filters.getInstance();

  let [countries, setSelectedCountriesFilter] = useState<CountryFilter[]>([]);
  let [operators, setSelectedOperatorFilter] = useState<OperatorsFilter[]>([]);

  useEffect(() => {
    const countryData = generateCountryCodeMultiSelectOptions();
    const operatorsData = generateOperatorsMultiSelectOptions();
    setCountryData(countryData);
    setOperatorsData(operatorsData);

    const initFilters = () => {
      let datePreset = filters.getDatePresetFilter() || "7days";
      let countries = filters.getCountriesFilter() || [];
      let operators = filters.getOperatorsFilter() || [];
      let startDate = filters.getStartDateFilter()
        ? new Date(
            moment(filters.getStartDateFilter()).format(DateFormat.DEFAULT)
          )
        : null;
      let endDate = filters.getEndDateFilter()
        ? new Date(
            moment(filters.getEndDateFilter()).format(DateFormat.DEFAULT)
          )
        : null;
      setDateRangeFilter(datePreset);
      setStartDateFilter(startDate);
      setEndStartDateFilter(endDate);
      setSelectedCountriesFilter(countries);
      setSelectedOperatorFilter(operators);
      if (startDate) setShowDateRangePicker(true);
    };
    initFilters();
  }, [filters]);

  const handleDateRangeSelection = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let selectedDateRangeVal = e.target.value;

    if (selectedDateRangeVal === "selectDate") {
      setShowDateRangePicker(true);
      filters.removeDatePresetFilter();
      return;
    } else {
      setShowDateRangePicker(false);
      updateFilters({ datePreset: e.target.value });
      filters.setDatePresetFilter(selectedDateRangeVal);
      setDateRangeFilter(selectedDateRangeVal);
    }
  };

  const renderDateRangeDropDown = () => {
    return (
      <>
        <div className="bp3-html-select .modifier">
          <select value={dateRange} onChange={handleDateRangeSelection}>
            {renderDateRangeSelectOptions(DateRangeOptions)}
          </select>
          <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
        </div>
      </>
    );
  };

  const renderDateRangeSelectOptions = (options: DateRangeOption[]) => {
    return options.map((e, key) => {
      return (
        <option key={key} value={e.value}>
          {e.name}
        </option>
      );
    });
  };

  const getTagProps = (_value: React.ReactNode, index: number) => ({
    intent: Intent.NONE,
    minimal: true,
  });

  const renderCountryTag = (country: CountryFilter) => country.name;

  const selectCountry = (country: CountryFilter) => {
    let tempNewCountryState = countries;
    tempNewCountryState.push(country);
    setSelectedCountriesFilter(tempNewCountryState);
    updateFilters(tempNewCountryState);
    filters.setCountriesFilter(countries);
  };

  const deselectCountry = (index: number) => {
    let tempCountrySelectionState = countries;
    tempCountrySelectionState.splice(index, 1);
    setSelectedCountriesFilter(tempCountrySelectionState);
    updateFilters(tempCountrySelectionState);
    filters.setCountriesFilter(countries);
  };

  const handleClear = () => {
    setSelectedCountriesFilter([]);
    filters.removeCountriesFilter();
    updateFilters([]);
  };
  const clearCountrySelectButton =
    countries && countries.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={handleClear} />
    ) : undefined;

  const handleCountrySelect = (country: CountryFilter) => {
    if (!isCountrySelected(country)) {
      selectCountry(country);
    } else {
      deselectCountry(getSelectedCountryIndex(country));
    }
  };

  const isCountrySelected = (country: CountryFilter) => {
    return getSelectedCountryIndex(country) !== -1;
  };

  const getSelectedCountryIndex = (country: CountryFilter) => {
    return countries.indexOf(country);
  };

  const handleCountryTagRemove = (_tag: React.ReactNode, index: number) => {
    deselectCountry(index);
  };

  const queryCountries = (query: string) => {
    if (query === "") return CountryData;
    else {
      return CountryData.filter((country: CountryFilter) =>
      country.abbrName.includes((query.toUpperCase())) || country.name.includes(generateCapitalizeString(query))
      );
    }
  };

  const renderCountry: ItemRenderer<CountryFilter> = (
    country,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        icon={isCountrySelected(country) ? "tick" : "blank"}
        key={country.id}
        label={country.abbrName.toString()}
        onClick={handleClick}
        text={`${country.name}`}
        shouldDismissPopover={false}
      />
    );
  };

  const renderCountryFilters = () => {
    return (
      <CountriesMultiSelect
        placeholder="Country"
        selectedItems={countries}
        itemListPredicate={queryCountries}
        noResults={<MenuItem disabled={true} text="No results." />}
        itemRenderer={renderCountry}
        onItemSelect={handleCountrySelect}
        popoverProps={{ minimal: true }}
        items={CountryData}
        fill={false}
        resetOnQuery={false}
        resetOnSelect={true}
        tagRenderer={renderCountryTag}
        tagInputProps={{
          onRemove: handleCountryTagRemove,
          rightElement: clearCountrySelectButton,
          tagProps: getTagProps,
        }}
      ></CountriesMultiSelect>
    );
  };

  const renderOperatorTag = (operator: OperatorsFilter) =>
    operator.operator_name;

  const selectOperator = (operator: OperatorsFilter) => {
    let tempNewOperatorState = operators;
    tempNewOperatorState.push(operator);
    setSelectedOperatorFilter(tempNewOperatorState);
    updateFilters(tempNewOperatorState);
    filters.setOperatorsFilter(operators);
  };

  const deselectOperator = (index: number) => {
    let tempOperatorSelectionState = operators;
    tempOperatorSelectionState.splice(index, 1);
    setSelectedOperatorFilter(tempOperatorSelectionState);
    updateFilters(tempOperatorSelectionState);
    filters.setOperatorsFilter(operators);
  };

  const handleOperatorClear = () => {
    setSelectedOperatorFilter([]);
    filters.removeOperatorsFilter();
    updateFilters([]);
  };

  const clearOperatorsSelectBtn =
    operators && operators.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={handleOperatorClear} />
    ) : undefined;

  const handleOperatorSelect = (operator: OperatorsFilter) => {
    if (!isOperatorSelected(operator)) {
      selectOperator(operator);
    } else {
      deselectOperator(getSelectedOperatorIndex(operator));
    }
  };

  const isOperatorSelected = (operator: OperatorsFilter) => {
    return getSelectedOperatorIndex(operator) !== -1;
  };

  const getSelectedOperatorIndex = (operator: OperatorsFilter) => {
    return operators.indexOf(operator);
  };

  const handleOperatorTagRemove = (_tag: React.ReactNode, index: number) => {
    deselectOperator(index);
  };

  const queryNetworkOperators = (query: string) => {
    if (query === "") return OperatorsData;
    else {
      return OperatorsData.filter((operator: OperatorsFilter) =>
      operator.tadig.includes(query.toUpperCase()) || operator.operator_name.includes(generateCapitalizeString(query))
      );
    }
  };

  const renderOperators: ItemRenderer<OperatorsFilter> = (
    operator,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        icon={isOperatorSelected(operator) ? "tick" : "blank"}
        key={operator.id}
        label={operator.tadig.toString()}
        onClick={handleClick}
        text={`${operator.operator_name}`}
        shouldDismissPopover={false}
      />
    );
  };

  const renderOperatorsFilters = () => {
    return (
      <OperatorsMultiSelect
        placeholder="Networks"
        itemListPredicate={queryNetworkOperators}
        noResults={<MenuItem disabled={true} text="No results." />}
        selectedItems={operators}
        itemRenderer={renderOperators}
        onItemSelect={handleOperatorSelect}
        popoverProps={{ minimal: true }}
        resetOnQuery={false}
        resetOnSelect={true}
        items={OperatorsData}
        fill={false}
        tagRenderer={renderOperatorTag}
        tagInputProps={{
          onRemove: handleOperatorTagRemove,
          rightElement: clearOperatorsSelectBtn,
          tagProps: getTagProps,
        }}
      ></OperatorsMultiSelect>
    );
  };

  const handleDateChange = (dateRange: DateRange) => {
    const [startDate, endDate] = dateRange;
    if (startDate) {
      setStartDateFilter(startDate);
    }
    if (endDate) {
      setEndStartDateFilter(endDate);
    }

    if (startDate && endDate) {
      let formattedStartDate = moment(startDate, DateFormat.DEFAULT).format(
        DateFormat.YEAR_MONTH_DAY
      );
      let formattedEndDate = moment(endDate, DateFormat.DEFAULT).format(
        DateFormat.YEAR_MONTH_DAY
      );
      filters.removeDatePresetFilter();
      filters.setStartDateFilter(formattedStartDate);
      filters.setEndDateFilter(formattedEndDate);
      updateFilters({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    }
  };

  const handleCancelDatePicker = () => {
    setShowDateRangePicker(false);
    setStartDateFilter(null);
    setEndStartDateFilter(null);
    filters.removeDateRangeFilters();
    updateFilters({});
  };

  const jsDateFormatter: DateFormatProps = {
    formatDate: (date) =>
      moment(date)
        .locale(DateFormat.DEFAULT_LOCALE)
        .format(DateFormat.DATE_PICKER_VIEW),
    parseDate: (str) => new Date(str)
  };



  const renderDateRangePicker = () => {
    return (
      <span className="date-range-container">
        <DateRangeInput
          {...jsDateFormatter}
          allowSingleDayRange={true}
          shortcuts={false}
          onChange={handleDateChange}
          parseDate={(str) => new Date(str)}
          value={[startDate, endDate]}
        />
      </span>
    );
  };

  const handleClearFilters = () => {
    setSelectedOperatorFilter([]);
    filters.removeOperatorsFilter();
    setSelectedCountriesFilter([]);
    filters.removeCountriesFilter();
    setShowDateRangePicker(false);
    setStartDateFilter(null);
    setEndStartDateFilter(null);
    filters.removeDateRangeFilters();
    setDateRangeFilter("7days");
    updateFilters([]);
  }

  return (
    <div className="filter-container">
      <b>Filter By:</b>
      {!showDateRangePicker && (
        <div className="filters">{renderDateRangeDropDown()}</div>
      )}
      {showDateRangePicker && (
        <div className="date-range-selector-container">
          Date Range:
          {renderDateRangePicker()}
          <Button
            onClick={() => handleCancelDatePicker()}
            intent="none"
            title="Remove Date Range Filter"
            text="Remove"
            outlined={true}
          />
        </div>
      )}
      <span>{renderCountryFilters()}</span>
      <span>{renderOperatorsFilters()}</span>
      <Button onClick={() =>handleClearFilters()} text="Reset Filters" intent="primary" outlined={true}/>
      <div className="search"><SearchComponent /></div>
    </div>
  );
};
