import { apiClient, API_CONFIG } from "./config";
import Filters from "../utility/Filters";
import { CallTypes, DefaultApiParams } from "../shared/enums";
import { CountryFilter, OperatorsFilter } from "../shared/types";

const filters = Filters.getInstance();

export const getChartData = ({
  iccid = "",
  currency = DefaultApiParams.CURRENCY,
  date_preset = filters.getDatePresetFilter() || DefaultApiParams.DATE_PRESET,
  page_size = '50' || DefaultApiParams.PAGE_SIZE,
  call_type = "",
  include_pending= "true",
  countries = filters.getCountriesFilter() || {},
  networks = filters.getOperatorsFilter() || {},
} = {}) => {
  let params = new URLSearchParams();
  params.append("iccid", iccid);
  params.append("currency", currency);
  params.append("page_size", page_size);
  params.append("date_preset", date_preset);
  params.append("include_pending", include_pending)


  if(filters.getStartDateFilter() && filters.getEndDateFilter()){
    params.append("start", filters.getStartDateFilter());
    params.append("end", filters.getEndDateFilter());
    params.delete("date_preset");
  }
  
  if (countries && Array.isArray(countries)) {
    countries.forEach((item: CountryFilter) => {
      params.append("countries", item.abbrName);
    });
  }

  if(networks && Array.isArray(networks)){
    networks.forEach((item: OperatorsFilter) => {
      params.append("networks", item.tadig)
    });
  }

  if (call_type === CallTypes.VOICE) {
    params.append("call_types", "VOICE-MT");
    params.append("call_types", "VOICE-MO");
  }
  if (call_type === CallTypes.SMS) {
    params.append("call_types", "SMS-MT");
    params.append("call_types", "SMS-MO");
  }

  let request = {
    params: params,
  };

  return apiClient.get(`${API_CONFIG.GET_USAGE_SUMMARY}/daily`, request);
};
