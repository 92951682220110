import { apiClient, API_CONFIG } from "../api/config";
import Filters from "../utility/Filters";
import Paging from "../utility/Paging";
import {
  SubDetailsParams,
  CountryFilter,
  OperatorsFilter,
} from "../shared/types";
import { DefaultApiParams, CallTypes } from "../shared/enums";

const filters = Filters.getInstance();
const paging = Paging.getInstance();

export const getSubscriptions = ({
  sort = "iccid",
  order = "desc",
  page = paging.getCurrentPage() || DefaultApiParams.PAGE,
  page_size = paging.getCurrentPageSize() || DefaultApiParams.PAGE_SIZE,
  date_preset = filters.getDatePresetFilter() || DefaultApiParams.DATE_PRESET,
  countries = filters.getCountriesFilter() || {},
  networks = filters.getOperatorsFilter() || {},
} = {}) => {

  let params = new URLSearchParams();
  params.append("page_size", page_size);
  params.append("sort", sort);
  params.append("order", order);
  params.append("page", page);
  params.append("last_usage_date", date_preset);

  if (filters.getStartDateFilter() && filters.getEndDateFilter()) {
    params.append("last_usage_start", filters.getStartDateFilter());
    params.append("last_usage_end", filters.getEndDateFilter());
    params.delete("last_usage_date");
  }

  if (countries && Array.isArray(countries)) {
    countries.forEach((item: CountryFilter) => {
      params.append("last_usage_countries", item.abbrName);
    });
  }

  if (networks && Array.isArray(networks)) {
    networks.forEach((item: OperatorsFilter) => {
      params.append("last_usage_networks", item.tadig);
    });
  }

  return apiClient.get(`${API_CONFIG.GET_SUBSCRIPTIONS}`, { params });
};

export const search = (searchStr:string) => {
  let params = {
    sort: "alias",
    order: "asc",
    page: 0,
    page_size: 100,
    search: searchStr,
  };
  return apiClient.get(`${API_CONFIG.SEARCH}`, { params });
}


export const getSubscriptionDetails = (iccid: string) => {
  const params: SubDetailsParams = {
    iccid: iccid,
  };

  return apiClient.get(`${API_CONFIG.GET_SUBSCRIPTIONS}`, { params });
};

export const getUsageTotals = ({
  // page = "" ? "" : DefaultApiParams.PAGE,
  summarize = DefaultApiParams.SUMMARIZE,
  currency = DefaultApiParams.CURRENCY,
  date_preset = filters.getDatePresetFilter() || DefaultApiParams.DATE_PRESET,
  countries = filters.getCountriesFilter() || {},
  networks = filters.getOperatorsFilter() || {},
  include_pending = "true",
} = {}) => {
  let params = new URLSearchParams();
  params.append("summarize", summarize);
  params.append("currency", currency);
  // params.append("page", page);
  // params.append("page_size", page_size);
  params.append("date_preset", date_preset);
  params.append("include_pending", include_pending);

  if (filters.getStartDateFilter() && filters.getEndDateFilter()) {
    params.append("start", filters.getStartDateFilter());
    params.append("end", filters.getEndDateFilter());
    params.delete("date_preset");
  }

  if (countries && Array.isArray(countries)) {
    countries.forEach((item: CountryFilter) => {
      params.append("countries", item.abbrName);
    });
  }

  if (networks && Array.isArray(networks)) {
    networks.forEach((item: OperatorsFilter) => {
      params.append("networks", item.tadig);
    });
  }

  let request = {
    params: params,
  };
  return apiClient.get(`${API_CONFIG.GET_USAGE_SUMMARY}`, request);
};

export const getUsageDetails = ({
  date_preset = filters.getDatePresetFilter() || DefaultApiParams.DATE_PRESET,
  sort = DefaultApiParams.SORT,
  order = DefaultApiParams.ORDER,
  // page = DefaultApiParams.PAGE,
  // page_size = DefaultApiParams.PAGE_SIZE,
  currency = DefaultApiParams.CURRENCY,
} = {}) => {
  const params = {
    date_preset: date_preset,
    sort: sort,
    order: order,
    // page: page,
    // page_size: page_size,
    currency: currency,
    include_pending: true,
  };
  return apiClient.get(`${API_CONFIG.GET_USAGE_DETAILS}`, { params });
};

export const getUsage = ({
  currency = DefaultApiParams.CURRENCY,
  accountId = "",
  iccid = "",
  page_size = "" ? "" : DefaultApiParams.PAGE_SIZE,
  sort = DefaultApiParams.SORT,
  order = DefaultApiParams.ORDER,
  page = "" ? "" : DefaultApiParams.PAGE,
  date_preset = filters.getDatePresetFilter() || DefaultApiParams.DATE_PRESET,
  call_type = "",
  countries = filters.getCountriesFilter() || {},
  networks = filters.getOperatorsFilter() || {},
  include_pending = "true",
} = {}) => {
  let params = new URLSearchParams();
  params.append("currency", currency);
  params.append("page_size", page_size);
  params.append("sort", sort);
  params.append("order", order);
  params.append("page", page);
  params.append("date_preset", date_preset);
  params.append("include_pending", include_pending);

  if (filters.getStartDateFilter() && filters.getEndDateFilter()) {
    params.append("start", filters.getStartDateFilter());
    params.append("end", filters.getEndDateFilter());
    params.delete("date_preset");
  }

  if (countries && Array.isArray(countries)) {
    countries.forEach((item: CountryFilter) => {
      params.append("countries", item.abbrName);
    });
  }

  if (networks && Array.isArray(networks)) {
    networks.forEach((item: OperatorsFilter) => {
      params.append("networks", item.tadig);
    });
  }

  if (call_type === CallTypes.VOICE) {
    params.append("call_types", "VOICE-MT");
    params.append("call_types", "VOICE-MO");
  }
  if (call_type === CallTypes.SMS) {
    params.append("call_types", "SMS-MT");
    params.append("call_types", "SMS-MO");
  }

  let request = {
    params: params,
  };

  return apiClient.get(
    `${API_CONFIG.GET_USAGE}/${accountId}/detailed?iccid=${iccid}`,
    request
  );
};

export const updateAlias = (iccid = "", accountId: number, data: string) => {
  const alias = {
    alias: data,
  };
  return apiClient.post(
    `${API_CONFIG.GET_SUBSCRIPTIONS}/${accountId}/${iccid}/attrs`,
    alias
  );
};

export const updateServiceStatus = (
  accountId: string,
  subscriptionId: number,
  operation: string
) => {
  return apiClient.put(
    `${API_CONFIG.GET_SUBSCRIPTIONS}/${accountId}/${subscriptionId}/${operation}`
  );
};

export const getQRCode = (iccid: string, accountId: string) => {
  return apiClient.get(
    `${API_CONFIG.GET_SUBSCRIPTIONS}/${accountId}/${iccid}/esim_qr`
  );
};
