import Storage from "./LocalStorageHelper";
import { Locals } from "../shared/enums";
import { IUser } from "../shared/types";

export default class User extends Storage<Locals> {
  private static instance?: User;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new User();
    }

    return this.instance;
  }
  public getUser() {
    return this.getItem(Locals.USER) || "";
  }

  public setUser(user: IUser) {
    this.setItem(Locals.USER, JSON.stringify(user));
  }

  public getUsername() {
    return this.getItem(Locals.USERNAME);
  }

  public setUsername(username: string) {
    this.setItem(Locals.USERNAME, username);
  }

  public removeUsername(){
    this.removeItem(Locals.USERNAME);
  }

  public removeUser() {
    this.removeItems([Locals.USER]);
  }
}
