import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  InputGroup,
  FormGroup,
  Elevation,
  Toaster,
  Position,
  Intent,
} from "@blueprintjs/core";
import "./LoginContainer.css";
import { useForm } from "react-hook-form";
import Tokens from "../../utility/Tokens";
import User from "../../utility/User";
import { useHistory } from "react-router-dom";
import { TOAST_DISPLAY_DEFAULT_TIME } from "../../shared/constants";
import { generateUsername, generatePageTitle } from "../../utility/utils";
import { authUser, getUser } from "../../api/authService";
import {
  AuthRoutes,
  AuthErrorMsgs,
  LoginDefaultConfigs,
  PageTitles,
} from "../../shared/enums";
import { BrandingProps } from "../../shared/types";
import { BrandingComponent } from "../../components/";

interface IFormInput {
  username: string;
  password: string;
}

const toast = Toaster.create({
  className: "top-toaster",
  position: Position.TOP,
});

export const LoginContainer: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IFormInput>({ mode: "onChange" });
  const history = useHistory();

  const tokens = Tokens.getInstance();
  const isAuthorized = tokens.getToken();
  const user = User.getInstance();
  const [loading, setLoading] = useState<boolean>(false);
  const [forcePassChange, setForcePassChangeVal] = useState();

  useEffect(() => {
    generatePageTitle(PageTitles.LOGIN);
    if (isAuthorized && forcePassChange) {
      history.push(AuthRoutes.changePassword);
      return;
    } else if (isAuthorized) {
      history.push(AuthRoutes.dashboard);
      return;
    }
  }, [isAuthorized, history, forcePassChange]);

  const handleAuthentication = (data: IFormInput): void => {
    const { username, password } = data;

    // credentials
    const dataObj = {
      username: username,
      password: password,
      role: LoginDefaultConfigs.ROLE,
    };
    setLoading(true);
    authUser(dataObj)
      .then((response) => {
        fetchAccount();
      })
      .catch((error) => {
        console.log(error.response);
        toast.show({
          intent: Intent.DANGER,
          message:
            error.response.status === 401
              ? AuthErrorMsgs.UNAUTHORIZED
              : AuthErrorMsgs.CONNECTION_ERROR,
          timeout: TOAST_DISPLAY_DEFAULT_TIME,
        });
        setLoading(false);
      });
  };

  const fetchAccount = () => {
    getUser()
      .then((response) => {
        if (response.data) {
          const usersInfo = generateUsername(response.data);
          user.setUser(response.data);
          user.setUsername(usersInfo.username);
          setForcePassChangeVal(response.data.force_password_change);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.show({
          intent: Intent.DANGER,
          message:
            error.response.status === 400
              ? AuthErrorMsgs.CONNECTION_ERROR
              : error.response.data,
          timeout: TOAST_DISPLAY_DEFAULT_TIME,
        });
        setLoading(false);
      });
  };

  const BrandingProps: BrandingProps = {
    width: "200",
    className: "logo",
    display: false,
    displayText: "Sign In",
  };

  return (
    <div className="container">
      <Card interactive={false} elevation={Elevation.THREE}>
        <BrandingComponent {...BrandingProps} />
        <div className="login-container">
          <form onSubmit={handleSubmit(handleAuthentication)}>
            <FormGroup>
              <InputGroup
                autoFocus
                type="text"
                name="username"
                inputRef={register({
                  required: true,
                })}
                placeholder="Username"
                large={true}
              />
              <div className="errorMsg">
                {errors.username && <p>Please enter a valid Username</p>}
              </div>
            </FormGroup>
            <FormGroup>
              <InputGroup
                type="password"
                name="password"
                inputRef={register({
                  required: true,
                })}
                placeholder="Password"
                large={true}
              />
              <div className="errorMsg">
                {errors.password && <p>Please enter a valid Password</p>}
              </div>
            </FormGroup>
            <Button
              loading={loading}
              disabled={!formState.isValid}
              type="submit"
              intent={Intent.PRIMARY}
              fill={true}
              outlined={true}
              text="Sign In"
              large={true}
            />
          </form>
        </div>
      </Card>
    </div>
  );
};
