import  Storage from './LocalStorageHelper';
import {Locals} from '../shared/enums';


export default class Tokens extends  Storage<Locals> {

    private static instance?: Tokens;

    public static getInstance() {
        if (!this.instance) {
          this.instance = new Tokens();
        }
    
        return this.instance;
      }

    public getToken(): string | null {
        return this.getItem(Locals.ACCESS_TOKEN);
    }

    public setToken(token: string){
        this.setItem(Locals.ACCESS_TOKEN, token);
    }

    public getRefreshToken(){
        return this.getItem(Locals.REFRESH_TOKEN);
    }

    public setRefreshToken(refreshToken: string){
        this.setItem(Locals.REFRESH_TOKEN, refreshToken);
    }

    public removeTokens(){
        this.removeItems([Locals.ACCESS_TOKEN, Locals.REFRESH_TOKEN]);
    }

    public parseJwt(){
        let base64Url = this.getToken()?.split('.')[1];
        let base64 = base64Url?.replace('-', '+').replace('_', '/') || "";
        return JSON.parse(window.atob(base64));
    }
}