import ReactDOM from "react-dom";
import "./index.css";
import {
  LoginContainer,
  DashboardContainer,
  SimDetailsContainer,
  ChangePasswordContainer,
} from "./pages";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthRoute } from "./utility/AuthRoute";
import { AuthRoutes, NonAuthRoutes } from "./shared/enums";

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path={NonAuthRoutes.login} component={LoginContainer} />
      <AuthRoute path={AuthRoutes.dashboard} Component={DashboardContainer} />
      <AuthRoute path={AuthRoutes.simDetails} Component={SimDetailsContainer} />
      <AuthRoute
        path={AuthRoutes.changePassword}
        Component={ChangePasswordContainer}
      />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
