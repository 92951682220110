import Storage from "./LocalStorageHelper";
import { PagingTypes } from '../shared/enums';


export default class Paging extends Storage<PagingTypes>{

    private static instance?: Paging;

    public static getInstance() {
        if (!this.instance) {
            this.instance = new Paging();
        }

        return this.instance;
    }


    public getCurrentPage() {
        let item: any = this.getItem(PagingTypes.PAGE);
        return JSON.parse(item);
    }

    public getCurrentPageSize() {
        let item: any = this.getItem(PagingTypes.PAGE_SIZE);
        return JSON.parse(item);
    }

    public setCurrentPageSize(currentPage: string) {
        this.setItem(PagingTypes.PAGE_SIZE, currentPage)
    }

    public setCurrentPage(page: string) {
        this.setItem(PagingTypes.PAGE, page)
    }

    public removeCurrentPage() {
        this.removeItem(PagingTypes.PAGE);
    }

    public removePageSize() {
        this.removeItem(PagingTypes.PAGE_SIZE);
    }

    public removeAllPaging(){
        this.removeItems([PagingTypes.PAGE, PagingTypes.PAGE_SIZE]);
    }
}