import {
  ChartTooltip,
  Msisdns,
  Username,
  ChartData,
} from "../shared/types";
import { CountryCodes, Operators } from "../shared/constants";
import numeral from "numeral";
import { ChartLabels, PageTitles } from "../shared/enums";
import libphonenumber from "google-libphonenumber";

export const generateUsername = (data: Username): Username => {
  return {
    username: data.username || "Username",
  };
};

export const generateUsernameInitial = (username: string | null): string => {
  return username ? username.charAt(0).toLocaleUpperCase() : "unknown";
};

export const generateChartData = (data: ChartData[], field: string) => {
  return data.map((dataItem: ChartData) => {
    return dataItem[field];
  });
};

export const generateDataUsageFormat = (value: number): number | string => {
  if (value > 1024 * 1024 * 1000 * 1000) {
    value = value / 1024 / 1024 / 1000 / 1000;
    return `${numeral(Number(value)).format("0.0")} TB`;
  } else if (value > 1024 * 1024 * 1000) {
    value = value / 1024 / 1024 / 1000;
    return `${numeral(Number(value)).format("0.0")} GB`;
  } else {
    return numeral(Number(value))
      .format("0.0 ib ")
      .replace(/i/, "")
      .replace(/\s/, " ");
  }
};

export const generateDurationFormat = (secondsIn: number): string => {
  let hours = Math.floor(secondsIn / 3600),
    remainder = secondsIn % 3600,
    minutes = Math.floor(remainder / 60),
    seconds = remainder % 60;
  let parts = [];
  if (hours) parts.push(hours + "h");
  if (minutes) parts.push(minutes + "m");
  parts.push(seconds + "s");
  return parts.join(", ");
};

export const generateTooltipLabel = (tooltipItem: ChartTooltip): string => {
  let label = tooltipItem.dataset.label || "";

  if (label) {
    label += ": ";
  }
  if (
    label === `${ChartLabels.TOTAL_DATA}: ` &&
    tooltipItem.parsed.y !== null
  ) {
    label += generateDataUsageFormat(tooltipItem.parsed.y);
  }
  if (
    label === `${ChartLabels.TOTAL_VOICE}: ` &&
    tooltipItem.parsed.y !== null
  ) {
    label += generateDurationFormat(tooltipItem.parsed.y);
  }
  if (label === `${ChartLabels.TOTAL_SMS}: ` && tooltipItem.parsed.y !== null) {
    label += `${tooltipItem.parsed.y}`;
  }
  return label;
};

export const generateCountryCodeMultiSelectOptions = () => {
  return Array.from(CountryCodes, ([abbrName, name]) => ({
    abbrName,
    name,
  })).map((m, index) => ({ ...m, id: index + 1 }));
};

export const generateOperatorsMultiSelectOptions = () => {
  return Array.from(Operators, ([tadig, operator_name]) => ({
    tadig,
    operator_name,
  })).map((m, index) => ({ ...m, id: index + 1 }));
};

export const generateServiceStatusIntent = (
  service_status: number
): "success" | "danger" | "none" | "warning" => {
  switch (service_status) {
    case 200:
      return "success";
    case 280:
    case 240:
      return "danger";
    case 225:
    case 235:
      return "warning";
    case 400:
      return "none";
    default:
      return "success";
  }
};

export const generateServiceStatusIcon = (
  service_status: number
): "tick" | "disable" | "issue" | "tick" | "ungroup-objects" => {
  switch (service_status) {
    case 200:
      return "tick";
    case 280:
    case 240:
      return "issue";
    case 400:
      return "disable";
    case 225:
    case 235:
      return "ungroup-objects";
    default:
      return "tick";
  }
};

export const generatePhoneNumberFormat = (
  msisdn: string | undefined
): string | undefined => {
  try {
    if (msisdn === null) {
      return "N/A";
    }
    let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    let pn = phoneUtil.parse("+" + msisdn, undefined);
    return phoneUtil.format(pn, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
  } catch (e) {
    console.warn("Error formatting phonenumber ", msisdn, e);
    return "+" + msisdn;
  }
};

export const generatePrimaryMsisdn = (
  msisdns: Msisdns[]
): string | undefined => {
  if (msisdns && msisdns.length) {
    let primaryMsisdn = msisdns.find(
      (msisdn: Msisdns) => msisdn.primary === true
    );
    return generatePhoneNumberFormat(primaryMsisdn?.msisdn);
  }
};

export const generateCapitalizeString = (word: string): string => {
  return word[0].toUpperCase() + word.slice(1);
};

export const generatePageTitle = (page: string): string => {
  switch (page) {
    case PageTitles.LOGIN:
      return (document.title = `${PageTitles.PORTAL_NAME} ${PageTitles.LOGIN}`);
    case PageTitles.CHANGE_PASSWORD:
        return (document.title = `${PageTitles.PORTAL_NAME} ${PageTitles.CHANGE_PASSWORD}`);
    case PageTitles.DASHBOARD:
      return (document.title = `${PageTitles.PORTAL_NAME} ${PageTitles.DASHBOARD}`);
    case PageTitles.SIM_DETAILS:
      return (document.title = `${PageTitles.PORTAL_NAME} ${PageTitles.SIM_DETAILS}`);
    default:
      return (document.title = PageTitles.PORTAL_NAME);
  }
};

export const generateTruncatedString = (
  str: string,
  strLen: number
): string => {
  if(str){
    return str.length > strLen ? str.substr(0, strLen - 1) + "..." : str;
  } else {
    return str;
  }
};

/*
const calculateTotalUsage = (data: [{}], usageType: string) => {
  return data
    .map((usage: any) => usage[usageType])
    .filter(Boolean)
    .reduce((sum: number, val: number) => sum + val, 0);
};
*/
