import React, { useState, useEffect } from "react";
import {
  FormGroup,
  InputGroup,
  Button,
  Intent,
  Callout,
  Toaster,
  Position,
} from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import {
  COPIED_TXT,
  SMDP_ADDRESS_TXT,
  ACTIVATION_CODE_TXT,
  ACTIVATION_URL_TXT,
  CLIPBOARD_RESET_TIME,
} from "../../shared/constants";
import { QRCodeProps } from "../../shared/types";
import "./QRCodeComponent.css";
import moment from "moment";
import QRCode from "qrcode";

const toast = Toaster.create({
  className: "top-toaster",
  position: Position.TOP,
});
export const QRCodeComponent: React.FC<QRCodeProps> = (props) => {
  const {
    activation_code,
    activation_url,
    downloaded_at,
    last_notification_point_id,
    last_notification_status,
    smdp_address,
    iccid,
  } = props;
  const [qrCodeDownloaded, setQRCodeDownloaded] = useState(false);
  const [copySMDPAddress, setCopySMDPAddress] = useState(SMDP_ADDRESS_TXT);
  const [SMDPAddress, setSMDPAddress] = useState("");
  const [qrCodeDownloadError, setQRDownloadError] = useState(false);
  const [qrCodeDownloadSuccess, setQRDownloadedSuccess] = useState(false);
  const [copyActivationCode, setCopyActivationCode] =
    useState(ACTIVATION_CODE_TXT);
  const [activationCode, setActivationCode] = useState("");

  const [copyActivationURL, setCopyActivationURL] =
    useState(ACTIVATION_URL_TXT);
  const [activationURL, setActivationURL] = useState("");

  useEffect(() => {
    const renderQRCode = () => {
      if (
        hasTimestampBeenSet(downloaded_at) ||
        hasMatchingNotificationId(last_notification_point_id)
      ) {
        setQRCodeDownloaded(true);
        if (last_notification_status !== "EXECUTION_STATUS_SUCCESS") {
          setQRDownloadError(true);
          return;
        } else {
          setQRDownloadedSuccess(true);
          return;
        }
      }
      setActivationURL(activation_url);
      setActivationCode(activation_code);
      setSMDPAddress(smdp_address);

      QRCode.toCanvas(
        document.getElementById("canvas"),
        activation_url,
        (error) => {
          if (error) {
            toast.show({
              intent: Intent.DANGER,
              message: error,
            });
          }
        }
      );
    };

    const hasTimestampBeenSet = (timestamp: any) => {
      return timestamp !== null;
    };

    const hasMatchingNotificationId = (noteId: string) => {
      return (
        noteId === "NOTIFICATION_POINT_ID_BPP_DOWNLOAD" ||
        noteId === "NOTIFICATION_POINT_ID_BPP_INSTALL"
      );
    };

    renderQRCode();
  }, [activation_code,activation_url,downloaded_at,last_notification_point_id,last_notification_status,smdp_address]);

  const renderDownloadedText = () => {
    return (
      <>
        <Callout
          className="qr-callout"
          icon="tick"
          intent={Intent.SUCCESS}
          title="eSIM Already Downloaded"
        >
          <p>
            eSIM already downloaded, contact our{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://help.t26.ch">
              Customer Care
            </a>{" "}
            team if you need to download it again.
          </p>
        </Callout>
      </>
    );
  };

  const renderErrorText = () => {
    return (
      <>
        <Callout
          className="qr-callout"
          icon="high-priority"
          intent={Intent.DANGER}
          title="eSIM Download Error"
        >
          <p>
            The eSIM download or installation failed. Contact our{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://help.t26.ch">
              Customer Care
            </a>{" "}
            team if you need support.
          </p>
        </Callout>
      </>
    );
  };

  const renderCopyTextBtn = (
    tooltipTxt: string,
    handleShowInputVal: () => void
  ): JSX.Element => {
    return (
      <Tooltip2 position="bottom" content={`${tooltipTxt}`}>
        <Button
          icon="duplicate"
          intent={Intent.NONE}
          minimal={true}
          onClick={handleShowInputVal}
        />
      </Tooltip2>
    );
  };

  const copyAddressToClipBoard = () => {
    navigator.clipboard.writeText(SMDPAddress);
    setCopySMDPAddress(COPIED_TXT);
    setTimeout(() => {
      setCopySMDPAddress(SMDP_ADDRESS_TXT);
    }, CLIPBOARD_RESET_TIME);
  };

  const copyActivationCodeToClipBoard = () => {
    navigator.clipboard.writeText(activationCode);
    setCopyActivationCode(COPIED_TXT);
    setTimeout(() => {
      setCopyActivationCode(ACTIVATION_CODE_TXT);
    }, CLIPBOARD_RESET_TIME);
  };

  const copyActivationURLToClipBoard = () => {
    navigator.clipboard.writeText(activationURL);
    setCopyActivationCode(COPIED_TXT);
    setTimeout(() => {
      setCopyActivationCode(ACTIVATION_URL_TXT);
    }, CLIPBOARD_RESET_TIME);
  };

  const renderSMDPAddressCopyTextIcon = renderCopyTextBtn(
    copySMDPAddress,
    copyAddressToClipBoard
  );

  const renderActivationCodeCopyTextIcon = renderCopyTextBtn(
    copyActivationCode,
    copyActivationCodeToClipBoard
  );

  const renderActivationURLCopyTextIcon = renderCopyTextBtn(
    copyActivationURL,
    copyActivationURLToClipBoard
  );

  const renderQrCodeMetaDataFields = () => {
    return (
      <>
        {<canvas className="qr-code" id="canvas" />}
        <div className="qr-code-fields-container">
          <div className="qr-code-info-container">
            <FormGroup className="qr-label" label="SMDP ADDRESS">
              <InputGroup
                large={true}
                name="SMDPAddress"
                value={SMDPAddress}
                disabled={true}
                leftElement={renderSMDPAddressCopyTextIcon}
              />
            </FormGroup>
          </div>

          <div className="qr-code-info-container">
            <FormGroup className="qr-label" label="ACTIVATION CODE">
              <InputGroup
                large={true}
                name="ActivationCode"
                value={activationCode}
                disabled={true}
                leftElement={renderActivationCodeCopyTextIcon}
              />
            </FormGroup>
          </div>

          <div className="qr-code-info-container">
            <FormGroup className="qr-label" label="ACTIVATION URL">
              <InputGroup
                large={true}
                name="ActivationURL"
                value={activationURL}
                disabled={true}
                leftElement={renderActivationURLCopyTextIcon}
              />
            </FormGroup>
          </div>
        </div>
      </>
    );
  };

  const renderQrCodeDownloaded = () => {
    return (
      <>{qrCodeDownloadSuccess ? renderDownloadedText() : renderErrorText()}</>
    );
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `eSIMs-QR-Code-${iccid}.jpg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    toast.show({
      intent: Intent.SUCCESS,
      message: `QRCode Succssfully Downloaded!`,
    });
  };

  return (
    <>
      <h3>
        ESIM PROFILE
        {qrCodeDownloaded ? null : (
          <Button
            onClick={downloadQRCode}
            className="download-qr-code-btn"
            outlined={true}
            icon="import"
          >
            Download QRCode
          </Button>
        )}
      </h3>
      {qrCodeDownloaded
        ? renderQrCodeDownloaded()
        : renderQrCodeMetaDataFields()}
    </>
  );
};
