import  Storage from './LocalStorageHelper';
import { CountryFilter, OperatorsFilter } from '../shared/types';
import { Filter } from '../shared/enums';


export default class Filters extends  Storage<Filter> {

    private static instance?: Filters;

    public static getInstance() {
        if (!this.instance) {
          this.instance = new Filters();
        }
    
        return this.instance;
      }

    public getCountriesFilter(){
        let item:any = this.getItem(Filter.COUNTRIES);
        return JSON.parse(item);
    }

    public getOperatorsFilter(){
        let item:any = this.getItem(Filter.OPERATORS);
        return JSON.parse(item);
    }

    public setCountriesFilter(countries: CountryFilter[]){
        this.setItem(Filter.COUNTRIES, JSON.stringify(countries));
    }

    public setOperatorsFilter(operators: OperatorsFilter[]){
        this.setItem(Filter.OPERATORS, JSON.stringify(operators));
    }

    public removeCountriesFilter(){
        this.removeItem(Filter.COUNTRIES);
    }

    public removeOperatorsFilter(){
        this.removeItem(Filter.OPERATORS);
    }

    public getDatePresetFilter(){
        return this.getItem(Filter.DATE_PRESET);
    }

    public setDatePresetFilter(datePreset:string){
        this.setItem(Filter.DATE_PRESET, datePreset);
    }

    public removeDatePresetFilter(){
        this.removeItem(Filter.DATE_PRESET);
    }

    public setStartDateFilter(startDate: string){
        this.setItem(Filter.START_DATE, startDate);
    }

    public setEndDateFilter(endDate: string){
        this.setItem(Filter.END_DATE, endDate);
    }

    public getStartDateFilter():any{
        return this.getItem(Filter.START_DATE);
    }

    public getEndDateFilter():any{
        return this.getItem(Filter.END_DATE);
    }

    public removeDateRangeFilters(){
        this.removeItems([ Filter.START_DATE, Filter.END_DATE]);
    }

    public removeAllFilters(){
        this.removeItems([Filter.DATE_PRESET, Filter.COUNTRIES, Filter.OPERATORS, Filter.START_DATE, Filter.END_DATE]);
    }
}