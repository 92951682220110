import React, { useState, useEffect, useCallback } from "react";
import { Button, Intent, FormGroup, InputGroup, NumericInput } from "@blueprintjs/core";
import { PageSizeOptions } from "../../shared/constants";
import { PageSizeOption, PaginationProps } from "../../shared/types";
import "./PaginationComponent.css";

export const PaginationComponent: React.FC<PaginationProps> = (props) => {
  const { page, page_size, total_pages, total_results, handlePageChange } =
    props;
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(page_size);
  
  useEffect(() => {
    let element: any = document.getElementsByName("JumpTo");
    element[0].className += ' jump-to';

  });

  
  const debounce = (func: Function) => {
    let timer: ReturnType<typeof setTimeout> | null;
    return (...args: string[]) => {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
        }, 1000)
    }
}

  const goToNextPage = () => {
    const nextPage = page + 1;
    setCurrentPage(nextPage);
    handleUpdate(nextPage);
  };

  const goToPreviousPage = () => {
    const prevPage = page - 1;
    setCurrentPage(prevPage);
    handleUpdate(prevPage);
  };

  const goToFirstPage = () => {
    const firstPage = 0;
    handleUpdate(firstPage);
  }

  const goToLastPage = () => {
    const lastPage = total_pages - 1;
    handleUpdate(lastPage);
  }

  const handlePageSizeSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPageSize = Number(e.target.value);

    setPageSize(selectedPageSize);
    setCurrentPage(0);
    handleUpdate(0, selectedPageSize);
  };

  const renderPageSizeDropDown = () => {
    return (
      <>
        <div className="bp3-html-select .modifier">
          <select value={page_size} onChange={handlePageSizeSelection}>
            {renderPageSizeOptions(PageSizeOptions)}
          </select>
          <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
        </div>
      </>
    );
  };

  const handleValueChange = (e:any) => {
    let cleanValue = e.replace(/[^a-zA-Z0-9 ]/g, '');
    cleanValue.trim();


    if(cleanValue >  total_pages || cleanValue < 1 || isNaN(cleanValue)){
      return
    }

    handleUpdate(cleanValue - 1);

    // console.log(cleanValue - 1);
    // setTimeout(() => {
    //   handleUpdate(cleanValue - 1);
    // },1000)

  
    console.log(cleanValue-1, cleanValue);
  }

  const jumpToFn = useCallback(debounce(handleValueChange), []);

   const renderJumpToPageInput = () => {
    return (<> Jump to page: <FormGroup className="jump-to-input">
      <InputGroup
        type="text"
        name="JumpTo"
        min={1}
        intent={Intent.PRIMARY}
        max={total_pages}
        onChange={(e) => jumpToFn(e.target.value)}
        large={true}
      />
    </FormGroup></>)
   }


  const renderPageSizeOptions = (options: PageSizeOption[]) => {
    return options.map((e, key) => {
      return (
        <option key={key} value={e.value}>
          {e.name}
        </option>
      );
    });
  };

  const renderCurrentPageNumber = (_page: number) => {
    return _page === 0 ? 1 : page + 1;
  };

  const renderResultsText = (_totalResults: number): string => {
    return _totalResults === 1 ? "Result" : "Results";
  }

  const handleUpdate = (_currentPage?: number, _pageSize?: number) => {
    const currPage = _currentPage ? _currentPage : currentPage;
    const page_Size = _pageSize ? _pageSize : pageSize;
    handlePageChange(currPage, page_Size);
  };

  const renderPagination = () => {
    return (
      <div className="pagination-container">
        <div className="pagintion-page-size-container">
          {total_results} {renderResultsText(total_results)} - Showing {renderPageSizeDropDown()} per page
          {<span className="jump-to-container">{renderJumpToPageInput()}</span>}
        </div>

        <div className="pagination-controls-container">
          Page {renderCurrentPageNumber(page)} of {total_pages}
          {page === 0 ? null : <Button className="page-btn" intent={Intent.PRIMARY} onClick={goToFirstPage} icon="double-chevron-left" text="First" />}
          {page === 0 ? null : (
            <Button className="page-btn" intent={Intent.PRIMARY} onClick={goToPreviousPage} icon="chevron-left" />
          )}
          {page === total_pages! - 1 ? null : (
            <Button className="page-btn" intent={Intent.PRIMARY} onClick={goToNextPage} icon="chevron-right" />
          )}
          {page === total_pages - 1 ? null : <Button className="page-btn" intent={Intent.PRIMARY} onClick={goToLastPage} text="Last" rightIcon="double-chevron-right" />}
        </div>
      </div>
    );
  };

  return <>{renderPagination()}</>;
};