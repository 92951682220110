import React, { useEffect, useState } from "react";
import {
  Card,
  Elevation,
  Toaster,
  Position,
  FormGroup,
  InputGroup,
  Button,
  Intent,
  Icon,
  Tooltip,
  Popover,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import { useForm } from "react-hook-form";
import "./ChangePasswordContainer.css";
import { AuthRoutes, NonAuthRoutes, PageTitles } from "../../shared/enums";
import { useHistory } from "react-router";
import { BrandingProps, ErrorResponseObj } from "../../shared/types";
import { BrandingComponent } from "../../components";
import {
  DEFAULT_ICON_SIZE,
  TOAST_DISPLAY_LONG_TIME,
} from "../../shared/constants";
import User from "../../utility/User";
import { changePassword, logOut} from "../../api/authService";
import PasswordStrengthBar from "react-password-strength-bar";
import { initRefresh } from "../../api/authService";
import { generatePageTitle } from "../../utility/utils";

interface ChangePasswordFormInput {
  newPassword: string;
  confirmPassword: string;
}

const toast = Toaster.create({
  className: "top-toaster",
  position: Position.TOP,
});

export const ChangePasswordContainer: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordFormInput>({ mode: "onChange" });
  const userInstance = User.getInstance()
  const [loading, setLoading] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowshowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isEnabled] = useState(false);
  const history = useHistory();

  const BrandingProps: BrandingProps = {
    width: "200",
    className: "logo",
  };

  useEffect(() => {
    generatePageTitle(PageTitles.CHANGE_PASSWORD);
    initRefresh();
  }, []);

  const handleIntent = (inputRef: string): Intent => {
    const { newPassword, confirmPassword } = getValues();

    if (inputRef === "newPassword") {
      if (newPassword === "" && !errors.newPassword) return Intent.PRIMARY;
      if (errors.newPassword) return Intent.DANGER;
    }

    if (inputRef === "confirmPassword") {
      if (confirmPassword === "" && !errors.confirmPassword)
        return Intent.PRIMARY;
      if (errors.confirmPassword) return Intent.DANGER;
    }

    return Intent.SUCCESS;
  };

  const generateLockIconBtn = (
    tooltipTxt: string,
    showInputVal: boolean,
    isEnabled: boolean,
    handleShowInputVal: () => void
  ): JSX.Element => {
    return (
      <Tooltip
        content={`${showInputVal ? "Hide" : "Show"} ${tooltipTxt}`}
        disabled={isEnabled}
      >
        <Button
          disabled={isEnabled}
          icon={showInputVal ? "eye-open" : "eye-off"}
          intent={Intent.WARNING}
          minimal={true}
          onClick={handleShowInputVal}
        />
      </Tooltip>
    );
  };
  const handleShowNewPassword = (): void => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = (): void => {
    setShowshowConfirmPassword(!showConfirmPassword);
  };

  const renderNewPasswordIcon = generateLockIconBtn(
    "New Password",
    showNewPassword,
    isEnabled,
    handleShowNewPassword
  );

  const renderConfirmPasswordIcon = generateLockIconBtn(
    "Confirm Password",
    showConfirmPassword,
    isEnabled,
    handleShowConfirmPassword
  );

  const generateErrorMsg = (errorObj: ErrorResponseObj) => {
    return (
      <>
        <div>{errorObj.data.title}</div>
        <div>{errorObj.data.description}</div>
      </>
    );
  };

  const handlePasswordReset = (data: ChangePasswordFormInput) => {
    const { newPassword } = data;
    const user = JSON.parse(userInstance.getUser());

    user.password = newPassword;
    setLoading(true);

    changePassword(user)
      .then((response) => {
        if (response.status === 200) {
          toast.show({
            icon: "tick-circle",
            intent: Intent.SUCCESS,
            message: `You have successfully changed your password.`,
            timeout: TOAST_DISPLAY_LONG_TIME,
          });
          reset();
          setLoading(false);
          // take a couple of sec then, redirect user to dashboard
          setTimeout(() => {
            history.push(AuthRoutes.dashboard);
          }, 5000);
        }
      })
      .catch((error) => {
        setLoading(false);
        reset();
        toast.show({
          icon: "warning-sign",
          intent: Intent.DANGER,
          message: generateErrorMsg(error.response),
          timeout: TOAST_DISPLAY_LONG_TIME,
        });
      });
  };

  const getPasswordVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const renderPasswordStrengthMeter = () => {
    return (
      <div className="password-strength-bar">
        <span>Password Strength</span>
        <PasswordStrengthBar password={newPassword} />
      </div>
    );
  };

  const onFocus = () => setIsTyping(true);
  const onBlur = () => setIsTyping(false);
  const handleCancel = () => {
    const user = JSON.parse(userInstance.getUser());
    user.force_password_change ? handleLogOut() : history.push(AuthRoutes.dashboard);
  }

  const handleLogOut = () => {
    logOut();
    history.push(NonAuthRoutes.login);
  }

  return (
    <>
      <div className="container">
        <div className="reset-password-wrapper">
          <Card interactive={false} elevation={Elevation.THREE}>
            <BrandingComponent {...BrandingProps} />
            <form onSubmit={handleSubmit(handlePasswordReset)}>
              <Popover
                popoverClassName="bp3-popover-content-sizing"
                content={renderPasswordStrengthMeter()}
                interactionKind={PopoverInteractionKind.CLICK}
                defaultIsOpen={true}
                position="right"
                isOpen={isTyping}
                target={
                  <FormGroup>
                    <InputGroup
                      autoFocus
                      className="new-password-field"
                      onFocus={onFocus}
                      onBlur={onBlur}
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      intent={handleIntent("newPassword")}
                      rightElement={renderNewPasswordIcon}
                      onChange={(e) => {
                        getPasswordVal(e);
                      }}
                      inputRef={register({
                        required: {
                          value: true,
                          message: " Password is required.",
                        },
                      })}
                      placeholder="New Password"
                      large={true}
                    />
                    {errors.newPassword ? (
                      <div className="errorMsg fade-in">
                        <Icon
                          icon="error"
                          iconSize={DEFAULT_ICON_SIZE}
                          intent={Intent.DANGER}
                        />
                        <span>
                          {errors.newPassword && errors.newPassword.message}
                        </span>
                      </div>
                    ) : null}
                  </FormGroup>
                }
              />
              <FormGroup>
                <InputGroup
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  rightElement={renderConfirmPasswordIcon}
                  intent={handleIntent("confirmPassword")}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Confirm Password is required.",
                    },
                    validate: {
                      matchPassword: (value): boolean | string => {
                        const { newPassword } = getValues();
                        return (
                          newPassword === value || " Passwords must match."
                        );
                      },
                    },
                  })}
                  placeholder="Confirm New Password"
                  large={true}
                />
                {errors.confirmPassword ? (
                  <div className="errorMsg fade-in">
                    <Icon
                      icon="error"
                      iconSize={DEFAULT_ICON_SIZE}
                      intent={Intent.DANGER}
                    />
                    <span>
                      {errors.confirmPassword && errors.confirmPassword.message}
                    </span>
                  </div>
                ) : null}
              </FormGroup>
            <div className="btn-container">
            <Button
                type="button"
                intent={Intent.NONE}
                fill={true}
                minimal={true}
                text="Cancel"
                large={true}
                disabled={loading}
                onClick={handleCancel}
              />
              <Button
                loading={loading}
                disabled={!formState.isValid || loading}
                type="submit"
                intent={Intent.PRIMARY}
                fill={true}
                outlined={true}
                text="Save"
                large={true}
              />
            </div>
            </form>

          </Card>
        </div>
      </div>
    </>
  );
};
