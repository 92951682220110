import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { InputGroup, Overlay, Icon, Toaster, Position, Intent } from "@blueprintjs/core";
import { ServiceStatusDialog, SearchInputProps } from "../../shared/types";
import { LoadingIndicatorComponent } from "../index";
import { search } from "../../api/usageService";
import "./SearchComponent.css";

interface ISearchComponent { }

const toast = Toaster.create({
    className: "top-toaster",
    position: Position.TOP,
});

export const SearchComponent: React.FC<ISearchComponent> = () => {
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>([]);

    const toggleOverlay = () => {
        setOpen(isOpen => !isOpen)
    }

    const LoadingProps = {
        text: "Searching...",
        className: "search-loader",
    };

    const OverlayProps: ServiceStatusDialog = {
        autoFocus: true,
        canEscapeKeyClose: false,
        canOutsideClickClose: false,
        enforceFocus: true,
        isOpen: isOpen,
        usePortal: true,
        onClose: toggleOverlay
    }

    const debounce = (func: Function) => {
        let timer: ReturnType<typeof setTimeout> | null;
        return (...args: string[]) => {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500)
        }
    }

    const fetchSearchResults = (value: string) => {
        if (value === "") return;

        setLoading(true);
        search(value).then((response) => {
            setLoading(false);
            console.log("RESULTS:", response)
            setData(response.data.results)
        }).catch((error) => {
            setLoading(false);
            toast.show({
                intent: Intent.DANGER,
                message: `${error} - Search`,
            });
        })
    }

    const searchFn = useCallback(debounce(fetchSearchResults), []);


    const SearchProps: SearchInputProps = {
        placeholder: "Search...",
        leftIcon: "search",
        type: "text",
        onChange: (e) => searchFn(e.target.value)
    }

    const history = useHistory();
    const goToSIMsDetailsView = (id: number) => history.push(`/sim-details/${id}`);

    const renderData = () => {
        return (<><ul className="result-list">{data.map((item: any, index: number) => <li onClick={() => goToSIMsDetailsView(item.iccid)} key={index}><div className="result-list-alias">{item.alias} </div><div className="result-list-iccid">{item.iccid}</div></li>)}</ul></>)
    }

    const renderNoResults = () => {
        return (<p className='no-matches-found'>No matching records found</p>)
    }

    const renderSearchResults = () => {
        return (<>
            {data.length === 0 ? renderNoResults() : renderData()}
        </>)
    }

    const renderSearchContainer = () => {
        return (<div className="search-wrapper">
            <div className="search-header">
                {data.length >0 ?<span>Found: <strong>{data.length}</strong> Records</span>: null}
                <span className="close-btn" onClick={toggleOverlay} ><Icon icon="cross" size={14} /> Close</span>
            </div>

            <div className="search-body">
                <InputGroup {...SearchProps} autoFocus />
                {loading ? <LoadingIndicatorComponent {...LoadingProps} /> : renderSearchResults()}
            </div>

        </div>)
    }

    return (<>
        <span onClick={toggleOverlay} className="search-button"><Icon icon="search" size={14} /> Search..</span>
        <Overlay {...OverlayProps}>
            {renderSearchContainer()}
        </Overlay>
    </>)
}