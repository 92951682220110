import axios from "axios";
import Tokens from "../utility/Tokens";
import { handleErrorLogOut } from "./authService";
import { ApiConfigEndpoints } from "../shared/types";

export const API_CONFIG: ApiConfigEndpoints = {
  BASE_URL: "/api",
  AUTHENTICATION_URL: "/auth/authenticate",
  REFRESH_TOKEN: "/auth/refresh",
  GET_ACCOUNT: "/auth/self/user",
  GET_SUBSCRIPTIONS: "/subscriptions",
  GET_USAGE: "/usage",
  GET_USAGE_SUMMARY: "/usage/summary",
  GET_USAGE_DETAILS: "usage/detailed",
  UPDATE_USER: "/auth/self/user",
  ESIM_QR_CODE: "esim_qr",
  SEARCH: "subscriptions/search"
};

export const apiClient = axios.create({
  baseURL: API_CONFIG.BASE_URL
});

apiClient.interceptors.request.use(
  (config) => {
    const tokens = Tokens.getInstance();
    const jwtToken = tokens.getToken();

    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
   (error) => {    
    const tokens = Tokens.getInstance();
    const jwtToken = tokens.getToken();
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (jwtToken) {
    if(error.request.status === 403){
      console.log("UNAUTHORIZED!");
    }
    if (error.request.status === 401 || error.request.status === 500 || error.request.status === 504) {
      handleErrorLogOut();
    }
  }
    return Promise.reject(error);
  }
);
