import React from "react";
import "./BrandingComponent.css";
import { BrandingProps } from "../../shared/types";

export const BrandingComponent: React.FC<BrandingProps> = (props) => {
  const { width, className, display, displayText } = props;
  return (
    <>
      {display ? (
        <img
          className={className}
          alt="branding logo"
          width={width}
          src={`${process.env.PUBLIC_URL}/assets/branding/telecom26-logo.png`}
        />
      ) : (
        <span className="sign-in-display-text">{displayText}</span>
      )}
      <>{displayText ? <hr /> : null}</>
    </>
  );
};
