import React from "react";
import "./FooterComponent.css";

interface FooterProps {}

export const FooterComponent: React.FC<FooterProps> = () => {
  return (
    <>
        <span className="app-version">Version: {process.env.REACT_APP_VERSION}</span>
    </>
  );
};
