import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Elevation, Icon, NonIdealState } from "@blueprintjs/core";
import "./SimOverviewCardComponent.css";
import { SimsCardUsage } from "../../shared/types";
import {
  generateDurationFormat,
  generateDataUsageFormat,
  generateServiceStatusIntent,
  generateServiceStatusIcon,
  generateTruncatedString
} from "../../utility/utils";
import { CallTypes, Messages } from "../../shared/enums";
import { ServiceStatuses, TRUNCATE_STRING_LEN } from "../../shared/constants";


export const SimOverviewCardComponent: React.FC<SimsCardUsage> = (props) => {
  const {
    alias,
    service_status: serviceStatus,
    iccid,
    total_data: totalUsageData,
    sms_events: totalSMSData,
    duration: totalDurationData,
  } = props;

  const history = useHistory<SimsCardUsage>();
  const goToSIMsDetailsView = (id: number) => history.push(`/sim-details/${id}`);

  const renderUsageDataItem = (
    data: number,
    label: string,
    usageType: string
  ) => {
    return data ? (
      <div className="usage-data-item">
        <span className="SIM-card-usage-total">
          {renderFormattedData(data, usageType)}
        </span>
        <span className="usage-label">{label}</span>
      </div>
    ) : null;
  };

  const renderFormattedData = (
    data: number,
    usageType: string
  ): number | string | undefined => {
    if (usageType === CallTypes.DATA) {
      return generateDataUsageFormat(data);
    }
    if (usageType === CallTypes.VOICE) {
      return generateDurationFormat(data);
    }
    if (usageType === CallTypes.SMS) {
      return data;
    }
  };

  const renderServiceStatus = (serviceStatus: number) => {
    return (
      <>
        <span className="service-status-icon">
          <Icon
            icon={generateServiceStatusIcon(serviceStatus)}
            size={14}
            intent={generateServiceStatusIntent(serviceStatus)}
          />
        </span>
        <span
          className={`service-status-label ${generateServiceStatusIntent(
            serviceStatus
          )}`}
        >
          {ServiceStatuses.get(serviceStatus)}
        </span>
      </>
    );
  };

  return (
    <>
      <Card
        onClick={() => goToSIMsDetailsView(iccid)}
        className="SIM-card"
        interactive={true}
        elevation={Elevation.ONE}
      >
        <span title={alias}>
          {<b>{generateTruncatedString(alias, TRUNCATE_STRING_LEN)}</b> }
        </span>
       
        <span className="service-status">
          {renderServiceStatus(serviceStatus)}
        </span>
        <div className="iccid"><small>{iccid}</small></div>
        {totalUsageData ? (
          <div className="usage-totals-data-container">
            {renderUsageDataItem(totalUsageData, "Total Data", CallTypes.DATA)}
            {renderUsageDataItem(totalSMSData, "Total SMS", CallTypes.SMS)}
            {renderUsageDataItem(totalDurationData, "Total Voice", CallTypes.VOICE)}
          </div>
        ) : (
          <div className="no-usage-data-container">
            <NonIdealState
              title="No Usage Data"
              description={Messages.NON_IDEAL_STATE}
            />
          </div>
        )}
      </Card>
    </>
  );
};
