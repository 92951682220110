import { apiClient, API_CONFIG } from "../api/config";
import Filters from "../utility/Filters";
import Paging from "../utility/Paging";
import User from "../utility/User";
import Tokens from "../utility/Tokens";
import { AuthParams, ParseToken, IUser } from "../shared/types";

let timerId: ReturnType<typeof setInterval>;
let tokenTimer: ParseToken;
const tokens = Tokens.getInstance();
const user = User.getInstance();
const filters = Filters.getInstance();
const paging = Paging.getInstance();

// set the refresh time limits
let setTimer = (): number => {
  tokenTimer = tokens.parseJwt();
  if (!tokenTimer.exp || !isNaN(tokenTimer.exp)) {
    if (tokenTimer.exp * 1000 - Date.now() > 500) {
      return Math.max(
        tokenTimer.exp * 1000 - Date.now() - 60000,
        (tokenTimer.exp * 1000 - Date.now()) / 2
      );
    }
  }
  return 5000;
};

export const authUser = ({ username, password, role }: AuthParams) => {
  return apiClient
    .post(`${API_CONFIG.AUTHENTICATION_URL}`, {
      username,
      password,
      role,
    })
    .then((response) => {
      tokens.setToken(response.data.access_token);
      tokens.setRefreshToken(response.data.refresh_token);
      timerId = setInterval(getRefreshToken, setTimer());
      return response;
    });
};

export const changePassword = (user: IUser) => {
  return apiClient.put(`${API_CONFIG.UPDATE_USER}`, user).then((response) => {
    return response;
  });
};

export const getUser = () => {
  return apiClient.get(`${API_CONFIG.GET_ACCOUNT}`);
};

export const initRefresh = () => {
  if (timerId) return;
  timerId = setInterval(getRefreshToken, setTimer());
};

export const getRefreshToken = () => {
  const params = {
    access_token: tokens.getToken(),
    refresh_token: tokens.getRefreshToken(),
  };
  return apiClient
    .post(`${API_CONFIG.REFRESH_TOKEN}`, params)
    .then((response) => {
      // set new token
      tokens.setToken(response.data.access_token);
    })
    .catch((error) => {
      error.response.status === 401 || error.response.status === 504
        ? handleErrorLogOut()
        : getRefreshToken();
    });
};

export const logOut = () => {
  clearRefreshTimer();
  tokens.removeTokens();
  user.removeUsername();
  filters.removeAllFilters();
  paging.removeAllPaging();
};

export const handleErrorLogOut = () => {
  clearRefreshTimer();
  tokens.removeTokens();
  user.removeUsername();
  filters.removeAllFilters();
  window.location.reload();
};

export const clearRefreshTimer = () => {
  clearInterval(timerId);
};
