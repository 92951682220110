import React from "react";
import { Menu, MenuItem } from "@blueprintjs/core";
interface MenuProps {
  service_status: number;
  handleRenderDialog: (status: number) => void;
}

export const ServiceStatusrMenu: React.FC<MenuProps> = (props) => {
  const { service_status: status, handleRenderDialog } = props;

  const handleStatusUpdate = (status: number) => {
    handleRenderDialog(status);
  };
  return (
    <div>
      <Menu>
        {status === 200 && (
          <MenuItem
            onClick={() => handleStatusUpdate(status)}
            icon="delete"
            text="Block"
          />
        )}
        {[280, 240].includes(status) && (
          <MenuItem
            onClick={() => handleStatusUpdate(status)}
            icon="undo"
            text="UnBlock"
          />
        )}
      </Menu>
    </div>
  );
};
